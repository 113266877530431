<template>
  <v-expand-transition>
    <div v-if="showAmazing">
      <v-fade-transition>
        <v-container
          v-if="isLoading"
          key="loader"
          fluid
          class="px-4 px-lg-16 pt-5"
        >
          <v-skeleton-loader type="image@2" />
        </v-container>
        <div v-else key="amazing">
          <AmazingMobile
            v-if="isMobile"
            :data="amazingData"
            @finish-timer="timerFinished = true"
          />
          <AmazingDesktop
            v-else
            :data="amazingData"
            @finish-timer="timerFinished = true"
          />
        </div>
      </v-fade-transition>
    </div>
  </v-expand-transition>
</template>

<script>
import AmazingDesktop from "@/packages/home/components/amazing/desktop/Index";
import AmazingMobile from "@/packages/home/components/amazing/mobile/Index";
import { getDayOfferProductsAxiosRequest } from "@/packages/home/api/main.api";
export default {
  name: "Amazing",
  components: { AmazingMobile, AmazingDesktop },
  data() {
    return {
      amazingData: null,
      isLoading: true,
      hasError: false,
      timerFinished: false,
    };
  },
  computed: {
    showAmazing() {
      return !(
        this.isLoading ||
        this.hasError ||
        this.timerFinished ||
        (this.amazingData && !this.amazingData.amazingShopGetDto.length)
      );
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  created() {
    this.getAmazing();
  },
  methods: {
    async getAmazing() {
      this.isLoading = true;
      this.hasError = false;
      try {
        const { data } = await getDayOfferProductsAxiosRequest();
        this.amazingData = data;
      } catch (err) {
        console.log(err);
        this.hasError = true;
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped></style>
