import router from "@/app/router";

export const navigateToLoginBeforeContinue = async function () {
  await router.push({
    name: "check-phone",
    query: { redirect: "order" },
  });
};

export const navigateToInPlacePaymentResult = async function (order_id, id) {
  await router.push({
    name: "order.cart.payment.in-place",
    params: { order_id: id },
    query: {
      order_id: order_id,
      type: "inPlace",
      status: "success",
      message: "local",
    },
  });
};

export const navigateToCheckPaymentResult = async function (
  order_id,
  id,
  total_price
) {
  await router.push({
    name: "order.cart.payment.in-place",
    params: { order_id: id },
    query: {
      order_id: order_id,
      type: "checkPay",
      status: "success",
      message: "checkPay",
      price: total_price,
    },
  });
};

export const navigateToInstallmentPaymentResult = async function (
  order_id,
  id,
  total_price
) {
  await router.push({
    name: "order.cart.payment.installment",
    params: { order_id: id },
    query: {
      order_id: order_id,
      type: "installment",
      status: "success",
      message: "installmentPay",
      price: total_price,
    },
  });
};
