// initial state

const state = () => ({
  specialFilters: [],
  brandFilters: [],
  categoryFilters: [],
  colorFilters: [],
  priceFilters: [],
  guaranteeFilters: [],
  price: null,
  otherFilters: [],
  selectedFilters: {},
  allFilters: {},
  specials: {
    amazing: "فقط شگفت انگیز",
    special: "فقط تخفیف دار",
    available: "فقظ کالاهای موجود",
  },
  products: {},
});

// getters
export const getters = {
  specials: (state) => state.specialFilters,
  brands: (state) => state.brandFilters,
  categories: (state) => state.categoryFilters,
  colors: (state) => state.colorFilters,
  colorsCount: (state) => state.colorFilters.length,
  prices: (state) => state.priceFilters,
  guarantees: (state) => state.guaranteeFilters,
  otherFilters: (state) => state.otherFilters,
  allFilters: (state) => state.allFilters,
  filters: (state) => {
    return {
      specials: state.specialFilters,
      brandId: state.brandFilters,
      catId: state.categoryFilters,
      colorId: state.colorFilters,
      priceStart: state.priceFilters[0],
      priceEnd: state.priceFilters[1],
      guarantee: state.guaranteeFilters,
    };
  },
  price: (state) => {
    state.price;
  },
  products: (state) => state.products,
};

// mutations
export const mutations = {
  mutateSpecialFilters(state, payload) {
    state.specialFilters = payload;
  },
  mutateBrandFilters(state, payload) {
    state.brandFilters = payload;
  },
  mutateCategoryFilters(state, payload) {
    state.categoryFilters = payload;
  },
  mutateColorFilters(state, payload) {
    state.colorFilters = payload;
  },
  mutatePriceFilters(state, payload) {
    state.priceFilters = payload;
  },
  mutateGuaranteeFilters(state, payload) {
    state.guaranteeFilters = payload;
  },

  mutatePriceFilter(state, payload) {
    state.price = payload;
  },

  mutateOtherFilters(state, payload) {
    state.otherFilters = payload;
  },

  mutateSelectedFilters(state, payload) {
    state.selectedFilters = payload;
  },

  mutateAllFilters(state, payload) {
    state.allFilters = payload.filters;
    // for (const prop in payload.query) {
    //   if (prop === "specials") {
    //     state.specialFilters = payload.query[prop].map((item) => {
    //       return {
    //         checked: false,
    //         id: item,
    //         label: state.special[prop],
    //         value: item.toUpperCase(),
    //       };
    //     });
    //   }
    // }
  },

  removeSpecialFiltersElement(state, payload) {
    let filters = [...state.specialFilters];
    let index = filters.indexOf(payload);
    if (index > -1) {
      filters.splice(index, 1);
      state.specialFilters = [...filters];
    }
  },

  removeBrandFiltersElement(state, payload) {
    let filters = [...state.brandFilters];
    let index = filters.indexOf(payload);
    if (index > -1) {
      filters.splice(index, 1);
      state.brandFilters = [...filters];
    }
  },

  removeCategoryFiltersElement(state, payload) {
    let filters = [...state.categoryFilters];
    let index = filters.indexOf(payload);
    if (index > -1) {
      filters.splice(index, 1);
      state.categoryFilters = [...filters];
    }
  },

  removeGuaranteeFiltersElement(state, payload) {
    let filters = [...state.guaranteeFilters];
    let index = filters.indexOf(payload);
    if (index > -1) {
      filters.splice(index, 1);
      state.guaranteeFilters = [...filters];
    }
  },

  removeColorFiltersElement(state, payload) {
    let filters = [...state.colorFilters];
    let index = filters.indexOf(payload);
    if (index > -1) {
      filters.splice(index, 1);
      state.colorFilters = [...filters];
    }
  },

  removeOthersFiltersElement(state, payload) {
    let filters = [...state.otherFilters];
    let index = filters.indexOf(payload);
    if (index > -1) {
      filters.splice(index, 1);
      state.otherFilters = [...filters];
    }
  },

  resetSpecialFilters(state) {
    state.specialFilters = [];
  },

  resetBrandFilters(state) {
    state.brandFilters = [];
  },

  resetCategoryFilters(state) {
    state.categoryFilters = [];
  },

  resetGuaranteeFilters(state) {
    state.guaranteeFilters = [];
  },

  resetColorFilters(state) {
    state.colorFilters = [];
  },

  resetOthersFilters(state) {
    state.otherFilters = [];
  },

  resetPriceFilters(state) {
    state.priceFilters = [];
  },

  mutateProducts(state, data) {
    state.products = data;
  },
};

// actions
export const actions = {
  setPriceAce({ commit }) {
    commit("mutatePriceFilter", "price,asc");
  },
  setPriceDesc({ commit }) {
    commit("mutatePriceFilter", "price,desc");
  },
  setAllFilters({ commit }, data) {
    commit("mutateAllFilters", data);
  },
  resetFilters({ commit }) {
    commit("resetSpecialFilters");
    commit("resetBrandFilters");
    commit("resetCategoryFilters");
    commit("resetGuaranteeFilters");
    commit("resetColorFilters");
    commit("resetPriceFilters");
    commit("resetOthersFilters");
  },
  setProducts({ commit }, data) {
    commit("mutateProducts", data);
  },
};

export default {
  namespaced: true,
  name: "products-filters",
  state,
  getters,
  actions,
  mutations,
};
