<template>
  <v-container
    class="d-flex flex-column justify-space-around px-4 px-lg-16 pt-5"
    fluid
  >
    <div class="d-flex justify-space-between w-100 align-center">
      <div>
        <router-link :to="{ name: 'home' }" class="mx-auto">
          <v-img
            height="50px"
            width="100%"
            :src="logoSrc"
            contain
            alt="lipak"
          />
        </router-link>
      </div>
      <div style="width: 50%">
        <SearchBox />
      </div>
      <div class="ms-2">
        <v-btn
          class="px-2"
          :to="{ name: is_authenticated ? 'profile' : 'authentication' }"
          elevation="0"
        >
          <div class="secondary--text font-weight-bold">
            {{
              $_t(
                `components.header.${
                  is_authenticated ? "profile" : "register_login"
                }`
              )
            }}
          </div>
          <v-icon size="24" class="mb-1 mr-6" color="secondary">
            mdi-account-outline
          </v-icon>
        </v-btn>
        <v-btn
          color="new_blue"
          class="ms-4 white--text px-2"
          :to="{ name: 'order.cart' }"
        >
          <div class="font-weight-bold ml-6">
            {{ $_t("components.header.cart") }}
          </div>
          <v-chip class="d-flex rounded-sm px-1" small color="white">
            <div
              v-if="cartItemsCount"
              class="font-weight-bold text-body-1 ss02 me-3"
            >
              {{ cartItemsCount }}
            </div>
            <v-icon color="secondary" size="18">mdi-cart-outline</v-icon>
          </v-chip>
        </v-btn>
      </div>
    </div>
    <div class="w-100 mt-3">
      <MegaMenuDesktop />
    </div>
  </v-container>
</template>

<script>
import logoSrc from "@/app/assets/images/logo/logo.webp";
import SearchBox from "@/app/components/SearchBox";
import { mapGetters } from "vuex";
import MegaMenuDesktop from "@/app/components/new-header/desktop/MegaMenu";
export default {
  name: "HeaderDesktop",
  components: { MegaMenuDesktop, SearchBox },
  data() {
    return {
      logoSrc,
    };
  },
  computed: {
    ...mapGetters({
      is_authenticated: "authentication/check",
      cartItemsCount: "cart/count",
    }),
  },
};
</script>

<style scoped></style>
