<template>
  <div class="pb-4 pt-3">
    <v-container fluid class="px-4 px-lg-16">
      <div class="d-flex justify-space-between">
        <v-card
          flat
          tile
          color="new_blue"
          class="d-inline-block white--text font-weight-bold text-h6 pr-6 pl-8 py-1 rounded"
        >
          پیشنهاد ویژه لیپک
        </v-card>
        <ImageChanger v-if="totalPages > 1" @next="next" @prev="prev" />
      </div>
      <v-row
        class="mt-3"
        v-touch="{
          left: () => swipe('left'),
          right: () => swipe('right'),
        }"
      >
        <v-col cols="6" sm="4" v-for="item in slicedData" :key="item.slug">
          <v-card :key="item.slug" class="pa-2 fill-height">
            <SpecialProduct @click.native="goto(item.slug)" :item="item" />
          </v-card>
        </v-col>
      </v-row>

      <v-btn
        outlined
        block
        color="primary"
        :to="{
          name: 'products.specials',
        }"
        class="error lighten-5 mt-5"
        style="border-color: var(--v-primary-base) !important"
      >
        مشاهده همه <v-icon class="mr-3">mdi-arrow-left</v-icon>
      </v-btn>

      <!--            <v-btn-->
      <!--              outlined-->
      <!--              block-->
      <!--              color="primary"-->
      <!--              :to="{-->
      <!--                name: 'products.search',-->
      <!--                query: { specials: ['special', 'available'] },-->
      <!--              }"-->
      <!--              class="error lighten-5 mt-5"-->
      <!--              style="border-color: var(&#45;&#45;v-primary-base) !important"-->
      <!--            >-->
      <!--              مشاهده همه <v-icon class="mr-3">mdi-arrow-left</v-icon>-->
      <!--            </v-btn>-->
    </v-container>
  </div>
</template>

<script>
import ImageChanger from "@/app/components/Changer";
import SpecialProduct from "@/packages/home/components/special/Product";
export default {
  name: "SpecialMobile",
  components: { SpecialProduct, ImageChanger },
  props: {
    data: { type: Array, required: true },
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    slicedData() {
      return this.data.slice(
        this.howManyItems * this.currentIndex,
        this.howManyItems * this.currentIndex + this.howManyItems
      );
    },
    howManyItems() {
      return this.isMobile ? 4 : 6;
    },
    totalPages() {
      return Math.ceil(this.data.length / this.howManyItems);
    },
  },
  watch: {
    isMobile() {
      this.currentIndex = 0;
    },
  },
  methods: {
    next() {
      let _index = this.currentIndex + 1;
      if (_index > this.totalPages - 1) {
        _index = 0;
      }
      this.currentIndex = _index;
    },
    prev() {
      let _index = this.currentIndex - 1;
      if (_index < 0) {
        _index = this.totalPages - 1;
      }
      this.currentIndex = _index;
    },
    goto(slug) {
      this.$router.push({
        name: "product.detail",
        params: { slug },
      });
    },
    swipe(direction) {
      if (direction === "left") {
        this.prev();
      }
      if (direction === "right") {
        this.next();
      }
    },
  },
};
</script>

<style scoped></style>
