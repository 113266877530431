<template>
  <div class="w-100" style="position: relative">
    <v-text-field
      v-model="q"
      :placeholder="$_t('components.header.searchPlaceholder')"
      clearable
      dense
      outlined
      hide-details
      single-line
      rounded
      append-icon="mdi-magnify"
      background-color="white"
      color="new_blue"
      @focus="focusOnInput"
      @blur="blurOnInput"
      full-width
      class="lipak-search-input"
    >
      <template #append-outer>
        <v-fade-transition>
          <v-card
            v-if="showResult"
            color="white"
            tabindex="0"
            class="search-result pa-3 text-right user-select-none"
            style="z-index: 1"
            @focus="focusOnResult"
            @blur="blurOnResult"
          >
            <SearchBoxItem
              v-for="(item, index) in result"
              :key="`search-${index}`"
              :item="item"
              @blur="blurOnResult"
            />
          </v-card>
        </v-fade-transition>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import { getSearchResultAxiosRequest } from "@/app/config/repository/api/megaMenu.api";
import SearchBoxItem from "@/app/components/SearchBoxItem";
const itemTypes = Object.freeze({
  ALL: "all",
  PRODUCT: "product",
  BRAND: "brand",
  PRODUCT_GROUP: "product-group",
  COUNT: "count",
  LOADING: "loading",
  ERROR: "error",
  NOT_FOUND: "not-found",
});
export default {
  name: "SearchBox",
  components: { SearchBoxItem },
  data() {
    const result = [];
    if (this.$route.query.q) {
      // this.q = this.$route.query.q;
      result.push({ text: this.$route.query.q, type: itemTypes.ALL });
    }
    return {
      debouncedQ: null,
      itemTypes,
      result,
      timeout: "",
      inputFocus: false,
      resultFocus: false,
    };
  },
  computed: {
    showResult() {
      return this.result.length && (this.inputFocus || this.resultFocus);
    },
    q: {
      get() {
        return this.debouncedQ;
      },
      set(newValue) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.debouncedQ = newValue;
        }, 500);
      },
    },
  },
  watch: {
    debouncedQ(newValue) {
      if (!newValue) {
        this.result = [];
        return;
      }
      if (newValue.length < 2) {
        this.result = [{ text: newValue, type: this.itemTypes.NOT_FOUND }];
        return;
      }
      return this.searchFromServer(newValue);
    },
  },
  methods: {
    focusOnInput() {
      this.inputFocus = true;
    },
    blurOnInput() {
      setTimeout(() => {
        this.inputFocus = false;
      }, 0);
    },
    focusOnResult() {
      this.resultFocus = true;
    },
    blurOnResult() {
      setTimeout(() => {
        this.q = "";
        this.resultFocus = false;
      }, 300);
    },
    async searchFromServer(q) {
      this.result = [{ text: q, type: this.itemTypes.LOADING }];
      // const result = [{ text: q, type: this.itemTypes.ALL }];
      const result = [];
      try {
        const { data } = await getSearchResultAxiosRequest({ q });
        const { productGroups, brands, lps, lpCount, isBrand, isProductGroup } =
          data;
        if (productGroups.length) {
          result.push(
            ...productGroups.map((productGroup) => ({
              text: q,
              productGroup,
              isProductGroup,
              type: this.itemTypes.PRODUCT_GROUP,
            }))
          );
        }
        if (brands.length) {
          result.push(
            ...brands.map((brand) => ({
              text: q,
              brand,
              isBrand,
              type: this.itemTypes.BRAND,
            }))
          );
        }
        if (lps.length) {
          result.push(
            ...lps.map((lp) => ({
              text: q,
              lp,
              type: this.itemTypes.PRODUCT,
            }))
          );
        }
        if (lpCount) {
          result.push({
            text: q,
            currentLpCount: lps.length,
            totalLpCount: lpCount,
            type: this.itemTypes.COUNT,
          });
        }
        if (result.length === 0) {
          result.push({ text: q, type: this.itemTypes.NOT_FOUND });
        }
        this.result = result;
      } catch (error) {
        console.log(error);
        this.result = [{ text: q, type: this.itemTypes.ERROR }];
      }
    },
  },
};
</script>

<style scoped>
.search-result {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
}
</style>

<style>
.lipak-search-input .v-input__append-outer {
  margin: 0 !important;
}
</style>
