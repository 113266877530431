const state = () => ({
  orderModes: {},
  orders: [],
  latestOrders: [],
  states: [],
  orderDetail: {},
});
export const getters = {
  orderModes: (state) => state.orderModes,
  orders: (state) => state.orders,
  latestOrders: (state) => state.latestOrders,
  states: (state) => state.states,
  orderDetail: (state) => state.orderDetail,
};
export const mutations = {
  mutateOrderMods(state, payload) {
    state.orderModes = payload;
  },
  mutateOrders(state, payload) {
    state.orders = [];
    state.orders = payload;
  },

  /////////////start latest orders
  mutateLatestOrders(state, payload) {
    state.latestOrders.length = 0;
    payload.forEach((element) => {
      switch (element.state.first) {
        case "INPAYMENT":
          state.latestOrders.push({
            ...element,
            color: "error",
            bgcolor: "light_error",
            to: "INPAYMENT",
          });
          return;
        case "INPROCESS":
          state.latestOrders.push({
            ...element,
            state: {
              first: element.state.first,
              second: "در حال اجرا",
            },
            color: "primary",
            bgcolor: "light_primary",
            to: "INPROCESS",
          });

          return;
        case "DELIVERED":
          state.latestOrders.push({
            ...element,
            color: "success",
            bgcolor: "light_success",
            to: "DELIVERED",
          });
          return;
        case "RETURNED":
          state.latestOrders.push({
            ...element,
            color: "error",
            bgcolor: "light_error",
            to: "RETURNED",
          });
          return;
        case "CANCELED":
          state.latestOrders.push({
            ...element,
            color: "error",
            bgcolor: "light_error",
            to: "CANCELED",
          });
          return;
      }
    });
  },
  /////////////end latest orders

  ///states
  mutateSetStates(state, payload) {
    let data = [];
    payload.forEach((element) => {
      switch (element.state) {
        case "INPAYMENT":
          data.push({ ...element, icon: "mdi-vanish", color: "primary" });
          return;
        case "INPROCESS":
          data.push({
            ...element,
            statePersianName: "در حال اجرا",
            icon: "mdi-checkbox-marked-circle-outline",
            color: "warning",
          });
          return;
        case "DELIVERED":
          data.push({ ...element, icon: "mdi-weight", color: "success" });
          return;
        case "RETURNED":
          data.push({ ...element, icon: "mdi-reload", color: "blue" });
          return;
        case "CANCELED":
          data.push({
            ...element,
            icon: "mdi-package-variant-closed-remove",
            color: "error",
          });
          return;
      }
    });
    state.states = data;
  },
  mutateClearStates(state, payload) {
    state.states = payload;
  },

  mutateOrderDetail(state, payload) {
    state.orderDetail = payload;
  },
};
export const actions = {
  setOrderMods({ commit }, payload) {
    commit("mutateOrderMods", payload);
  },
  setOrders({ commit }, payload) {
    commit("mutateOrders", payload);
  },
  setLatestOrders({ commit }, payload) {
    commit("mutateLatestOrders", payload);
  },

  /////states
  setStates({ commit }, payload) {
    commit("mutateSetStates", payload);
  },
  clearStates({ commit }, payload) {
    commit("mutateClearStates", payload);
  },

  setOrderDetail({ commit }, payload) {
    commit("mutateOrderDetail", payload);
  },
};
export default {
  name: "track_orders",
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
