import lang from "@/helpers/lang.helper";

// Utils
// general and useful functions to project.
//

export function _t(key, values = null) {
  return lang.t(key, values);
}

export function fileSize(fileSize) {
  try {
    if (!fileSize) return "...";
    return fileSize.toFixed(1) + "m";
  } catch (e) {
    return 0;
  }
}

export function pushOrMergeToArray(array, new_object, key = "id") {
  try {
    let index = array.findIndex((item) => item[key] === new_object[key]);
    if (index === -1) array.push(new_object);
    else array[index] = { ...array[index], ...new_object };

    if (Array.isArray(array)) {
      uniqueArray(array, key);
    }

    return array;
  } catch (e) {
    return [];
  }
}

export function uniqueArray(array, key = "id") {
  try {
    let new_array = [];
    array.filter(function (item) {
      let result = new_array.findIndex((x) => x[key] === item[key]);
      if (result < 0) new_array.push(item);
    });
    return new_array;
  } catch (e) {
    return array;
  }
}

export function calculateFileSize(bytes) {
  try {
    let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (!bytes || bytes == 0) return "0 Byte";
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  } catch (e) {
    return "0 Byte";
  }
}

export function snakeToCamel(str) {
  return str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase());
}
export function snakeToPascal(str) {
  const camelCase = snakeToCamel(str);
  return camelCase[0].toUpperCase() + camelCase.substr(1);
}
