import storageService from "@/services/LocalStorageService.service";
import router from "@/app/router/index";
// Auth Class
// this class handle user info and user
// token.

const auth = {
  supper_key: "@auth_user:",

  // Set user info
  setUserInfo(value) {
    storageService.set(this.supper_key + "user_info", value);
  },

  // Get user info
  getUserInfo() {
    return storageService.get(this.supper_key + "user_info");
  },

  // This method save user token
  // Set otp token
  saveInitToken(value) {
    storageService.set(this.supper_key + "init_token", value);
  },

  // Set otp token
  saveOtpToken(value) {
    storageService.set(this.supper_key + "otp_token", value);
  },

  // on database
  saveToken(token) {
    storageService.set(this.supper_key + "token", token);
  },

  saveIsRegistered(isRegistered) {
    storageService.set(this.supper_key + "is_registered", isRegistered);
  },

  // This method save user refresh token
  // on database
  saveRefreshToken(refresh_token) {
    storageService.set(this.supper_key + "refresh_token", refresh_token);
  },

  saveExpiration(data) {
    storageService.set(this.supper_key + "expiration", data);
  },

  savePhone(data) {
    storageService.set(this.supper_key + "phone", data);
  },

  saveRedirectRouteAfterAuthentication(value) {
    storageService.set(this.supper_key + "redirect", value);
  },

  // This method get user token
  // on database
  getInitToken() {
    return storageService.get(this.supper_key + "init_token");
  },

  getOtpToken() {
    return storageService.get(this.supper_key + "otp_token");
  },

  // on database
  getToken() {
    return storageService.get(this.supper_key + "token");
  },

  getIsRegistered() {
    return storageService.get(this.supper_key + "is_registered");
  },

  getRefreshToken() {
    return storageService.get(this.supper_key + "refresh_token");
  },

  getExpiration() {
    return storageService.get(this.supper_key + "expiration");
  },

  getPhone() {
    return storageService.get(this.supper_key + "phone");
  },

  getRedirectRouteAfterAuthentication() {
    return storageService.get(this.supper_key + "redirect");
  },

  // This method get user token
  // on database
  check() {
    let token = this.getToken();
    return token != null && token !== "" && token !== undefined;
  },

  // This method remove token and all user data
  // of database
  removeToken() {
    storageService.remove(this.supper_key + "user_info");
    storageService.remove(this.supper_key + "token");
    storageService.remove(this.supper_key + "otp_token");
    storageService.remove(this.supper_key + "init_token");
    storageService.remove(this.supper_key + "refresh_token");
    storageService.remove(this.supper_key + "expiration");
    storageService.remove(this.supper_key + "is_registered");
    storageService.remove(this.supper_key + "redirect");
    storageService.remove("userName");
    storageService.remove("personal-info");
    storageService.remove("phone");
    storageService.remove("@auth_user:phone");
  },

  clearRedirectRouteAfterAuthentication() {
    return storageService.remove(this.supper_key + "redirect");
  },

  clearOtpToken() {
    storageService.remove(this.supper_key + "otp_token");
  },

  clearInitToken() {
    storageService.remove(this.supper_key + "init_token");
  },

  // This method remove token and all user data
  // of database
  clear() {
    console.log("cleare");
    this.removeToken();
    router.push({ name: "authentication" }).catch(() => {});
  },
};
export function clearAuth() {
  auth.clear();
}
export default auth;
