// initial state
const state = () => ({
  items: undefined,
});

// getters
export const getters = {
  items: (state) => state.items,
};

// mutations
export const mutations = {
  setItems(state, value) {
    state.items = value;
  },
};

// actions
export const actions = {
  saveItems({ commit }, data) {
    commit("setItems", data);
  },
};

export default {
  namespaced: true,
  name: "megaMenu",
  state,
  getters,
  actions,
  mutations,
};
