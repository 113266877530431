var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"me-n7 ms-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"row flex-wrap px-0",attrs:{"cols":"9"}},[(_vm.hasExists(_vm.item.labels))?_c('v-col',{staticClass:"d-flex justify-space-between",staticStyle:{"height":"fit-content"},attrs:{"cols":"2"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"secondary--text font-weight-bold mb-5"},[_vm._v(" "+_vm._s(_vm.$_t("components.header.mega_menu.label_title", { title: _vm.item.title, }))+" ")]),_vm._l((_vm.item.labels),function(label){return _c('router-link',{key:label.id,staticClass:"text-decoration-none more--text font-weight-medium py-1 w-100",attrs:{"to":{
            name: 'products.label-brand',
            params: { cat_title: _vm.item.slug, title: label.slug },
            // query: { catId: item.productGroupId, labelId: label.id },
          }}},[_vm._v(" "+_vm._s(label.title)+" ")])})],2)]):_vm._e(),(_vm.hasExists(_vm.item.brands))?_c('v-col',{staticClass:"d-flex justify-start",staticStyle:{"height":"fit-content"},attrs:{"cols":"2"}},[(_vm.item.labels.length)?_c('v-divider',{staticClass:"ml-6",staticStyle:{"height":"auto"},attrs:{"vertical":""}}):_vm._e(),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"secondary--text font-weight-bold mb-5"},[_vm._v(" "+_vm._s(_vm.$_t("components.header.mega_menu.brand_title", { title: _vm.item.title, }))+" ")]),_vm._l((_vm.item.brands),function(brand){return _c('router-link',{key:brand.id,staticClass:"text-decoration-none more--text font-weight-medium py-1 w-100",attrs:{"to":{
            name: 'products.label-brand',
            params: { cat_title: _vm.item.slug, title: brand.slug },
            // query: { catId: item.productGroupId, brandId: brand.id },
          }}},[_vm._v(" "+_vm._s(_vm.item.title)+" "+_vm._s(brand.title)+" ")])})],2)],1):_vm._e(),(_vm.hasExists(_vm.item.children))?_vm._l((_vm.item.children),function(child){return _c('v-col',{key:child.productGroupId,staticClass:"d-flex justify-start",staticStyle:{"height":"fit-content"},attrs:{"cols":"2"}},[_c('v-divider',{staticClass:"ml-6",staticStyle:{"height":"auto"},attrs:{"vertical":""}}),_c('div',[_c('router-link',{staticClass:"text-decoration-none d-block secondary--text font-weight-bold mb-5",attrs:{"to":{
              name: 'products.category',
              params: { cat_title: child.slug },
              // query: { catId: child.productGroupId },
            },"replace":""}},[_vm._v(" "+_vm._s(child.title)+" ")]),(_vm.hasExists(child.labels))?_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"secondary--text font-weight-bold mb-2 ps-2 border-right"},[_vm._v(" "+_vm._s(_vm.$_t("components.header.mega_menu.label_title", { title: "", }))+" ")]),_vm._l((child.labels),function(label){return _c('router-link',{key:label.id,staticClass:"text-decoration-none d-block more--text font-weight-medium py-1 w-100",attrs:{"to":{
                name: 'products.label-brand',
                params: { cat_title: child.slug, title: label.slug },
                // query: { catId: child.productGroupId, labelId: label.id },
              }}},[_vm._v(" "+_vm._s(label.title)+" ")])})],2):_vm._e(),(_vm.hasExists(child.brands))?_c('div',[_c('div',{staticClass:"secondary--text font-weight-bold mb-2 ps-2 border-right"},[_vm._v(" "+_vm._s(_vm.$_t("components.header.mega_menu.brand_title", { title: "", }))+" ")]),_vm._l((child.brands),function(brand){return _c('router-link',{key:brand.id,staticClass:"text-decoration-none d-block more--text font-weight-medium py-1 w-100",attrs:{"to":{
                name: 'products.label-brand',
                params: { cat_title: child.slug, title: brand.slug },
                // query: { catId: child.productGroupId, brandId: brand.id },
              }}},[_vm._v(" "+_vm._s(child.title)+" "+_vm._s(brand.title)+" ")])})],2):_vm._e()],1)],1)}):_vm._e()],2),_c('v-col',{staticClass:"pe-0 d-flex justify-center",attrs:{"cols":"3"}},[(_vm.item.desktopImage)?_c('v-img',{class:{ 'cursor-pointer': _vm.item.cta },attrs:{"width":"373","height":"373","contain":"","src":_vm.imageUrlBuilder(_vm.item.desktopImage),"alt":_vm.item.desktopImageAlt},on:{"click":_vm.goToCta},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-sheet',{attrs:{"height":"100%","color":"white"}},[_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","aria-label":"progress"}})],1)],1)]},proxy:true}],null,false,542569861)}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }