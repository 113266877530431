"use strict";

export default [
  {
    path: "/terms",
    name: "terms",
    component: () =>
      import(
        /* webpackChunkName: "static-pages" */ "@/packages/static-pages/presentation/views/TermsAndConditions"
      ),
  },
  {
    path: "/order-canceling-guide",
    name: "order-canceling-guide",
    component: () =>
      import(
        /* webpackChunkName: "static-pages" */ "@/packages/static-pages/presentation/views/OrderCancelingGuide"
      ),
  },
  {
    path: "/payment-methods-guide",
    name: "payment-methods-guide",
    component: () =>
      import(
        /* webpackChunkName: "static-pages" */ "@/packages/static-pages/presentation/views/PaymentMethodsGuide"
      ),
  },
  {
    path: "/guarantee-guide",
    name: "guarantee-guide",
    component: () =>
      import(
        /* webpackChunkName: "static-pages" */ "@/packages/static-pages/presentation/views/GuaranteeGuide"
      ),
  },
  {
    path: "/faq",
    name: "faq",
    component: () =>
      import(
        /* webpackChunkName: "static-pages" */ "@/packages/static-pages/presentation/views/Faq"
      ),
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: () =>
      import(
        /* webpackChunkName: "static-pages" */ "@/packages/static-pages/presentation/views/ContactUs"
      ),
  },
  {
    path: "/about-us",
    name: "about-us",
    component: () =>
      import(
        /* webpackChunkName: "static-pages" */ "@/packages/static-pages/presentation/views/AboutUs.vue"
      ),
  },
  {
    path: "/installment-purchase-guide",
    name: "installment-purchase-guide",
    component: () =>
      import(
        /* webpackChunkName: "static-pages" */ "@/packages/static-pages/presentation/views/InstallmentPurchaseGuide.vue"
      ),
  },
];
