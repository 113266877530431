// initial state

const state = () => ({
  price_summary: {
    total_price: 0,
    discount: 0,
    final_price: 0,
  },
});

// getters
export const getters = {
  price_summary: (state) => state.price_summary,
};

// mutations
export const mutations = {
  mutatePriceSummary(state, value) {
    state.price_summary = value;
  },
};

// actions
export const actions = {
  setPriceSummary({ commit }, data) {
    commit("mutatePriceSummary", data);
  },
};

export default {
  namespaced: true,
  name: "order.price_summary",
  state,
  getters,
  actions,
  mutations,
};
