export default {
  methods: {
    createMeta({ title, description, imageSrc, canonical, torob }) {
      const metaInfo = {
        title,
        meta: [],
        link: [],
      };
      if (title) {
        metaInfo.meta.push(
          {
            name: "og:title",
            vmid: "og:title",
            content: title,
          },
          {
            name: "twitter:title",
            vmid: "twitter:title",
            content: title,
          },

          {
            name: "product_name",
            vmid: "product_name",
            content: title,
          }
        );
      }
      if (description) {
        metaInfo.meta.push(
          {
            name: "description",
            vmid: "description",
            content: description,
          },
          {
            name: "og:description",
            vmid: "og:description",
            content: description,
          },
          {
            name: "twitter:description",
            vmid: "twitter:description",
            content: description,
          }
        );
      }
      if (imageSrc) {
        metaInfo.meta.push(
          {
            name: "og:image",
            vmid: "og:image",
            content: imageSrc,
          },
          {
            name: "twitter:image",
            vmid: "twitter:image",
            content: imageSrc,
          }
        );
      }
      if (canonical) {
        metaInfo.link.push({
          rel: "canonical",
          vmid: "canonical",
          href: canonical,
        });
      }
      //Torob meta tags
      if (torob) {
        if (torob.product_id) {
          metaInfo.meta.push({
            name: "product_id",
            vmid: "product_id",
            content: torob.product_id,
          });
        }
        if (torob.product_price) {
          metaInfo.meta.push({
            name: "product_price",
            vmid: "product_price",
            content: torob.product_price,
          });
        }
        if (torob.product_old_price) {
          metaInfo.meta.push({
            name: "product_old_price",
            vmid: "product_old_price",
            content: torob.product_old_price,
          });
        }
        if (torob.availability) {
          metaInfo.meta.push({
            name: "availability",
            vmid: "availability",
            content: torob.availability,
          });
        }
        if (torob.guarantee) {
          metaInfo.meta.push({
            name: "guarantee",
            vmid: "guarantee",
            content: torob.guarantee,
          });
        }
      }
      return metaInfo;
    },
  },
};
