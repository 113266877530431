<template>
  <v-container fluid class="px-4 px-lg-16 pt-5 mt-10 mt-lg-0">
    <div class="text-center">
      <v-icon color="primary" x-large>mdi-percent</v-icon>
      <span class="primary--text text-h4 font-weight-black mx-3">
        پیشنهاد شگفت‌انگیز
      </span>
      <v-icon color="primary" x-large>mdi-percent</v-icon>
    </div>
    <div class="mt-5 d-flex justify-space-between align-center">
      <div class="d-flex align-center">
        <div
          class="ml-5 primary--text text-darken-4 font-weight-medium text-h6 pa-1 rounded"
        >
          زمان باقی‌مانده تا پایان سفارش:
        </div>
        <AmazingTimer
          @finish-timer="$emit('finish-timer')"
          :time="data.remain"
        />
      </div>
      <div v-if="hasOverflow">
        <ImageChanger @next="scrollLeft" @prev="scrollRight" />
      </div>
    </div>
    <div
      class="d-flex mt-11 pb-3 overflow-x-auto hide-scrollbar cursor-grab"
      ref="productContainer"
      @mousedown="startDrag"
      @mousemove="doDrag"
    >
      <v-card
        v-for="(item, index) in data.amazingShopGetDto"
        :key="`item-${index}`"
        elevation="0"
        outlined
        :width="amazingCardWidth"
        :min-width="amazingCardWidth"
        :max-width="amazingCardWidth"
        class="user-select-none pa-2"
        :class="[index && 'mr-3']"
      >
        <AmazingProduct :item="item" @click.native="goto($event, item)" />
      </v-card>
    </div>
  </v-container>
</template>

<script>
import AmazingProduct from "@/packages/home/components/amazing/Product";
import AmazingTimer from "@/packages/home/components/amazing/Timer";
import ImageChanger from "@/app/components/Changer";
export default {
  name: "AmazingDesktop",
  components: { ImageChanger, AmazingTimer, AmazingProduct },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      amazingCardWidth: "290",
      mounted: false,
      recalculateOverflow: false,
      dragging: false,
      pos: { x: 0, y: 0 },
    };
  },
  computed: {
    hasOverflow() {
      if (!this.mounted) return;
      this.recalculateOverflow; // just to recalculate overflow
      const container = this.$refs["productContainer"];
      return container.scrollWidth > container.clientWidth;
    },
  },
  mounted() {
    this.mounted = true;
    addEventListener("resize", this.resizeListener);
    addEventListener("orientationchange", this.resizeListener);
    addEventListener("deviceorientation", this.resizeListener);
    addEventListener("mouseup", this.stopDrag);
  },
  methods: {
    resizeListener() {
      this.recalculateOverflow = !this.recalculateOverflow;
    },
    scrollLeft() {
      const container = this.$refs["productContainer"];
      container.scroll({
        left: container.scrollLeft - container.clientWidth,
        behavior: "smooth",
      });
    },
    scrollRight() {
      const container = this.$refs["productContainer"];
      container.scroll({
        left: container.scrollLeft + container.clientWidth,
        behavior: "smooth",
      });
    },
    startDrag(e) {
      this.$refs["productContainer"].style.cursor = "grabbing";
      this.pos = { x: e.x, y: e.y };
      this.dragging = true;
    },
    doDrag(e) {
      if (this.dragging) {
        const container = this.$refs["productContainer"];
        container.scroll({
          left: container.scrollLeft - e.movementX,
        });
      }
    },
    stopDrag() {
      this.$refs["productContainer"].style.cursor = "grab";
      this.dragging = false;
    },
    goto(e, item) {
      if (this.pos.x === e.x && this.pos.y === e.y) {
        this.$router.push({
          name: "product.detail",
          params: { slug: item.slug },
        });
      }
    },
  },
  beforeDestroy() {
    removeEventListener("resize", this.resizeListener);
    removeEventListener("orientationchange", this.resizeListener);
    removeEventListener("deviceorientation", this.resizeListener);
    removeEventListener("mouseup", this.stopDrag);
  },
};
</script>

<style scoped></style>
