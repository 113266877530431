<template>
  <v-app class="ss02">
    <!-- Start content            ---------------------------------------------------------------------->
    <v-main>
      <Container />
    </v-main>
    <!-- End   content            ---------------------------------------------------------------------->
  </v-app>
</template>
<script>
export default {
  name: "Auth",
  components: {
    Container: () =>
      import(
        "@/packages/authentication/presentation/components/main/Container"
      ),
  },
};
</script>

<style lang="scss" scoped></style>
