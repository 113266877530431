import store from "@/app/store";
export default {
  data() {
    return {};
  },

  created() {},

  methods: {
    async paginationQueryBuilder(page, size) {
      page = page
        ? page - 1
        : (await store.getters["pagination/current_page"]) - 1;
      size = size ? size : await store.getters["pagination/size"];
      return {
        pageNumber: page,
        pageSize: size,
      };
    },

    queryBuilder(filter, filters) {
      for (let prop in filter) {
        filters.set(prop, filter[prop]);
        if (filter[prop] === undefined || filter[prop].length === 0)
          filters.delete(prop);
      }
      return new Map(filters);
    },
  },
};
