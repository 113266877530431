<template>
  <div class="ss02">
    <div
      class="font-weight-bold mb-3 text-h6 black--text text-center text-md-right"
    >
      راه ارتباطی با لیپک
    </div>

    <div class="communication-title">
      <v-icon color="black">mdi-map-marker-outline</v-icon>
      آدرس:
    </div>
    <div>
      تهران - خیابان ولی‌عصر - بالاتر از میدان ولی‌عصر -کوچه شهید شهامتی - پلاک
      21
    </div>

    <div class="communication-title">
      <v-icon color="black">mdi-clock-outline</v-icon>
      ساعت کاری:
    </div>
    <div>
      شنبه تا چهارشنبه از ساعت <span class="primary--text">9</span> صبح الی
      <span class="primary--text">17</span>، پنجشنبه ها
      <span class="primary--text">9</span> صبح الی
      <span class="primary--text">13</span>
    </div>

    <div class="communication-title">
      <v-icon color="black">mdi-email-outline</v-icon>
      ایمیل:
    </div>
    <div>
      <a class="text-decoration-none black--text" href="mailto:info@lipak.com">
        info@lipak.com
      </a>
    </div>
    <div class="communication-title">
      <v-icon color="black">mdi-headset</v-icon>
      ساعت پاسخگویی پشتیبانی:
    </div>
    <div>
      شنبه تا چهارشنبه <span class="primary--text">۹</span> الی
      <span class="primary--text">۲۴</span>
      <br />
      پنج شنبه و جمعه
      <span class="primary--text">۹</span> الی
      <span class="primary--text">۱۷</span>
    </div>
    <div>
      تعطیلات رسمی<span class="primary--text"> ۱۰</span> صبح الی
      <span class="primary--text">16</span>
    </div>
    <v-btn
      color="primary"
      outlined
      href="tel:02158256000"
      class="mt-5 font-weight-bold text-body-1"
      block
      large
    >
      021-58256000<v-icon class="mr-5">mdi-phone-in-talk-outline</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "Communication",
};
</script>

<style scoped>
.communication-title {
  font-weight: bold;
  color: black;
  margin: 8px 0;
}
</style>
