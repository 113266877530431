module.exports = {
  key: "products",
  products: {
    components: {},
    views: {},
    route_titles: {},
  },
  attribute: {},
  placeholder: {},
};
