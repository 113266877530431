// initial state
// import { MessageService } from "@/services/MessageService.service";

const state = () => ({
  featureGroups: [],
  specificCategoryProducts: [],
  comparedProducts: [],
});

// getters
export const getters = {
  featureGroups: (state) => state.featureGroups,
  specificCategoryProducts: (state) => state.specificCategoryProducts,
  comparedProducts: (state) => state.comparedProducts,
};

// mutations
export const mutations = {
  mutateFeatureGroups(state, payload) {
    state.featureGroups.push([...payload]);
  },
  mutateSpecificCategoryProducts(state, payload) {
    state.specificCategoryProducts = payload;
  },
  mutateRemoveProduct(state, payload) {
    state.featureGroups.splice(payload.productIndex, 1);
    state.comparedProducts.splice(payload.productIndex, 1);
  },
  mutateClearProducts(state) {
    state.specificCategoryProducts = [];
    state.featureGroups = [];
    state.comparedProducts = [];
  },
  mutateAddProductToProductsCompare(state, payload) {
    state.comparedProducts.push(payload);
  },
};

// actions
export const actions = {
  addProductToProductsCompare({ commit }, payload) {
    commit("mutateAddProductToProductsCompare", payload);
  },
  setFeatureGroups({ commit }, payload) {
    commit("mutateFeatureGroups", payload.featureGroups);
  },
  setSpecificCategoryProducts({ commit }, payload) {
    commit("mutateSpecificCategoryProducts", payload);
  },
  removeComparedProduct({ commit }, payload) {
    commit("mutateRemoveProduct", payload);
  },
  clearComparedProducts({ commit }) {
    commit("mutateClearProducts");
  },
};

export default {
  namespaced: true,
  name: "compare",
  state,
  getters,
  actions,
  mutations,
};
