const state = () => ({
  contestItems: [],
});
export const getters = {
  contestItems: (state) => state.contestItems,
};
export const mutations = {
  mutateContestItems(state, payload) {
    state.contestItems = payload;
  },
};
export const actions = {
  setContestItems({ commit }, payload) {
    commit("mutateContestItems", payload);
  },
};
export default {
  name: "wheel_of_luck",
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
