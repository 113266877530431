<template>
  <v-hover v-slot="{ hover }">
    <v-card
      outlined
      :style="[
        campaignDetail
          ? {
              border:
                '1px solid ' + `${campaignDetail.colorHex}` + '!important',
            }
          : '',
      ]"
      class="pa-3 ss02 cursor-pointer fill-height d-flex flex-column justify-space-between"
      link
      :to="{
        name: 'product.detail',
        params: { slug: item.slug },
      }"
    >
      <div class="d-flex justify-space-between pb-2">
        <div v-if="campaignDetail">
          <div
            :style="{
              color: `${campaignDetail.colorHex} `,
            }"
            class="font-weight-bold text-body-1"
          >
            {{ campaignDetail.title }}
          </div>
          <div
            v-if="campaignDetail.discount"
            :style="{
              color: `${campaignDetail.colorHex} `,
            }"
            class="font-weight-bold text-body-2"
          >
            + تخفیف {{ $_currency(campaignDetail.discount / 10) }} تومانی
          </div>
        </div>
        <v-btn
          v-if="isProductHasDiscount || campaignDetail"
          small
          :style="[
            campaignDetail
              ? {
                  backgroundColor: campaignDetail.colorHex + '!important',
                }
              : '',
          ]"
          class="rounded-sm text-body-2 mr-auto white--text primary"
          :class="[
            !+percent
              ? $vuetify.breakpoint.xs
                ? 'd-none'
                : 'visibility-hidden'
              : '',
          ]"
        >
          <v-fade-transition mode="out-in">
            <span :key="percent">{{ percent }}%</span>
          </v-fade-transition>
        </v-btn>
      </div>
      <figure class="d-flex justify-space-between align-center">
        <div
          v-if="!image.url"
          class="d-flex justify-center align-center"
          mode="in-out"
          style="width: 100px; height: 100px"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            aria-label="progress"
          />
        </div>
        <img
          v-else
          class="mt-1 product-image-size"
          :src="image.url"
          :alt="image.alt"
          mode="in-out"
          @error="catchImageLoadingError"
        />
        <!--        <v-img-->
        <!--          class="mt-1"-->
        <!--          :src="image.url"-->
        <!--          contain-->
        <!--          width="100px"-->
        <!--          height="100px"-->
        <!--          min-width="100px"-->
        <!--          max-width="100px"-->
        <!--          @error="catchImageLoadingError"-->
        <!--          mode="in-out"-->
        <!--        >-->
        <!--          <template v-slot:placeholder>-->
        <!--            <v-sheet height="100%" color="white">-->
        <!--              <v-row class="fill-height ma-0" align="center" justify="center">-->
        <!--                <v-progress-circular-->
        <!--                  indeterminate-->
        <!--                  color="primary"-->
        <!--                  aria-label="progress"-->
        <!--                />-->
        <!--              </v-row>-->
        <!--            </v-sheet>-->
        <!--          </template>-->
        <!--        </v-img>-->

        <figcaption class="w-100 pr-1">
          <div style="position: relative" class="mt-2">
            <p
              class="text-body-1 font-weight-bold more--text mb-0 line-clamp text-right"
              :class="[isMobile && 'text-body-2']"
            >
              {{ item.title }}
            </p>
            <v-expand-transition>
              <div
                v-if="hover"
                class="text-body-1 white font-weight-bold secondary--text text-wrap text-right card-product__price-wrapper-title-hovered"
                :class="[isMobile && 'text-body-2']"
              >
                {{ item.title }}
              </div>
            </v-expand-transition>
          </div>
          <div class="d-flex justify-space-between align-center mt-6">
            <div class="d-flex flex-wrap">
              <div
                v-for="(color, index) in colors"
                :key="`${color}-${index}`"
                class="rounded-circle mt-1 ml-1"
                style="border: 1px solid var(--v-secondary-lighten5)"
                :style="{
                  backgroundColor: color,
                  width: isMobile ? '8px' : '10px',
                  height: isMobile ? '8px' : '10px',
                }"
              />
            </div>
            <div class="d-flex" v-if="item.satisfactionRate.count">
              <v-rating
                dir="ltr"
                class="amazing-rating"
                color="warning"
                length="5"
                dense
                readonly
                half-increments
                :value="item.satisfactionRate.score"
                :size="isMobile ? 16 : 18"
              />
            </div>
          </div>
          <div
            v-if="item.saleType === 'STOP'"
            class="d-flex justify-space-between mt-3 primary--text font-weight-bold"
          >
            <div class="mr-auto">ناموجود</div>
          </div>
          <div v-else class="d-flex justify-space-between mt-3">
            <div
              v-if="+percent"
              class="info--text font-weight-medium text-small text-decoration-line-through"
            >
              {{ formatPrice(item.basePrice / 10) }}
            </div>
            <div class="primary--text font-weight-bold text-left mr-auto">
              {{ formatPrice(item.salePrice / 10) }} تومان
            </div>
          </div>
        </figcaption>
      </figure>
    </v-card>
  </v-hover>
</template>

<script>
import imageUrlBuilder from "@/app/mixins/mixin.image_url_builder";

export default {
  name: "CategoryProduct",
  mixins: [imageUrlBuilder],
  props: {
    item: {
      type: Object,
      required: true,
    },
    campaign: {
      type: Object,
      required: false,
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    colors() {
      return [
        ...new Set(
          this.item.colors
            ? this.item.colors.map((v) => {
                let color = v;
                if (v.length === 3) {
                  color = `${v[0]}${v[0]}${v[1]}${v[1]}${v[2]}${v[2]}`;
                }
                return color.toLowerCase();
              })
            : []
        ),
      ];
    },
    percent() {
      return Math.ceil(
        ((this.item.basePrice - this.item.salePrice) / this.item.basePrice) *
          100
      ).toFixed(0);
    },
    image() {
      const { image } = this.item;
      return {
        url:
          image && !this.imageHasError
            ? this.imageUrlBuilder(image.url)
            : require("@/app/assets/images/product-default-image.png"),
        alt: image && image.alt ? image.alt : this.item.title,
      };
    },
    campaignDetail() {
      return this.item.campaign || this.campaign;
    },
    isNormalSaleType() {
      return this.item.type === "NORMAL";
    },

    isProductHasDiscount() {
      return (
        Number(this.item.basePrice) > Number(this.item.salePrice) &&
        !this.isNormalSaleType
      );
    },
  },
  methods: {
    catchImageLoadingError() {
      this.imageHasError = true;
    },
    formatPrice(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<style scoped>
.card-product__price-wrapper-title-hovered {
  position: absolute !important;
  top: 0;
  width: 100%;
  z-index: 4;
}
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.product-image-size {
  min-width: 100px !important;
  max-width: 100px !important;
  width: 100px;
  height: 100px;
}
</style>
