// initial state

import { MAXIMUM_PRICE_ALLOWED_ONLINE_PAY } from "@/packages/order/config/MAXIMUM_PRICE_ALLOWED_LOCAL_PAY";

const state = () => ({
  setting: [],
  method: null,
  bank_gateway: null,
  paymentDisable: false,

  ///// installment setting
  balon: {},
  balonStatus: false,
  creditStatus: false,
  installmentsHasPriceDifferent: false,
  creditPaymentMethod: {},
  orderInvoice: null,
  balonOtp: null,
  baloanCreditStatus: null,
  installmentsPrice: null,
});

// getters
export const getters = {
  setting: (state) => state.setting,
  maxOnlinePaymentAllowed: (state) => {
    let found = state.setting.find((item) => item.accounts);
    if (found) return found.maxPaymentAllowed;
    else return MAXIMUM_PRICE_ALLOWED_ONLINE_PAY;
  },
  accounts: (state) => {
    let found = state.setting.find((item) => item.accounts);
    if (found) return found.accounts;
    else return [];
  },
  online_payment_methods: (state) => {
    let found = state.setting.find((item) => item.gateways);
    if (found) return found.paymentMethod;
    else return "ONLINE";
  },
  check_payment_methods: (state) => {
    let found = state.setting.find((item) => item.accounts);
    if (found) return found.paymentMethod;
    else return "CHECK";
  },
  method: (state) => state.method,
  bank_gateway: (state) => state.bank_gateway,
  payment_method_is_valid_for_pay: (state) =>
    !!(
      state.method === "INPLACE" ||
      state.method === "CHECK" ||
      state.method === "INSTALLMENT" ||
      state.bank_gateway
    ),
  balon_otp_is_valid_for_pay: (state) =>
    !(
      state.balonStatus &&
      !state.installmentsHasPriceDifferent &&
      !state.balonOtp
    ),

  balon_credit_is_valid_for_pay: (state) => {
    let baloanIsValid = !!(
      state.baloanCreditStatus && state.baloanCreditStatus.hasBaloan
    );
    return !!(state.balonStatus && !baloanIsValid);
  },

  paymentDisable: (state) => state.paymentDisable,
  balon: (state) => state.balon,
  creditPaymentMethod: (state) => state.creditPaymentMethod,
  balonStatus: (state) => state.balonStatus,
  creditStatus: (state) => state.creditStatus,
  installmentsHasPriceDifferent: (state) => state.installmentsHasPriceDifferent,

  orderInvoice: (state) => state.orderInvoice,
  balonOtp: (state) => state.balonOtp,
  baloanCreditStatus: (state) => state.baloanCreditStatus,
  installmentsPrice: (state) => state.installmentsPrice,
};

// mutations
export const mutations = {
  mutatePaymentSetting(state, value) {
    //set online payment as first element
    let arr = [...value];
    let online = arr.find((item) => item.gateways);
    let index = arr.indexOf(online);
    arr.splice(index, 1);
    arr.unshift(online);

    //find balon and set in state
    let balonIndex = value.findIndex(
      (item) => item.paymentMethod === "INSTALLMENT"
    );
    if (balonIndex >= 0) {
      state.balon = value[balonIndex];

      //delete balon finded in paymentMethods
      arr.splice(balonIndex, 1);
    }

    //find balon and set in state
    let creditIndex = value.findIndex(
      (item) => item.paymentMethod === "CREDIT"
    );
    if (creditIndex >= 0) {
      state.creditPaymentMethod = value[creditIndex];

      //delete credit finded in paymentMethods
      arr.splice(creditIndex, 1);
    }

    //set check payment as selected payment method if order greater than max online pay allowed
    let activeMethods = arr.filter((item) => item.isActive);
    if (
      activeMethods.length === 1 &&
      activeMethods[0].paymentMethod === "CHECK"
    ) {
      state.method = value = activeMethods[0].paymentMethod;
    }

    ///set first active payment active
    arr.forEach((item) => {
      if (item.gateways) {
        state.method = item.paymentMethod;
      }
    });

    state.setting = arr;
  },
  mutatePaymentMethod(state, value) {
    state.method = value;
  },
  mutateBankGateway(state, value) {
    state.bank_gateway = value;
  },
  mutatePaymentDisable(state, payload) {
    state.paymentDisable = payload;
  },
  mutateBalonStatus(state, payload) {
    state.balonStatus = payload;
  },
  mutateCreditStatus(state, payload) {
    state.creditStatus = payload;
  },
  mutateInstallmentsHasPriceDifferent(state, payload) {
    state.installmentsHasPriceDifferent = payload;
  },
  mutateOrderInvoice(state, data) {
    state.orderInvoice = data;
  },

  mutateClearInvoice(state) {
    state.orderInvoice = null;
  },
  mutateBalonOtp(state, payload) {
    state.balonOtp = payload;
  },
  mutateClearInstallmentSetting(state) {
    state.balonStatus = false;
    state.creditStatus = false;
    state.installmentsHasPriceDifferent = false;
    state.orderInvoice = null;
    state.balonOtp = null;
  },

  mutateBaloanCreditStatus(state, payload) {
    state.baloanCreditStatus = payload;
  },

  mutateDefualtPaymentMethod(state) {
    let paymentMethods = state.setting;
    let fistActiveMethod = paymentMethods.find((item) => item.isActive);

    if (fistActiveMethod) {
      state.method = fistActiveMethod.paymentMethod;
    }
  },

  mutateInstallmentsPrice(state, payload) {
    state.installmentsPrice = payload;
  },
};

// actions
export const actions = {
  setPaymentSetting({ commit }, data) {
    commit("mutatePaymentSetting", data);
  },
  setPaymentMethod({ commit }, data) {
    commit("mutatePaymentMethod", data);
    //clear bank_gateway on payment method change
    commit("mutateBankGateway", null);
  },
  setBankGateway({ commit }, data) {
    commit("mutateBankGateway", data);
    // state.setting.forEach((item) => {
    //   if (item.gateways) {
    //     commit("mutatePaymentMethod", item.paymentMethod);
    //   }
    // });
  },

  clearPaymentStore({ commit }) {
    commit("mutateBankGateway", null);
    commit("mutatePaymentMethod", null);
  },

  setPaymentDisable({ commit }, payload) {
    commit("mutatePaymentDisable", payload);
  },
  updateBalonStatus({ commit }, newValue) {
    commit("mutateBalonStatus", newValue);
  },
  updateCreditStatus({ commit }, newValue) {
    commit("mutateCreditStatus", newValue);
  },

  setInstallmentsOrdersHasPriceDifferent({ commit }, payload) {
    commit("mutateInstallmentsHasPriceDifferent", payload);
  },

  setOrderInvoice({ commit }, payload) {
    commit("mutateOrderInvoice", payload);
  },

  clearInvoicePdf({ commit }) {
    commit("mutateClearInvoice");
  },
  setBalonOtp({ commit }, payload) {
    commit("mutateBalonOtp", payload);
  },
  clearInstallmentSetting({ commit }) {
    commit("mutateClearInstallmentSetting");
  },

  setBaloanCreditStatus({ commit }, payload) {
    commit("mutateBaloanCreditStatus", payload);
  },

  setDefualtPaymentMethod({ commit }) {
    commit("mutateDefualtPaymentMethod");
  },

  setInstallmentsPrice({ commit }, payload) {
    commit("mutateInstallmentsPrice", payload);
  },
};

export default {
  namespaced: true,
  name: "order.payment_method",
  state,
  getters,
  actions,
  mutations,
};
