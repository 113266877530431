// initial state
import storageService from "@/services/LocalStorageService.service";

const state = () => ({
  userName: storageService.get("userName") || "",
  latestOrders: [],
  personalInfo: storageService.get("personal-info")
    ? storageService.get("personal-info")
    : {},
  phone:
    storageService.get("phone") || storageService.get("@auth_user:phone") || "",
  bankDetails: {},
  legals: [],
  changeToken: "",
});

// getters
export const getters = {
  userName: (state) => state.userName,
  latestOrders: (state) => state.latestOrders,
  personalInfo: (state) => state.personalInfo,
  phone: (state) => state.phone,
  bankDetails: (state) => state.bankDetails,
  legals: (state) => state.legals,
  changeToken: (state) => state.changeToken,
};

// mutations
export const mutations = {
  mutateLatestOrders(state, payload) {
    state.latestOrders = payload;
  },
  mutatePersonalInfo(state, payload) {
    state.personalInfo = {
      name: {
        title: "نام",
        value: payload?.name || "",
        show: false,
        key: "name",
      },
      lastName: {
        title: "نام خانوادگی ",
        value: payload?.lastName || "",

        show: false,
        key: "lastName",
      },
      fullName: {
        title: "نام و نام خانوادگی",
        value:
          (payload?.name || "") +
          (payload?.lastName ? " " : "") +
          (payload?.lastName || ""),
        show: true,
        key: "fullName",
      },
      nationalCode: {
        title: "کد ملی",
        value: payload?.nationalCode || null,
        show: true,
        key: "nationalCode",
      },
      birthday: {
        title: "تاریخ تولد",
        value: payload?.birthday
          ? payload?.birthday.replaceAll("-", "/")
          : null,
        show: true,
        key: "birthday",
      },
      email: {
        title: "ایمیل",
        value: payload?.email || null,
        show: true,
        key: "email",
      },
    };
    state.userName = state.personalInfo.fullName.value || "";
    storageService.set("userName", state.personalInfo.fullName.value);
    storageService.set("personal-info", state.personalInfo);
  },
  mutatePhoneUser(state, payload) {
    state.phone = payload || storageService.get("@auth_user:phone") || "";
    storageService.set("phone", state.phone);
  },
  mutateBankDetails(state, payload) {
    state.bankDetails = {
      accountOwner: {
        title: "صاحب حساب",
        value: payload?.accountOwner || null,
      },
      cartNumber: {
        title: "شماره کارت",
        value: payload?.cartNumber || null,
      },
      shebaNumber: {
        title: "شماره شبا",
        value: payload?.shebaNumber || null,
      },
    };
  },
  mutateLegal(state, payload) {
    if (payload.length) {
      state.legals.length = 0;
      payload.forEach((legal) => {
        state.legals.push({
          legal: {
            companyName: {
              title: "نام شرکت",
              value: legal.companyName || null,
            },
            nationalId: { title: "شناسه ملی", value: legal.nationalId || null },
            registerNumber: {
              title: "شماره ثبت",
              value: legal.registerNumber || null,
            },
          },
          id: legal.id,
        });
      });
    }
  },
  mutateChangeToken(state, payload) {
    state.changeToken = payload;
  },
};

// actions
export const actions = {
  setLatestOrders({ commit }, payload) {
    commit("mutateLatestOrders", payload);
  },

  setPersonalInfo({ commit }, payload) {
    commit("mutatePersonalInfo", payload.userDetail);
    commit("mutatePhoneUser", payload.phone);
    commit("mutateBankDetails", payload.bankDetails);
    commit("mutateLegal", payload.legal);
  },

  setChangeToken({ commit }, payload) {
    commit("mutateChangeToken", payload);
  },
};

export default {
  namespaced: true,
  name: "account_information",
  state,
  getters,
  actions,
  mutations,
};
