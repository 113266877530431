<template>
  <v-container fluid class="px-4 px-lg-16 pt-1 mt-md-n10 white">
    <v-card elevation="0" class="pa-3 border-primary rounded-lg">
      <div class="d-flex justify-space-between align-center px-2 py-1">
        <div class="primary--text text-h6 font-weight-bold mx-1">
          <v-icon color="primary">mdi-percent</v-icon>
          پیشنهاد شگفت‌انگیز
          <v-icon color="primary">mdi-percent</v-icon>
        </div>

        <div>
          <ImageChanger
            v-if="data.amazingShopGetDto.length > 1"
            @next="selectNextItem"
            @prev="selectPrevItem"
          />
        </div>
      </div>
      <v-divider class="my-3 primary" />
      <AmazingProduct
        v-touch="{
          left: () => swipe('left'),
          right: () => swipe('right'),
        }"
        @click.native="goto"
        :item="data.amazingShopGetDto[currentItem - 1]"
      />
      <v-divider class="my-3 primary" />
      <div class="d-flex justify-space-between align-center mt-3">
        <div>
          <div class="font-weight-medium">
            {{
              data.amazingShopGetDto[currentItem - 1].remainCount
                ? "عجله کن"
                : "دیر رسیدی"
            }}
          </div>
          <div
            v-if="data.amazingShopGetDto[currentItem - 1].remainCount"
            class="more--text text-body-2 font-weight-medium"
          >
            پایان پیشنهاد
          </div>
        </div>
        <AmazingTimer
          @finish-timer="$emit('finish-timer')"
          :time="data.remain"
        />
      </div>
    </v-card>
  </v-container>
</template>

<script>
import AmazingProduct from "@/packages/home/components/amazing/ProductMobile";
import AmazingTimer from "@/packages/home/components/amazing/Timer";
import ImageChanger from "@/app/components/Changer";
export default {
  name: "AmazingMobile",
  components: { ImageChanger, AmazingTimer, AmazingProduct },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentItem: 1,
      slidHandler: null,
    };
  },
  methods: {
    selectNextItem() {
      const len = this.data.amazingShopGetDto.length;
      const _next = this.currentItem + 1;
      this.currentItem = _next > len ? 1 : _next;
      clearInterval(this.slidHandler);
    },
    selectPrevItem() {
      const len = this.data.amazingShopGetDto.length;
      const _prev = this.currentItem - 1;
      this.currentItem = _prev < 1 ? len : _prev;
      clearInterval(this.slidHandler);
    },
    goto() {
      this.$router.push({
        name: "product.detail",
        params: {
          slug: this.data.amazingShopGetDto[this.currentItem - 1].slug,
        },
      });
    },
    swipe(direction) {
      if (direction === "left") {
        this.selectPrevItem();
      }
      if (direction === "right") {
        this.selectNextItem();
      }
    },
  },
  mounted() {
    this.slidHandler = setInterval(() => {
      const len = this.data.amazingShopGetDto.length;
      const _next = this.currentItem + 1;
      this.currentItem = _next > len ? 1 : _next;
    }, 3000);
  },
  beforeDestroy() {
    if (this.slidHandler) clearInterval(this.slidHandler);
  },
};
</script>

<style scoped>
.border-primary {
  border: 1px solid var(--v-primary-base);
}
</style>
