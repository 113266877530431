// initial state

const state = () => ({
  step: 1,
});

// getters
export const getters = {
  step: (state) => state.step,
};

// mutations
export const mutations = {
  setStep(state, value) {
    if (value > 3) return;
    state.step = value;
  },
};

// actions
export const actions = {
  nextStep({ commit, state }) {
    commit("setStep", state.step + 1);
  },
  resetWizard({ commit }) {
    commit("setStep", 1);
  },
};

export default {
  namespaced: true,
  name: "order.wizard",
  state,
  getters,
  actions,
  mutations,
};
