// initial state

const state = () => ({
  comment: "",
});

// getters
export const getters = {
  comment: (state) => state.comment,
};

// mutations
export const mutations = {
  mutateComment(state, value) {
    state.comment = value;
  },
};

// actions
export const actions = {
  setComment({ commit }, data) {
    commit("mutateComment", data);
  },
};

export default {
  namespaced: true,
  name: "order.comment",
  state,
  getters,
  actions,
  mutations,
};
