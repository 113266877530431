import api from "@/services/ApiService.service";
import urls from "@/helpers/api_route_manager.helper";

/**
 *  get mega menu
 *  ------------------------
 *  Api address| /users/megaMenu
 *  ------------------------
 *  Method| GET
 *  ------------------------
 *  Description| get mega menu
 *  ------------------------
 *  @param
 *  ------------------------
 *  @return  type {Promise}
 *  ------------------------
 **/
export const getSystemBarAxiosRequest = function () {
  return api.get(urls.api("systemBar"));
};
