<template>
  <div class="ss02 d-flex flex-column fill-height">
    <div class="text-left">
      <v-btn small color="primary" class="rounded-sm text-body-2">
        <v-fade-transition mode="out-in">
          <span :key="percent">{{ percent }}%</span>
        </v-fade-transition>
      </v-btn>
    </div>
    <v-img
      class="mt-1"
      :src="image.url"
      contain
      style="flex: unset"
      @error="catchImageLoadingError"
      mode="in-out"
    >
      <template v-slot:placeholder>
        <v-sheet height="100%" color="white">
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="primary"
              aria-label="progress"
            />
          </v-row>
        </v-sheet>
      </template>
    </v-img>
    <v-fade-transition mode="out-in">
      <div
        class="d-flex justify-space-between align-center mt-2"
        :key="item.slug"
      >
        <div class="d-flex flex-wrap">
          <div
            v-for="(color, index) in colors"
            :key="`${color}-${index}`"
            class="rounded-circle mt-1 ml-1"
            style="border: 1px solid var(--v-secondary-lighten5)"
            :style="{
              backgroundColor: color,
              width: isMobile ? '18px' : '10px',
              height: isMobile ? '18px' : '10px',
            }"
          />
        </div>
        <div class="d-flex" v-if="item.satisfactionRate.count">
          <v-rating
            dir="ltr"
            class="amazing-rating"
            color="warning"
            length="5"
            dense
            readonly
            half-increments
            :value="item.satisfactionRate.score"
            :size="isMobile ? 30 : 22"
          />
        </div>
      </div>
    </v-fade-transition>
    <v-fade-transition mode="out-in">
      <div
        :key="item.slug"
        class="d-flex justify-space-between align-center mt-3"
      >
        <div
          v-if="isMobile"
          class="info--text font-weight-medium text-body-2 text-decoration-line-through mr-auto"
        >
          {{ formatPrice(item.basePrice / 10) }}
        </div>
      </div>
    </v-fade-transition>
    <v-fade-transition mode="out-in">
      <div :key="item.slug" class="d-flex justify-space-between mt-2">
        <div class="font-weight-bold text-body-1">
          {{ item.title }}
        </div>
        <div v-if="isMobile" class="primary--text font-weight-bold">
          {{ formatPrice(item.salePrice / 10) }}
        </div>
      </div>
    </v-fade-transition>
    <div
      class="d-flex flex-column"
      style="place-content: flex-end; flex: 1 0 auto"
    >
      <v-fade-transition mode="out-in">
        <div
          :key="item.slug"
          class="d-flex justify-space-between align-center mt-2"
        >
          <div class="more--text font-weight-medium">
            <v-badge
              inline
              :content="item.remainCount ? item.remainCount : 'اتمام موجودی'"
            >
              تعداد باقیمانده:
            </v-badge>
          </div>
        </div>
      </v-fade-transition>

      <div
        v-if="!isMobile"
        class="more--text font-weight-medium text-body-1 pt-2"
      >
        {{ item.remainCount ? "عجله کن" : "دیر رسیدی" }}
      </div>
      <div v-if="!isMobile" class="d-flex justify-space-between align-end mt-3">
        <div
          class="info--text font-weight-medium text-body-2 text-decoration-line-through"
        >
          {{ formatPrice(item.basePrice / 10) }}
        </div>
        <div class="primary--text font-weight-bold">
          {{ formatPrice(item.salePrice / 10) }} تومان
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imageUrlBuilder from "@/app/mixins/mixin.image_url_builder";

export default {
  name: "AmazingProduct",
  mixins: [imageUrlBuilder],
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      imageHasError: false,
    };
  },
  computed: {
    colors() {
      return [
        ...new Set(
          this.item.colors
            ? this.item.colors.map((v) => {
                let color = v;
                if (v.length === 3) {
                  color = `${v[0]}${v[0]}${v[1]}${v[1]}${v[2]}${v[2]}`;
                }
                return color.toLowerCase();
              })
            : []
        ),
      ];
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    percent() {
      return Math.ceil(
        ((this.item.basePrice - this.item.salePrice) / this.item.basePrice) *
          100
      ).toFixed(0);
    },
    image() {
      const { image } = this.item;
      return {
        url:
          image && !this.imageHasError
            ? this.imageUrlBuilder(image.url)
            : require("@/app/assets/images/product-default-image.png"),
        alt: image && image.alt ? image.alt : this.item.title,
      };
    },
  },
  methods: {
    catchImageLoadingError() {
      this.imageHasError = true;
    },
    formatPrice(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<style scoped>
.amazing-rating > button {
  transform: unset !important;
}
</style>
