export default {
  /*product Routes********************/
  product_title: "shop/product-detail/%s",
  product_detail_by_lp_code: "shop/product-detail/lp/%s",
  product_add_to_cart: "order/card/add-item/%s",
  product_relations: "shop/product-detail/relations/%s",
  comments_product_id: "product_comment/by_product/%s",
  comments: "product_comment",
  comments_like: "product_comment/like/%s",
  comments_dislike: "product_comment/dislike/%s",
  comments_reply_like: "product_comment/like_reply/%s",
  comments_reply_dislike: "product_comment/dislike_reply/%s",
  fetch_all_average_feedbacks: "productFeedback/getFeedbackStat/%s",
  fetch_product_detail: "productFeedback/getFeedbackStat",
  fetch_product_feedback_item: "productfeedback/getAll",
  notify_me: "product/notify/%s",
  delete_cart_item_count: "order/card/delete-item/%s",
  get_products: "product/compare",
};
