<template>
  <v-card class="px-3 py-1 rounded-pill d-inline-block" color="background">
    <v-btn @click="$emit('prev')" color="secondary" :small="isMobile" icon>
      <v-icon :size="isMobile ? 24 : 32">mdi-arrow-right-circle-outline</v-icon>
    </v-btn>
    <v-btn
      @click="$emit('next')"
      color="secondary"
      :small="isMobile"
      icon
      class="mr-1 mr-md-3"
    >
      <v-icon :size="isMobile ? 24 : 32">mdi-arrow-left-circle</v-icon>
    </v-btn>
  </v-card>
</template>

<script>
export default {
  name: "ImageChanger",
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>

<style scoped></style>
