<template>
  <div>
    <!--    <v-lazy-->
    <!--      v-model="topSliderIsVisible"-->
    <!--      :options="options"-->
    <!--      :min-height="topSliderIsVisible ? '100%' : '800px'"-->
    <!--    >-->
    <TopSlider />
    <!--    </v-lazy>-->
    <!--    <v-lazy-->
    <!--      v-model="amazingIsVisible"-->
    <!--      :options="options"-->
    <!--      transition="fade-transition"-->
    <!--      :min-height="amazingIsVisible ? '100%' : '800px'"-->
    <!--    >-->
    <Amazing />
    <!--    </v-lazy>-->
    <v-lazy
      v-model="specialIsVisible"
      :options="options"
      transition="fade-transition"
      :min-height="specialIsVisible ? '100%' : '800px'"
    >
      <Special />
    </v-lazy>
    <v-lazy
      v-model="categoryIsVisible"
      :options="options"
      transition="fade-transition"
      :min-height="categoryIsVisible ? '100%' : '800px'"
    >
      <Category />
    </v-lazy>
    <v-lazy
      v-model="serviceListIsVisible"
      :options="options"
      transition="fade-transition"
      :min-height="serviceListIsVisible ? '100%' : '800px'"
    >
      <ServiceList />
    </v-lazy>
    <v-lazy
      v-model="introductionIsVisible"
      :options="options"
      transition="fade-transition"
      :min-height="introductionIsVisible ? '100%' : '800px'"
    >
      <Introduction />
    </v-lazy>
  </div>
</template>

<script>
import TopSlider from "@/packages/home/components/top-slider/Index";
import Amazing from "@/packages/home/components/amazing/Index";
import Special from "@/packages/home/components/special/Index";
import Category from "@/packages/home/components/category/Index";
import ServiceList from "@/packages/home/components/service-list/Index";
import Introduction from "@/packages/home/components/Introduction";
export default {
  name: "Home",
  components: {
    TopSlider,
    Amazing,
    Special,
    Category,
    ServiceList,
    Introduction,
  },
  data() {
    return {
      topSliderIsVisible: false,
      amazingIsVisible: false,
      specialIsVisible: false,
      categoryIsVisible: false,
      serviceListIsVisible: false,
      introductionIsVisible: false,
      options: {
        threshold: 0,
      },
    };
  },
};
</script>

<style scoped></style>
