"use strict";

export default [
  {
    path: "/order",
    name: "order",
    redirect: { name: "order.cart" },
    component: () =>
      import(
        /* webpackChunkName: "order-pkg" */ "@/packages/order/presentation/views/Index"
      ),
    children: [
      {
        path: "cart",
        name: "order.cart",
        // redirect: { name: "cart" },
        component: () =>
          import(
            /* webpackChunkName: "order-pkg" */ "@/packages/order/presentation/views/OrderStepper"
          ),
      },
    ],
  },
  {
    path: "/order/cart/payment/in-place/:order_id",
    name: "order.cart.payment.in-place",
    component: () =>
      import(
        /* webpackChunkName: "order-pkg" */ "@/packages/order/presentation/views/PaymentResultInPlace"
      ),
  },
  {
    path: "/order/cart/payment/online",
    name: "order.cart.payment.online",
    component: () =>
      import(
        /* webpackChunkName: "order-pkg" */ "@/packages/order/presentation/views/PaymentResultOnline"
      ),
  },
  {
    path: "/order/cart/payment/online/pre",
    name: "order.cart.payment.online.pre",
    component: () =>
      import(
        /* webpackChunkName: "order-pkg" */ "@/packages/order/presentation/views/PrePayment"
      ),
  },
  {
    path: "/order/cart/payment/installment/:order-id",
    name: "order.cart.payment.installment",
    component: () =>
      import(
        /* webpackChunkName: "order-pkg" */ "@/packages/order/presentation/views/PaymentResultInstallment"
      ),
  },
];
