<template>
  <div class="white pb-8">
    <v-container fluid class="px-4 px-lg-16">
      <div
        class="text-center secondary--text font-weight-bold text-h4 d-flex justify-center align-center"
      >
        <div class="special-title-border special-title-border-right"></div>
        <div class="mx-8">پیشنهاد ویژه لیپک</div>
        <div class="special-title-border special-title-border-left"></div>
      </div>
      <v-row class="mt-12">
        <template v-for="(item, index) in data">
          <v-col cols="2" :key="item.slug">
            <v-hover v-slot="{ hover }">
              <v-card
                class="transition-swing cursor-pointer special-products-card pa-2"
                :class="[`elevation-${hover ? 12 : 0}`, hover && 'is-hovered']"
                link
                :to="{
                  name: 'product.detail',
                  params: { slug: item.slug },
                }"
              >
                <SpecialProduct :item="item" :hover="hover" />
              </v-card>
            </v-hover>
          </v-col>
          <v-divider
            v-if="index % 6 !== 5 && index !== data.length - 1"
            vertical
            class="my-2"
            :key="`divider-${item.slug}`"
          />
        </template>
      </v-row>
      <div class="text-left mt-10">
        <v-btn
          outlined
          color="primary"
          :to="{
            name: 'products.specials',
          }"
          class="error lighten-5"
          style="border-color: var(--v-primary-base) !important"
        >
          مشاهده همه <v-icon class="mr-3">mdi-arrow-left</v-icon>
        </v-btn>
        <!--        -->
        <!--        <v-btn-->
        <!--          outlined-->
        <!--          color="primary"-->
        <!--          :to="{-->
        <!--            name: 'products.search',-->
        <!--            query: { specials: ['special', 'available'] },-->
        <!--          }"-->
        <!--          class="error lighten-5"-->
        <!--          style="border-color: var(&#45;&#45;v-primary-base) !important"-->
        <!--        >-->
        <!--          مشاهده همه <v-icon class="mr-3">mdi-arrow-left</v-icon>-->
        <!--        </v-btn>-->
      </div>
    </v-container>
  </div>
</template>

<script>
import SpecialProduct from "@/packages/home/components/special/Product";

export default {
  components: { SpecialProduct },
  props: {
    data: { type: Array, required: true },
  },
};
</script>

<style scoped lang="scss">
.special-title-border {
  width: 46px;
  border: 2px solid var(--v-new_blue-base);
  &-right {
    border-top-left-radius: 10px;
  }
  &-left {
    border-top-right-radius: 10px;
  }
}
.special-products-card {
  z-index: 1;
  &.is-hovered {
    transform: scale(1.15);
    z-index: 2;
  }
}
</style>
