import store from "@/app/store/index";
import { getSystemBarAxiosRequest } from "@/app/config/repository/api/fetchSystemBar.api";

/**
 * get mega menu and save it in store
 * -----------------------
 * @param
 * -----------------------
 * Description
 * -----------------------
 * @return type {Promise}
 * */
export const getSystemBar = async function () {
  try {
    const { data } = await getSystemBarAxiosRequest();
    await store.dispatch("systemBar/saveItems", data);
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
};
