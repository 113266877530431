<template>
  <div class="user-select-none">
    <div class="d-flex align-center justify-space-between mb-3">
      <div class="secondary--text font-weight-bold text-h6 text-md-h5">
        <span v-if="!isMobile">دسته‌بندی</span>
        <span v-else-if="filteredCategories[selectedCatIndex]">
          <v-fade-transition mode="out-in">
            <span :key="selectedCatIndex">
              {{ filteredCategories[selectedCatIndex].title }}
            </span>
          </v-fade-transition>
        </span>
      </div>
      <div>
        <div v-if="!isMobile" class="d-flex">
          <div
            v-for="(category, index) in filteredCategories"
            :key="category.id"
            :ref="`category`"
            class="py-3 px-6 font-weight-medium text-large cursor-pointer"
            :class="[
              index === selectedCatIndex ? 'secondary--text' : 'info--text',
            ]"
            style="transition: all 0.3s"
            @click="selectCat(category.id)"
          >
            {{ category.title }}
          </div>
        </div>
        <ImageChanger
          v-else-if="filteredCategories.length > 1"
          @next="nextCat"
          @prev="prevCat"
        />
      </div>
    </div>
    <div
      v-if="!isMobile"
      class="selection-border"
      :style="{ width: selectionBorderWidth }"
    ></div>
    <v-divider />
  </div>
</template>

<script>
import ImageChanger from "@/app/components/Changer";
export default {
  name: "CategorySelection",
  components: { ImageChanger },
  props: {
    categories: {
      type: Array,
      required: true,
    },
    next: {
      type: Number,
      default: () => 0,
    },
    prev: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      selectedCatIndex: -1,
      isMounted: false,
      slideHandler: null,
    };
  },
  computed: {
    filteredCategories() {
      return this.categories.filter((v) => v.products.length);
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    selectionBorderWidth() {
      if (!this.isMounted || !this.$refs.category) return "100%";
      let width = 0;
      for (
        let i = this.selectedCatIndex + 1;
        i < this.filteredCategories.length;
        i++
      ) {
        width += this.$refs.category[i].clientWidth;
      }
      return `calc(100% - ${width}px)`;
    },
  },
  watch: {
    selectedCatIndex(newIndex) {
      this.$emit("select-cat", this.filteredCategories[newIndex].id);
    },
    next() {
      this.nextCat();
    },
    prev() {
      this.prevCat();
    },
  },
  mounted() {
    this.selectedCatIndex = 0;
    this.isMounted = true;
    this.slideHandler = setInterval(() => {
      let _index = this.selectedCatIndex + 1;
      if (_index >= this.filteredCategories.length) {
        _index = 0;
      }
      this.selectedCatIndex = _index;
    }, 3000);
  },
  methods: {
    selectCat(id) {
      this.selectedCatIndex = this.filteredCategories.findIndex(
        (v) => v.id === id
      );
      clearInterval(this.slideHandler);
    },
    nextCat() {
      let _index = this.selectedCatIndex + 1;
      if (_index >= this.filteredCategories.length) {
        _index = 0;
      }
      this.selectedCatIndex = _index;
      clearInterval(this.slideHandler);
    },
    prevCat() {
      let _index = this.selectedCatIndex - 1;
      if (_index < 0) {
        _index = this.filteredCategories.length - 1;
      }
      this.selectedCatIndex = _index;
      clearInterval(this.slideHandler);
    },
  },
};
</script>

<style scoped>
.selection-border {
  background-color: var(--v-new_blue-base);
  border: 2px solid var(--v-new_blue-base);
  border-radius: 5px;
  transition: all 0.3s;
}
</style>
