export default {
  /*OTP Routes********************/
  ////test
  fetch_address: "user/address",
  delete_address: "user/address/%s",
  get_provinces: "contents/provinces",
  fetch_cities: "contents/provinces/%s",
  create_new_address: "user/address",
  update_address: "user/address/%s",
  fetch_order_modes: "user/orders",

  /////personal_info
  personal_info: "user/detail",
  update_user_personal_info: "user/detail/info",
  update_bank_account_details: "user/detail/bank",
  add_new_legal: "user/legal",
  update_user_legal_info: "user/legal/%s",
  send_otp: "user/detail/phone",
  user_phone_validate_otp: "user/detail/phone/validate",
  send_new_phone_number: "user/detail/phone/new",

  user_phone_validate_otp_with_change_token: "user/detail/phone/new/validate",

  ///track_orders
  fetch_states: "order/customer/states",
  fetch_latest_order: "order/customer/latest",
  fetch_order_by_type_order: "order/customer/by_state",
  fetch_order_detail_by_id: "order/customer/orderdetails/%s",
  download_factor: "order/customer/saleInvoice/%s",
  update_state: "order/customer/cancel/%s",
  fetch_return_reason: "order/customer/return/reason",
  submit_returned: "order/customer/return",
  fetch_return_detail_by_step: "order/customer/return/%s",
  payment_online_request: "payment/requestOnlinePayment",
  fetch_order_item_by_id_for_return: "order/customer/return/init/%s",
  upload_image_for_returned: "media/image/uploadReturnItemImages/%s",
  cancel_returned_order: "order/customer/return/%s",
  approve_image_save: "order/customer/return/%s",
  fetch_payment_detail: "orderSetting/getPaymentGatewayStatus",
  fetch_bank_receipt_info: "payment/bankDetailList",
  change_payment_method_to_receipt: "order/customer/payment-method-switch",
  check_validate_online_payment: "order/customer/allowedToBePaidOnline/%s",
  validate_otp_installments_payment_in_order_detail:
    "payment/installment/baloanOtpResponse",
  request_to_send_otp_for_installments_payment:
    "payment/installment/requestOrderBaloanOtp/%s",
  request_to_resend_otp_for_installments_payment:
    "payment/installment/requestOrderBaloanOtp/%s",

  ///comment
  fetch_comments_user: "product_comment/user",
  delete_comment_user: "product_comment/user/%s",
  fetch_products_waiting_for_comment: "product_comment/user/products_bought",
  fetch_feedback_item: "productfeedback/getAll",

  //password
  send_otp_for_change_pass: "user/detail/change_password/customer/otp",
  resend_otp_for_change_pass: "user/detail/change_password/customer/otp/resend",
  set_new_password: "user/detail/change_password/customer",
  user_phone_validate_otp_for_change_password:
    "user/detail/change_password/customer/otp/validate",
};
