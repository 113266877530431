// initial state
const state = () => ({
  data: {},
});

// getters
export const getters = {
  data: (state) => state.data,
  isActive: (state) => !!state.data.isActive,
};

// mutations
export const mutations = {
  setItems(state, value) {
    state.data = value;
  },
};

// actions
export const actions = {
  saveItems({ commit }, data) {
    commit("setItems", data);
  },
};

export default {
  namespaced: true,
  name: "systemBar",
  state,
  getters,
  actions,
  mutations,
};
