import api from "@/services/ApiService.service";
import urls from "@/helpers/api_route_manager.helper";
import Qs from "qs";

export const getDayOfferProductsAxiosRequest = function (query_params = null) {
  return api.get(urls.api("day_offer"), {
    params: {
      ...query_params,
    },
  });
};

export const getCategoryProductsAxiosRequest = function (query_params = null) {
  return api.get(urls.api("main_page_products"), {
    params: {
      ...query_params,
    },
    paramsSerializer: (params) =>
      Qs.stringify(params, { arrayFormat: "repeat" }),
  });
};

export const getCategoryProductsV2AxiosRequest = function (
  query_params = null
) {
  return api.get(urls.api("main_page_products_v2", [], "v2/"), {
    params: {
      ...query_params,
    },
    paramsSerializer: (params) =>
      Qs.stringify(params, { arrayFormat: "repeat" }),
  });
};

export const getSpecialProductsAxiosRequest = function (query_params = null) {
  return api.get(urls.api("main_page_special"), {
    params: {
      ...query_params,
    },
    paramsSerializer: (params) =>
      Qs.stringify(params, { arrayFormat: "repeat" }),
  });
};

export const getMainPageCategoryAxiosRequest = function (query_params = null) {
  return api.get(urls.api("main_page_category"), {
    params: {
      ...query_params,
    },
  });
};

export const getSliderItemsAxiosRequest = function (query_params = null) {
  return api.get(urls.api("sliders"), {
    params: {
      bannerType: "SLIDER",
      ...query_params,
    },
  });
};
