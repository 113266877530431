import api from "@/services/ApiService.service";
import urls from "@/helpers/api_route_manager.helper";

/**
 *  delete cart item count by id
 *  ------------------------
 *  Api address| /order/card/delete-item//:id
 *  ------------------------
 *  Method| Put
 *  ------------------------
 *  Description| delete cart item count by id
 *  ------------------------
 *  @param cart_id {String}
 *  @param query_params {Object}
 *  ------------------------
 *  @return  type {Promise}
 *  ------------------------
 **/
export const deleteCartItemCountAxiosRequest = function (
  cart_id,
  query_params = null
) {
  return api.delete(urls.api("delete_cart_item_count", [`${cart_id}`]), {
    params: {
      ...query_params,
    },
  });
};
