export default {
  black: "#000", // black
  white: "#fff", // white
  primary: "#F4561B", // Orange actions, buttons and links
  chip: "#fde7e0",
  secondary: "#2C2C54", // Title texts, captions, input fields and everywhere else where black is required
  accent: "#8C8B9B", // Secondary text / Default texts in text field
  more: "#6F727A", // Secondary text / Default texts in text field
  info: "#a7a7a7", // teal lighten-1
  warning: "#F6A609", // Warning
  error: "#FB4E4E", // Error/dislike icon/text
  success: "#2AC769", // Success
  background: "#F2F2F2",
  card: "#CCE1F2",
  ////// custom color
  light_primary: "#fff0ed",
  light_error: "#ffe5e5",
  light_success: "#dbf3e6",
  blue: "#42C0F5",
  new_blue: "#059BF2",
  new_light_blue: "#DFF3FE",
  navy_blue: "#232362",
  light_pink: "#ffa788",
  default_navy_blue: "#36405a",
};
