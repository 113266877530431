<template>
  <v-container v-if="showCategories" fluid class="px-4 px-lg-16 white">
    <v-skeleton-loader v-if="isLoading" type="image@2" />
    <v-card v-else outlined class="pa-3">
      <CategorySelection
        :next="nextCat"
        :prev="prevCat"
        :categories="categories"
        @select-cat="selectCat"
      />
      <v-fade-transition mode="out-in">
        <v-row
          class="mt-3"
          :key="selectedCatId"
          v-touch="{
            left: () => swipe('left'),
            right: () => swipe('right'),
          }"
        >
          <v-col
            cols="12"
            sm="6"
            lg="3"
            v-for="product in productsToShow"
            :key="product.id"
          >
            <CategoryProduct :item="product" />
          </v-col>
        </v-row>
      </v-fade-transition>
      <div class="text-left">
        <v-btn
          outlined
          :block="isMobile"
          color="primary"
          :to="{
            name: 'products.category',
            params: {
              cat_title: selectedCatId,
            },
            query: { specials: ['available'] },
          }"
          class="error lighten-5 mt-5"
          style="border-color: var(--v-primary-base) !important"
        >
          مشاهده همه <v-icon class="mr-3">mdi-arrow-left</v-icon>
        </v-btn>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import {
  getMainPageCategoryAxiosRequest,
  getCategoryProductsV2AxiosRequest,
} from "@/packages/home/api/main.api";
import CategorySelection from "@/packages/home/components/category/CategorySelection";
import CategoryProduct from "@/packages/home/components/category/CategoryProduct";

export default {
  name: "Category",
  components: { CategoryProduct, CategorySelection },
  data() {
    return {
      isLoading: true,
      hasError: false,
      categories: null,
      selectedCatId: "",
      nextCat: 0,
      prevCat: 0,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    isTablet() {
      return this.$vuetify.breakpoint.mobile;
    },
    showCategories() {
      if (this.hasError || (this.categories && !this.categories.length)) {
        return false;
      }
      return true;
    },
    productsToShow() {
      if (!this.selectedCatId) return;
      return this.categories
        .find((v) => v.id === this.selectedCatId)
        .products.slice(0, this.isMobile ? 3 : this.isTablet ? 6 : 8);
    },
  },
  mounted() {
    this.fetchProductGroups();
  },
  methods: {
    async fetchProductGroups() {
      this.isLoading = true;
      this.hasError = false;
      try {
        const { data } = await getMainPageCategoryAxiosRequest();
        this.categories = data.map((v) => ({ id: v.slug, title: v.title }));
        const products = await this.getAllProducts();
        this.categories = this.categories.map((v, index) => ({
          ...v,
          products: products[index],
        }));
      } catch (err) {
        console.log(err);
        this.hasError = true;
      }
      this.isLoading = false;
    },
    async fetchProductsByProductGroup(catId) {
      try {
        const {
          data: {
            productPage: { content },
          },
        } = await getCategoryProductsV2AxiosRequest({
          pageNumber: 0,
          pageSize: 9,
          catId: catId,
          specials: ["available"],
        });
        return Promise.resolve(content);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async getAllProducts() {
      const promises = [];
      for (const { id } of this.categories) {
        promises.push(this.fetchProductsByProductGroup(id));
      }
      return await Promise.all(promises);
    },
    selectCat(catId) {
      this.selectedCatId = catId;
    },
    swipe(direction) {
      if (direction === "left") {
        this.prevCat++;
      }
      if (direction === "right") {
        this.nextCat++;
      }
    },
  },
};
</script>

<style scoped></style>
