// initial state
import auth from "@/services/AuthService.service";
// console.log(auth, "auth");

const state = () => ({
  init_token: auth ? auth.getInitToken() : null,
  otp_token: auth ? auth.getOtpToken() : null,
  token: auth ? auth.getToken() : null,
  phone: auth ? auth.getPhone() : null,
  is_registered: auth ? auth.getIsRegistered() : 0, //0 -> registered, 1 -> not registered
  refresh_token: auth ? auth.getRefreshToken() : null,
  expiration: auth ? auth.getExpiration() : null,
});

// getters
export const getters = {
  check: (state) => !!state.token,
  token: (state) => state.token,
  init_token: (state) => state.init_token,
  otp_token: (state) => state.otp_token,
  refresh_token: (state) => state.refresh_token,
  expiration: (state) => state.expiration,
  is_registered: (state) => state.is_registered,
  phone: (state) => state.phone,
};

// mutations
export const mutations = {
  setInitToken(state, value) {
    state.init_token = value;
    auth.saveInitToken(value);
  },

  setOtpToken(state, value) {
    state.otp_token = value;
    auth.saveOtpToken(value);
  },

  setToken(state, value) {
    state.token = value;
    auth.saveToken(value);
  },
  setRefreshToken(state, value) {
    state.refresh_token = value;
    auth.saveRefreshToken(value);
  },

  setPhone(state, value) {
    state.phone = value;
    auth.savePhone(value);
  },

  setIsRegistered(state, value) {
    state.is_registered = value;
    auth.saveIsRegistered(value);
  },

  setExpiration(state, value) {
    state.expiration = value;
    auth.saveExpiration(value);
  },

  clearOtpToken(state) {
    state.otp_token = null;
    auth.clearOtpToken();
  },

  clearInitToken(state) {
    state.init_token = null;
    auth.clearInitToken();
  },

  clearAuth(state) {
    auth.removeToken();
    state.token = auth.getToken();
    state.refresh_token = auth.getRefreshToken();
    state.init_token = auth.getInitToken();
    state.otp_token = auth.getOtpToken();
    state.expiration = auth.getExpiration();
    state.check = auth.check();
    state.phone = auth.getPhone();
    state.is_registered = auth.getIsRegistered(); //0 -> registered, 1 -> not registered
  },
};

// actions
export const actions = {
  saveToken({ commit }, data) {
    commit("setToken", data);
  },
  saveInitToken({ commit }, data) {
    commit("setInitToken", data);
  },

  saveOtpToken({ commit }, data) {
    commit("setOtpToken", data);
  },

  saveRefreshToken({ commit }, data) {
    commit("setRefreshToken", data);
  },
  saveExpiration({ commit }, data) {
    commit("setExpiration", data);
  },

  savePhone({ commit }, data) {
    commit("setPhone", data);
  },

  saveIsRegistered({ commit }, data) {
    commit("setIsRegistered", data);
  },

  clear({ commit }) {
    commit("clearAuth");
  },

  clearOtp({ commit }) {
    commit("clearOtpToken");
  },

  clearInit({ commit }) {
    commit("clearInitToken");
  },

  logout({ commit }) {
    commit("clearAuth");
  },
};

export default {
  namespaced: true,
  name: "authentication",
  state,
  getters,
  actions,
  mutations,
};
