import api from "@/services/ApiService.service";
import urls from "@/helpers/api_route_manager.helper";

/**
 *  get mega menu
 *  ------------------------
 *  Api address| /users/megaMenu
 *  ------------------------
 *  Method| GET
 *  ------------------------
 *  Description| get mega menu
 *  ------------------------
 *  @param
 *  ------------------------
 *  @return  type {Promise}
 *  ------------------------
 **/
export const getMegaMenuAxiosRequest = function () {
  return api.get(urls.api("mega_menu"));
};

export const getSearchResultAxiosRequest = function (queryParams) {
  return api.get(urls.api("mega_menu_search"), { params: { ...queryParams } });
};
