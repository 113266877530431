// initial state

const state = () => ({
  changePass: "",
});

// getters
export const getters = {
  changePass: (state) => state.changePass,
};

// mutations
export const mutations = {
  mutateChangePass(state, payload) {
    state.changePass = payload;
  },
};

// actions
export const actions = {
  setChangePass({ commit }, payload) {
    commit("mutateChangePass", payload);
  },
};

export default {
  namespaced: true,
  name: "change_password",
  state,
  getters,
  actions,
  mutations,
};
