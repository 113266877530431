<template>
  <div class="w-fit-content mx-auto">
    <div
      class="d-block d-md-none mb-3 text-center text-h6 font-weight-bold secondary--text"
    >
      {{ title }}
    </div>
    <v-card
      class="d-flex align-center justify-space-around py-3 py-md-6 px-16"
      elevation="0"
    >
      <div
        class="d-none d-md-block ml-16 text-h6 font-weight-bold secondary--text"
      >
        {{ title }}
      </div>
      <div class="d-flex">
        <v-img
          max-height="73"
          max-width="61"
          :src="
            require('@/app/assets/images/Image-statue-of-consumer-support.svg')
          "
        />
        <v-img
          class="cursor-pointer"
          max-height="73"
          max-width="61"
          :src="
            require('@/app/assets/images/National-registration-mark-of-digital-media-site.svg')
          "
          @click="gotoNationalRegistrationMark"
        />

        <a
          referrerpolicy="origin"
          target="_blank"
          href="https://trustseal.enamad.ir/?id=174315&amp;Code=x9kz9IOU2NBp24juNFLg"
          style="max-height: 73px !important; max-width: 61px !important"
          ><img
            height="73"
            width="61"
            referrerpolicy="origin"
            src="https://Trustseal.eNamad.ir/logo.aspx?id=174315&amp;Code=x9kz9IOU2NBp24juNFLg"
            aria-label="enamad"
            style="
              max-height: 73px !important;
              max-width: 61px !important;
              cursor: pointer;
            "
            id="x9kz9IOU2NBp24juNFLg"
        /></a>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Symbols",
  data() {
    return {
      title: "با اطمینان از لیپک خرید کنید",
    };
  },
  methods: {
    gotoNationalRegistrationMark() {
      window.open(
        " https://logo.samandehi.ir/Verify.aspx?id=196374&p=rfthpfvlgvkaxlaojyoeaods",
        "Popup",
        "toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30"
      );
    },
    goToENamad() {
      window.open(
        "https://trustseal.enamad.ir/?id=174315&amp;Code=x9kz9IOU2NBp24juNFLg",
        "window"
      );
    },
  },
};
</script>

<style scoped></style>
