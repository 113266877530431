"use strict";

Object.defineProperty(exports, "__esModule", { value: true });

// printInvoice();
function addStyles(win, styles) {
  styles.forEach((element) => {
    if (element.type === "css") {
      let link = win.document.createElement("link");
      link.setAttribute("rel", "stylesheet");
      link.setAttribute("type", "text/css");
      link.setAttribute("href", element.link);
      win.document.getElementsByTagName("head")[0].appendChild(link);
    } else {
      let link = win.document.createElement("script");
      link.setAttribute("src", element.link);
      win.document.getElementsByTagName("body")[0].appendChild(link);
    }
  });
}
function openWindow(url, name, props) {
  let windowRef = null;
  windowRef = window.open(url, name, props);
  if (!windowRef.opener) {
    windowRef.opener = self;
  }
  windowRef.focus();
  return windowRef;
}

const VueHtmlToPaper = {
  install(Vue, options = {}) {
    Vue.prototype.$htmlToPaper = (el, localOptions = () => true) => {
      let defaultName = "_blank",
        defaultSpecs = ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
        // defaultReplace = true,
        defaultStyles = [];
      let {
        name = defaultName,
        specs = defaultSpecs,
        // replace = defaultReplace,
        styles = defaultStyles,
      } = options;

      // If has localOptions
      // TODO: improve logic
      if (localOptions) {
        if (localOptions.name) name = localOptions.name;
        if (localOptions.specs) specs = localOptions.specs;
        // if (localOptions.replace) replace = localOptions.replace;
        if (localOptions.styles) styles = localOptions.styles;
      }

      specs = specs.length ? specs.join(",") : "";

      const element = window.document.getElementById(el);
      if (!element) {
        alert(`Element to print #${el} not found!`);
        return;
      }
      const IRANSansX_Light_woff =
        process.env.VUE_APP_IMAGE_BASE_URL +
        "/fonts/IRANSansX-Light.90df01fc.woff";
      const IRANSansX_Light_woff2 =
        process.env.VUE_APP_IMAGE_BASE_URL +
        "/fonts/IRANSansX-Light.c2dc65de.woff2";
      const url = "";
      const win = openWindow(url, name, specs);

      win.document.write(`
        <html lang="en">
          <head>
            <title>${window.document.title}</title>
              <style>
            @font-face {
            font-family: IRANSansX;
            font-style: normal;
            font-weight: 300;
            src: url("${IRANSansX_Light_woff}")
             format("woff"),
              url("${IRANSansX_Light_woff2}")
               format("woff2");
            font-display: swap;
          } 
           </style>
          </head>
          <body> 
          <div class="btn-print-box">
                 <v-btn class="btn-print" style="cursor: pointer!important;" onclick="printInvoice()" >پرینت / دانلود</v-btn>  
          </div> 
           
           <div class="print-content-wrapper">  ${element.innerHTML} </div> 
         <script>
           function printInvoice(){
               window.document.close();
                window.focus();
                window.print();
         }
         </script>
          </body>
        </html>
      `);

      addStyles(win, styles);
      // addScript(win, scripts);
      // setTimeout(() => {
      //   win.document.close();
      //   win.focus();
      //   win.print();
      //   // setTimeout(function () {window.close();}, 1);
      //   cb();
      // }, 1000);
      return true;
    };
  },
};

exports.default = VueHtmlToPaper;
