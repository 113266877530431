// initial state

const state = () => ({
  dialog: false,
  changeDialog: null,
});

// getters
export const getters = {
  dialog: (state) => state.dialog,
  changeDialog: (state) => state.changeDialog,
};

// mutations
export const mutations = {
  setDialog(state, value) {
    state.dialog = value;
  },
  mutatechangeDialog(state, value) {
    state.changeDialog = value;
  },
};

// actions
export const actions = {
  openDialog({ commit }) {
    commit("setDialog", true);
  },
  closeDialog({ commit }) {
    commit("setDialog", false);
  },
  changeDialog({ commit }, value) {
    commit("mutatechangeDialog", value);
  },
};

export default {
  namespaced: true,
  name: "dialog",
  state,
  getters,
  actions,
  mutations,
};
