// initial state

const state = () => ({
  shipping: {
    customerInfo: null,
    address: null,
    shipping_method: null,
    shipping_time: null,
    legal: null,
  },
  hasLegal: false,
  // addresses: [],
  cities: [],
  inputEnabledToQuarter: null,
});

// getters
export const getters = {
  shipping: (state) => state.shipping,
  customerInfo: (state) => state.shipping.customerInfo,
  address: (state) => state.shipping.address,
  shipping_method: (state) => state.shipping.shipping_method,
  shipping_time: (state) => state.shipping.shipping_time,
  legal: (state) => state.shipping.legal,
  isValidToContinue: (state) => {
    let message = "";
    let isValid = true;
    if (state.shipping.address && state.shipping.shipping_method) {
      if (state.shipping.shipping_method.shippingMethodCityId) {
        message = "لطفا زمان ارسال را انتخاب کنید.";
        isValid = !!state.shipping.shipping_time;
      }
    } else {
      message = "آدرس یا شیوه ارسال را انتخاب نکرده اید.";
      isValid = false;
    }
    return {
      isValid,
      message,
    };
  },
  isLegalValidToContinue: (state) => {
    let message = "";
    let isValid = true;
    if (state.hasLegal && !state.shipping.legal) {
      message =
        "لطفا اطلاعات کاربری حقوقی را انتخاب کنید و درصورت عدم وجود اطلاعات با زدن گزینه افزودن اطلاعات حقوقی آن را وارد و سپس انتخاب کنید.";
      isValid = false;
    }
    return {
      isValid,
      message,
    };
  },
  isCustomerInfoValidToContinue: (state) => {
    let message = "";
    let isValid = true;
    if (!state.shipping.customerInfo) {
      message = "لطفا اطلاعات ارسال کننده را تکمیل کنید.";
      isValid = false;

      return {
        isValid,
        message,
      };
    } else {
      if (state.shipping.customerInfo.nationalCode.length === 0) {
        message = "لطفا کد ملی خود را وارد کنید.";
        isValid = false;
      } else if (state.shipping.customerInfo.nationalCode.length === 10) {
        if (
          state.shipping.customerInfo.nationalCode == "0000000000" ||
          state.shipping.customerInfo.nationalCode == "1111111111" ||
          state.shipping.customerInfo.nationalCode == "2222222222" ||
          state.shipping.customerInfo.nationalCode == "3333333333" ||
          state.shipping.customerInfo.nationalCode == "4444444444" ||
          state.shipping.customerInfo.nationalCode == "5555555555" ||
          state.shipping.customerInfo.nationalCode == "6666666666" ||
          state.shipping.customerInfo.nationalCode == "7777777777" ||
          state.shipping.customerInfo.nationalCode == "8888888888" ||
          state.shipping.customerInfo.nationalCode == "9999999999"
        ) {
          message = "لطفا کد ملی خود را اصلاح کنید.";
          isValid = false;
        } else {
          let c = parseInt(state.shipping.customerInfo.nationalCode.charAt(9));
          let n =
            parseInt(state.shipping.customerInfo.nationalCode.charAt(0)) * 10 +
            parseInt(state.shipping.customerInfo.nationalCode.charAt(1)) * 9 +
            parseInt(state.shipping.customerInfo.nationalCode.charAt(2)) * 8 +
            parseInt(state.shipping.customerInfo.nationalCode.charAt(3)) * 7 +
            parseInt(state.shipping.customerInfo.nationalCode.charAt(4)) * 6 +
            parseInt(state.shipping.customerInfo.nationalCode.charAt(5)) * 5 +
            parseInt(state.shipping.customerInfo.nationalCode.charAt(6)) * 4 +
            parseInt(state.shipping.customerInfo.nationalCode.charAt(7)) * 3 +
            parseInt(state.shipping.customerInfo.nationalCode.charAt(8)) * 2;
          let r = n - parseInt(n / 11) * 11;
          if (
            (r == 0 && r == c) ||
            (r == 1 && c == 1) ||
            (r > 1 && c == 11 - r)
          ) {
            message = "لطفا ";
            if (state.shipping.customerInfo.firstName.length === 0) {
              isValid = false;
              message += "نام ";
            }
            if (state.shipping.customerInfo.lastName.length === 0) {
              isValid = false;
              message.length === 5
                ? (message += "نام خانوادگی ")
                : (message += "، نام خانوادگی ");
            }
            if (state.shipping.customerInfo.nationalCode.length === 0) {
              isValid = false;
              message.length === 5
                ? (message += "کدملی ")
                : (message += "، کدملی ");
            }
            message += " را وارد کنید.";
          } else {
            message = "لطفا کد ملی خود را اصلاح کنید.";
            isValid = false;
          }
        }
      } else {
        message = "لطفا کد ملی خود را اصلاح کنید.";
        isValid = false;
      }

      return {
        isValid,
        message,
      };
    }
  },
  // addresses: (state) => state.addresses,
  cities: (state) => state.cities,
  inputEnabledToQuarter: (state) => state.inputEnabledToQuarter,
};

// mutations
export const mutations = {
  mutateAddress(state, value) {
    state.shipping.address = value;
  },
  mutateShippingMethod(state, value) {
    state.shipping.shipping_method = value;
  },
  mutateShippingTime(state, value) {
    state.shipping.shipping_time = value;
  },
  mutateLegal(state, value) {
    state.shipping.legal = value;
  },
  mutateHasLegal(state, value) {
    state.hasLegal = value;
  },
  mutateCustomerInfo(state, value) {
    state.shipping.customerInfo = value;
  },
  // mutateAddresses(state, value) {
  //   state.addresses = value;
  // },

  mutateCities(state, value) {
    state.cities = value;
  },
  mutateInputEnabledToQuarter(state, value) {
    state.inputEnabledToQuarter = value;
  },
};

// actions
export const actions = {
  // setAddresses({ commit }, value) {
  //   commit("mutateAddresses", value);
  // },
  setCities({ commit }, data) {
    commit("mutateCities", data);
  },
  setInputEnabledToQuarter({ commit }, data) {
    commit("mutateInputEnabledToQuarter", data);
  },
};

export default {
  namespaced: true,
  name: "order.shipping",
  state,
  getters,
  actions,
  mutations,
};
