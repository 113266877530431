<template>
  <v-navigation-drawer v-model="drawer" fixed temporary width="100%" tag="nav">
    <v-container style="padding-top: 56px">
      <div
        class="d-flex align-center justify-space-between lipak-navigation-fixed-top elevation-2 px-2"
      >
        <v-btn
          fab
          color="transparent"
          elevation="0"
          @click="$emit('input', false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <router-link :to="{ name: 'home' }">
          <v-img height="26px" width="100%" :src="logoSrc" contain />
        </router-link>
      </div>
      <v-expansion-panels class="mt-3">
        <v-expansion-panel
          class="mt-2 rounded elevation-0 user-select-none"
          v-for="item in items"
          :key="item.productGroupId"
          style="background: transparent"
        >
          <v-expansion-panel-header
            color="white"
            hide-actions
            class="rounded py-5"
            style="min-height: auto"
          >
            <template v-slot:default="{ open }">
              <v-row class="justify-space-between align-center">
                <div class="font-weight-bold text-body-1">{{ item.title }}</div>
                <v-img
                  v-if="item.mobileImage"
                  class="mega-menu-header-image"
                  contain
                  :max-width="open ? 43 : 83"
                  :src="imageUrlBuilder(item.mobileImage)"
                  :alt="item.mobileImageAlt"
                  @click="goToCta(item.cta)"
                >
                  <template v-slot:placeholder>
                    <v-sheet height="100%" color="white">
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                          aria-label="progress"
                        />
                      </v-row>
                    </v-sheet>
                  </template>
                </v-img>
              </v-row>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content
            :color="hasExists(item.children) ? 'transparent' : 'white'"
            :class="{
              'rounded mt-2': !hasExists(item.children),
              'mega-menu-expandable-child-px-0': hasExists(item.children),
            }"
          >
            <template v-slot:default>
              <router-link
                :to="{
                  name: 'products.category',
                  params: { cat_title: item.slug },
                  // query: { catId: item.productGroupId },
                }"
                class="text-decoration-none"
              >
                مشاهده همه محصولات
              </router-link>
              <div>
                <div class="mt-4">
                  <div v-if="hasExists(item.brands)">
                    <div
                      class="mega-menu-item-option-title ps-3 my-5 font-weight-medium text-body-1"
                    >
                      {{
                        $_t("components.header.mega_menu.brand_title", {
                          title: item.title,
                        })
                      }}
                    </div>
                    <v-chip-group column>
                      <v-chip
                        v-for="brand in item.brands"
                        :key="brand.id"
                        :to="{
                          name: 'products.label-brand',
                          params: {
                            cat_title: item.slug,
                            title: brand.slug,
                          },
                          // query: {
                          //   catId: item.productGroupId,
                          //   brandId: brand.id,
                          // },
                        }"
                      >
                        {{ item.title }} {{ brand.title }}
                      </v-chip>
                    </v-chip-group>
                  </div>
                  <div
                    v-if="hasExists(item.labels)"
                    :class="{ 'mt-4': hasExists(item.brands) }"
                  >
                    <div
                      class="mega-menu-item-option-title ps-3 mb-5 font-weight-medium text-body-1"
                    >
                      {{
                        $_t("components.header.mega_menu.label_title", {
                          title: item.title,
                        })
                      }}
                    </div>
                    <v-chip-group column>
                      <v-chip
                        v-for="label in item.labels"
                        :key="label.id"
                        :to="{
                          name: 'products.label-brand',
                          params: { cat_title: item.slug, title: label.slug },
                          // query: {
                          //   catId: item.productGroupId,
                          //   labelId: label.id,
                          // },
                        }"
                      >
                        {{ label.title }}
                      </v-chip>
                    </v-chip-group>
                  </div>
                </div>
                <v-expansion-panels>
                  <v-expansion-panel
                    class="mt-2 rounded elevation-0 user-select-none"
                    v-for="child in item.children"
                    :key="child.productGroupId"
                    style="background: transparent"
                  >
                    <v-expansion-panel-header
                      color="white"
                      hide-actions
                      class="rounded py-5"
                      style="min-height: auto"
                    >
                      <template v-slot:default="{ open: childOpen }">
                        <v-row class="justify-space-between align-center">
                          <div class="font-weight-bold text-body-1">
                            {{ child.title }}
                          </div>
                          <v-fab-transition>
                            <v-icon
                              color="secondary"
                              :key="`${child.productGroupId}-${
                                childOpen ? 'minus' : 'plus'
                              }`"
                              >mdi-{{ childOpen ? "minus" : "plus" }}</v-icon
                            >
                          </v-fab-transition>
                        </v-row>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      color="white"
                      class="rounded mt-2"
                    >
                      <template v-slot:default>
                        <div class="mt-4">
                          <router-link
                            :to="{
                              name: 'products.category',
                              params: { cat_title: child.slug },
                              // query: { catId: child.productGroupId },
                            }"
                            class="text-decoration-none"
                          >
                            مشاهده همه محصولات
                          </router-link>
                          <div v-if="hasExists(child.brands)">
                            <div
                              class="mega-menu-item-option-title ps-3 mb-5 font-weight-medium text-body-1 mt-3"
                            >
                              {{
                                $_t("components.header.mega_menu.brand_title", {
                                  title: child.title,
                                })
                              }}
                            </div>
                            <v-chip-group column>
                              <v-chip
                                v-for="brand in child.brands"
                                :key="brand.id"
                                :to="{
                                  name: 'products.label-brand',
                                  params: {
                                    cat_title: child.slug,
                                    title: brand.slug,
                                  },
                                  // query: {
                                  //   catId: child.productGroupId,
                                  //   brandId: brand.id,
                                  // },
                                }"
                              >
                                {{ child.title }} {{ brand.title }}
                              </v-chip>
                            </v-chip-group>
                          </div>
                          <div
                            v-if="hasExists(child.labels)"
                            :class="{ 'mt-4': hasExists(child.brands) }"
                          >
                            <div
                              class="mega-menu-item-option-title ps-3 mb-5 font-weight-medium text-body-1"
                            >
                              {{
                                $_t("components.header.mega_menu.label_title", {
                                  title: child.title,
                                })
                              }}
                            </div>
                            <v-chip-group column>
                              <v-chip
                                v-for="label in child.labels"
                                :key="label.id"
                                :to="{
                                  name: 'products.label-brand',
                                  params: {
                                    cat_title: item.slug,
                                    title: label.slug,
                                  },
                                  // query: {
                                  //   catId: child.productGroupId,
                                  //   labelId: label.id,
                                  // },
                                }"
                              >
                                {{ label.title }}
                              </v-chip>
                            </v-chip-group>
                          </div>
                        </div>
                      </template>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-list-item>
        <!-- mag.lipak    -------------------------------------------------------------->
        <a
          href="https://mag.lipak.com/"
          target="_blank"
          class="py-2 px-2 ms-n2 secondary--text font-weight-bold text-decoration-none"
        >
          مجله لیپک
        </a>
        <!--End mag.lipak -------------------------------------------------------------->
      </v-list-item>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import logoSrc from "@/app/assets/images/logo/img.png";
import mixinImage_url_builder from "@/app/mixins/mixin.image_url_builder";
import { mapGetters } from "vuex";

export default {
  name: "Navigation",
  mixins: [mixinImage_url_builder],
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      group: null,
      drawer: false,
      logoSrc,
    };
  },
  computed: {
    ...mapGetters({ items: "megaMenu/items" }),
  },
  watch: {
    value(newValue) {
      this.drawer = newValue;
    },
    drawer(newValue) {
      this.$emit("input", newValue);
    },
  },
  methods: {
    goToCta(cta) {
      if (cta) {
        return this.$router.push(`${cta[0] === "/" ? "" : "/"}${cta}`);
      }
    },
    hasExists(item) {
      return item && item.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.mega-menu-header-image {
  transition: all 0.3s;
}
.v-expansion-panel::before,
.v-expansion-panel::after {
  display: none;
}
.mega-menu-item-option-title {
  border-right: 2px solid var(--v-primary-base);
}
.lipak-navigation-fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 99;
}
</style>

<!-- should not be scoped -->
<style>
.mega-menu-expandable-child-px-0 > .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
