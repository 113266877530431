var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ss02 user-select-none text-center card-product__image-container"},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[(!_vm.isDesktop && _vm.item.satisfactionRate.count)?_c('div',{staticClass:"d-flex text-caption"},[_c('v-rating',{staticClass:"text-left mb-1",attrs:{"dir":"ltr","empty-icon":"mdi-star-outline","full-icon":"mdi-star","half-icon":"mdi-star-half-full","hover":"","length":"1","size":"14","value":_vm.item.satisfactionRate.score,"color":"warning","readonly":"","dense":""}}),_vm._v(" "+_vm._s(_vm.item.satisfactionRate.score)+" ")],1):_vm._e(),_c('v-btn',{staticClass:"rounded-sm text-body-2 mr-auto",attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.percent)+"% ")])],1),(!_vm.isDesktop)?_c('div',{staticClass:"d-block product-color-viewer-container"},_vm._l((_vm.colors),function(color,index){return _c('div',{key:`${color}-${index}`,staticClass:"rounded-circle mt-1 ml-1",staticStyle:{"border":"1px solid var(--v-secondary-lighten5)"},style:({
        backgroundColor: color,
        width: _vm.isMobile ? '8px' : '10px',
        height: _vm.isMobile ? '8px' : '10px',
      })})}),0):_vm._e(),_c('figure',[_c('img',{staticClass:"mt-1 w-100",staticStyle:{"flex":"unset","aspect-ratio":"1/1 !important"},attrs:{"src":_vm.image.url,"alt":_vm.image.alt},on:{"error":_vm.catchImageLoadingError}}),_c('figcaption',[_c('div',{staticClass:"d-flex justify-space-between align-end mt-3 flex-column flex-lg-row"},[_c('div',{staticClass:"info--text font-weight-medium text-body-2 text-decoration-line-through"},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.item.basePrice / 10))+" ")]),_c('div',{staticClass:"primary--text font-weight-bold text-large"},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.item.salePrice / 10))+" تومان ")])]),_c('div',{staticClass:"mt-2",staticStyle:{"position":"relative"}},[_c('p',{staticClass:"text-body-1 font-weight-bold more--text mb-0 line-clamp text-right",class:[_vm.isMobile && 'text-body-2']},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]),_c('v-expand-transition',[(_vm.hover)?_c('div',{staticClass:"text-body-1 white font-weight-bold secondary--text text-wrap text-right card-product__price-wrapper-title-hovered",class:[_vm.isMobile && 'text-body-2']},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]):_vm._e()])],1),(_vm.isDesktop)?_c('div',{staticClass:"d-flex flex-wrap justify-space-between align-center mt-3"},[_c('div',{staticClass:"d-flex justify-start"},_vm._l((_vm.colors),function(color,index){return _c('div',{key:`${color}-${index}`,staticClass:"rounded-circle mt-1 ml-1",staticStyle:{"border":"1px solid var(--v-secondary-lighten5)"},style:({
              backgroundColor: color,
              width: _vm.isMobile ? '8px' : '10px',
              height: _vm.isMobile ? '8px' : '10px',
            })})}),0),(_vm.item.satisfactionRate.count)?_c('div',{staticClass:"d-flex"},[_c('v-rating',{staticClass:"amazing-rating",attrs:{"dir":"ltr","color":"warning","length":"5","dense":"","readonly":"","half-increments":"","value":_vm.item.satisfactionRate.score,"size":_vm.isMobile ? 16 : 18}})],1):_vm._e()]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }