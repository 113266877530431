import store from "@/app/store/index";
import storageService from "@/services/LocalStorageService.service";
import { MessageService } from "@/services/MessageService.service";
import { getUserCartAxiosRequest } from "@/packages/order/repository/api/order-cart/get.cart.api";
import { changeCartItemCountAxiosRequest } from "../../repository/api/order-cart/update.cart.item.count.api";
import { deleteCartItemCountAxiosRequest } from "@/packages/order/repository/api/order-cart/delete.cart.item.api";
import { getUserCartInitAxiosRequest } from "@/packages/order/repository/api/order-cart/get.cart.init.api";
import { navigateToLoginBeforeContinue } from "@/packages/order/actions/navigator-actions/navigator.actions";
import { verifyCartAxiosRequest } from "@/packages/order/repository/api/order-cart/get.cart.validate.api";
import { handleErrors } from "@/packages/order/actions/error.actions";

/**
 *  get user cart
 *  ------------------------
 *  Description| Make api call to get user cart
 *  ------------------------
 *  @param cart_id {String}
 *  @param query_params {Object}
 *  ------------------------
 *  @return  type {Promise}
 *  ------------------------
 **/
export const getUserCartAction = async function (cart_id, query_params = null) {
  try {
    const { data } = await getUserCartAxiosRequest(cart_id, query_params);
    await store.dispatch("cart/setCart", data);
    return data;
  } catch (e) {
    await Promise.reject(e);
  }
};

/**
 *  change cart item count by id
 *  ------------------------
 *  Description| Make api call to change cart item count by id
 *  ------------------------
 *  @param cart_id {String}
 *  @param payload {Object}
 *  @param query_params {Object}
 *  ------------------------
 *  @return  type {Promise}
 *  ------------------------
 **/
export const changeCartItemCountAction = async function (
  cart_id,
  payload,
  query_params = null
) {
  try {
    const { data } = await changeCartItemCountAxiosRequest(
      cart_id,
      payload,
      query_params
    );
    await store.dispatch("cart/setCartItem", {
      item_id: payload.itemId,
      cart: data,
    });
    return data;
  } catch (e) {
    MessageService.error("FAILED");
    await Promise.reject(e);
  }
};

/**
 *  delete cart item count by id
 *  ------------------------
 *  Description| Make api call to delete cart item count by id
 *  ------------------------
 *  @param cart_id {String}
 *  @param query_params {Object}
 *  ------------------------
 *  @return  type {Promise}
 *  ------------------------
 **/
export const deleteCartItemCountAction = async function (
  cart_id,
  query_params = null
) {
  try {
    const { data } = await deleteCartItemCountAxiosRequest(
      cart_id,
      query_params
    );
    await store.dispatch("cart/deleteCartItem", {
      item_id: query_params.itemId,
      cart: data,
    });
    MessageService.success("DELETE_SUCCESS_ORDER");
    return data;
  } catch (e) {
    MessageService.error("DELETE_FAILED");
    await Promise.reject(e);
  }
};

/**
 *  get user cart
 *  ------------------------
 *  Description| Make api call to get user cart
 *  ------------------------
 *  @param query_params {Object}
 *  ------------------------
 *  @return  type {Promise}
 *  ------------------------
 **/
export const getUserCartInitAction = async function (query_params = null) {
  try {
    const { cardId } = storageService.get("cart");
    // console.log(cardId, "CART id");
    if (cardId) {
      const { data } = await getUserCartAxiosRequest(cardId, query_params);
      await store.dispatch("cart/setCart", data);
      return data;
    } else {
      const { data } = await getUserCartInitAxiosRequest(query_params);
      await store.dispatch("cart/setCart", data);
      return Promise.resolve(data);
    }
  } catch (e) {
    const { data } = await getUserCartInitAxiosRequest(query_params);
    await store.dispatch("cart/setCart", data);
    return Promise.resolve(data);
  }
};

/**
 *  verify cart for next order step
 *  ------------------------
 *  Description| Make api call to verify cart for next order step
 *  if user authenticated
 *  else navigate to login page
 *  ------------------------
 *  @param cart_id {String}
 *  @param query_params {Object}
 *  ------------------------
 *  @return  type {Promise}
 *  ------------------------
 **/
export const verifyCartAction = async function (cart_id, query_params = null) {
  try {
    if (store.getters["authentication/check"]) {
      const { data } = await verifyCartAxiosRequest(cart_id, query_params);
      if ("isValid" in data) {
        await store.dispatch("cart/setCart", data);
        MessageService.error("no item in card");
      } else {
        await store.dispatch("cart/setCart", data);
        await store.dispatch("order.wizard/nextStep");
      }
    } else await navigateToLoginBeforeContinue();
  } catch (e) {
    if (e.error.status !== 401) {
      await handleErrors(e);
    }
    await Promise.reject(e);
  }
};
