import urls from "@/helpers/api_route_manager.helper";
import axios from "axios";
import store from "@/app/store/index";

export const fetchLabelsSlugsAxiosRequest = function () {
  return axios.get(urls.api("slugs_labels"));
};

export const getLabelsSlugsService = async function () {
  try {
    if (store.getters["labelSlugs/isExpire"]) {
      const { data } = await fetchLabelsSlugsAxiosRequest();
      await store.dispatch("labelSlugs/saveItems", data.content);
      await store.dispatch("labelSlugs/saveExpireTime");
      return data.content;
    } else {
      return await store.getters["labelSlugs/items"];
    }
  } catch (e) {
    await Promise.reject(e);
  }
};
