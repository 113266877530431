<template>
  <v-card-text v-if="item.type === itemTypes.LOADING" class="text-center py-5">
    <v-progress-circular indeterminate color="primary" large />
  </v-card-text>
  <v-card-text
    v-else-if="item.type === itemTypes.ERROR"
    class="text-center py-5"
  >
    خطای سرور. دوباره تلاش کنید
  </v-card-text>
  <v-card-actions
    v-else-if="item.type === itemTypes.NOT_FOUND"
    class="font-weight-medium text-body-1 secondary--text"
  >
    <v-icon small class="me-1">mdi-magnify</v-icon>
    موردی برای {{ item.text }} یافت نشد.
  </v-card-actions>
  <!--    <v-card-actions-->
  <!--      v-else-if="item.type === itemTypes.ALL"-->
  <!--      @click="-->
  <!--        goToSearchPage({-->
  <!--          name: 'products.search',-->
  <!--          query: { q: item.text },-->
  <!--        })-->
  <!--      "-->
  <!--      class="font-weight-medium text-body-1 secondary&#45;&#45;text"-->
  <!--    >-->
  <!--      <v-icon small class="me-1">mdi-magnify</v-icon>-->
  <!--      جستجوی {{ item.text }}-->
  <!--    </v-card-actions>-->
  <v-card-actions
    v-else-if="item.type === itemTypes.PRODUCT_GROUP"
    @click="
      goToSearchPage({
        name: 'products.category',
        params: { cat_title: item.productGroup.slug },
        // query: { catId: item.productGroup.id },
      })
    "
    class="font-weight-medium text-body-1 secondary--text"
  >
    <v-icon small class="me-1">mdi-magnify</v-icon>
    همه محصولات در
    <span class="primary--text mx-1">{{ item.productGroup.name }}</span>
  </v-card-actions>
  <v-card-actions
    v-else-if="item.type === itemTypes.BRAND"
    @click="
      goToSearchPage({
        name: 'products.search',
        query: { q: item.text, brandId: item.brand.slug },
      })
    "
    class="font-weight-medium text-body-1 secondary--text"
  >
    <v-icon small class="me-1">mdi-magnify</v-icon>
    {{ item.text }} در
    <span class="primary--text mx-1">{{ item.brand.name }}</span>
  </v-card-actions>
  <v-card
    v-else-if="item.type === itemTypes.PRODUCT"
    class="d-flex my-1"
    color="#F7F7F8"
    elevation="0"
  >
    <v-card-actions
      @click.stop="
        goToSearchPage({
          name: 'product.detail',
          params: { slug: item.lp.slug },
        })
      "
    >
      <v-avatar rounded color="white" class="pa-1 me-3" size="60">
        <v-img
          contain
          :src="imageUrlBuilder(item.lp.imageUrl)"
          :alt="item.lp.imageAlt"
        />
      </v-avatar>
      <div class="d-flex flex-column justify-space-between">
        <div class="text-body-2 secondary--text font-weight-bold">
          {{ item.lp.title }}
        </div>
        <div class="text-body-1 secondary--text font-weight-bold">
          <span v-if="item.lp.salePrice !== 0">
            {{ $_currency(item.lp.salePrice / 10) }} تومان
          </span>
          <span v-else>ناموجود</span>
        </div>
      </div>
    </v-card-actions>
  </v-card>

  <!--  <div v-else-if="item.type === itemTypes.COUNT"></div>-->
  <div v-else></div>
</template>

<script>
import equalDeep from "fast-deep-equal";
import mixinImage_url_builder from "@/app/mixins/mixin.image_url_builder";
const itemTypes = Object.freeze({
  ALL: "all",
  PRODUCT: "product",
  BRAND: "brand",
  PRODUCT_GROUP: "product-group",
  COUNT: "count",
  LOADING: "loading",
  ERROR: "error",
  NOT_FOUND: "not-found",
});
export default {
  name: "SearchBoxItem",
  mixins: [mixinImage_url_builder],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { itemTypes };
  },
  methods: {
    goToSearchPage(redirect) {
      if (!equalDeep(this.$route.query, redirect.query)) {
        this.$router.push(redirect);
      }
      this.$emit("blur");
    },
  },
};
</script>

<style scoped></style>
