// initial state

const state = () => ({
  comments: [],
  productsForRegisterComments: [],
});

// getters
export const getters = {
  comments: (state) => state.comments,
  productsForRegisterComments: (state) => state.productsForRegisterComments,
};

// mutations
export const mutations = {
  mutateComments(state, payload) {
    state.comments = payload;
  },
  mutateProductsForRegisterComments(state, payload) {
    state.productsForRegisterComments = payload;
  },
};

// actions
export const actions = {
  setComments({ commit }, payload) {
    commit("mutateComments", payload);
  },
  setProductsForRegisterComments({ commit }, payload) {
    commit("mutateProductsForRegisterComments", payload);
  },
};

export default {
  namespaced: true,
  name: "comments",
  state,
  getters,
  actions,
  mutations,
};
