import api from "@/services/ApiService.service";
import urls from "@/helpers/api_route_manager.helper";

/**
 *  get user address by id
 *  ------------------------
 *  Api address| /order/card/card-init/:id
 *  ------------------------
 *  Method| Get
 *  ------------------------
 *  Description| get user address by id
 *  ------------------------
 *  @param cart_id {String}
 *  @param query_params {Object}
 *  ------------------------
 *  @return  type {Promise}
 *  ------------------------
 **/
export const getUserCartAxiosRequest = function (cart_id, query_params = null) {
  return api.get(urls.api("get_cart", [`${cart_id}`]), {
    params: {
      ...query_params,
    },
  });
};
