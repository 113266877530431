<template>
  <v-row no-gutters class="me-n7 ms-0">
    <v-col cols="9" class="row flex-wrap px-0">
      <v-col
        v-if="hasExists(item.labels)"
        cols="2"
        class="d-flex justify-space-between"
        style="height: fit-content"
      >
        <!--        <v-divider style="height: auto" vertical />-->
        <div class="d-flex flex-column">
          <div class="secondary--text font-weight-bold mb-5">
            {{
              $_t("components.header.mega_menu.label_title", {
                title: item.title,
              })
            }}
          </div>

          <router-link
            v-for="label in item.labels"
            :key="label.id"
            class="text-decoration-none more--text font-weight-medium py-1 w-100"
            :to="{
              name: 'products.label-brand',
              params: { cat_title: item.slug, title: label.slug },
              // query: { catId: item.productGroupId, labelId: label.id },
            }"
          >
            {{ label.title }}
          </router-link>
        </div>
      </v-col>
      <v-col
        v-if="hasExists(item.brands)"
        cols="2"
        class="d-flex justify-start"
        style="height: fit-content"
      >
        <v-divider
          class="ml-6"
          style="height: auto"
          vertical
          v-if="item.labels.length"
        />

        <div class="d-flex flex-column">
          <div class="secondary--text font-weight-bold mb-5">
            {{
              $_t("components.header.mega_menu.brand_title", {
                title: item.title,
              })
            }}
          </div>
          <router-link
            v-for="brand in item.brands"
            :key="brand.id"
            class="text-decoration-none more--text font-weight-medium py-1 w-100"
            :to="{
              name: 'products.label-brand',
              params: { cat_title: item.slug, title: brand.slug },
              // query: { catId: item.productGroupId, brandId: brand.id },
            }"
          >
            {{ item.title }} {{ brand.title }}
          </router-link>
        </div>
      </v-col>
      <template v-if="hasExists(item.children)">
        <v-col
          cols="2"
          class="d-flex justify-start"
          style="height: fit-content"
          v-for="child in item.children"
          :key="child.productGroupId"
        >
          <v-divider class="ml-6" style="height: auto" vertical />

          <div>
            <router-link
              :to="{
                name: 'products.category',
                params: { cat_title: child.slug },
                // query: { catId: child.productGroupId },
              }"
              replace
              class="text-decoration-none d-block secondary--text font-weight-bold mb-5"
            >
              {{ child.title }}
            </router-link>
            <div v-if="hasExists(child.labels)" class="mb-5">
              <div
                class="secondary--text font-weight-bold mb-2 ps-2 border-right"
              >
                {{
                  $_t("components.header.mega_menu.label_title", {
                    title: "",
                  })
                }}
              </div>
              <router-link
                v-for="label in child.labels"
                :key="label.id"
                class="text-decoration-none d-block more--text font-weight-medium py-1 w-100"
                :to="{
                  name: 'products.label-brand',
                  params: { cat_title: child.slug, title: label.slug },
                  // query: { catId: child.productGroupId, labelId: label.id },
                }"
              >
                {{ label.title }}
              </router-link>
            </div>
            <div v-if="hasExists(child.brands)">
              <div
                class="secondary--text font-weight-bold mb-2 ps-2 border-right"
              >
                {{
                  $_t("components.header.mega_menu.brand_title", {
                    title: "",
                  })
                }}
              </div>
              <router-link
                v-for="brand in child.brands"
                :key="brand.id"
                class="text-decoration-none d-block more--text font-weight-medium py-1 w-100"
                :to="{
                  name: 'products.label-brand',
                  params: { cat_title: child.slug, title: brand.slug },
                  // query: { catId: child.productGroupId, brandId: brand.id },
                }"
              >
                {{ child.title }} {{ brand.title }}
              </router-link>
            </div>
          </div>
        </v-col>
      </template>
    </v-col>

    <v-col cols="3" class="pe-0 d-flex justify-center">
      <v-img
        v-if="item.desktopImage"
        width="373"
        height="373"
        contain
        :src="imageUrlBuilder(item.desktopImage)"
        :alt="item.desktopImageAlt"
        @click="goToCta"
        :class="{ 'cursor-pointer': item.cta }"
      >
        <template v-slot:placeholder>
          <v-sheet height="100%" color="white">
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="primary"
                aria-label="progress"
              />
            </v-row>
          </v-sheet>
        </template>
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
import mixinImage_url_builder from "@/app/mixins/mixin.image_url_builder";

export default {
  name: "MegaMenuDesktopCard",
  mixins: [mixinImage_url_builder],
  props: {
    item: { type: Object, required: true },
  },
  methods: {
    goToCta() {
      if (this.item.cta) {
        return this.$router.push(
          `${this.item.cta[0] === "/" ? "" : "/"}${this.item.cta}`
        );
      }
    },
    hasExists(item) {
      return item && item.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.border-right {
  border-right: 2px solid var(--v-primary-base);
}
</style>
