// import api from "@/services/ApiService.service";

import axios from "axios";

export const fetchProductsGroupsSlugsAxiosRequest = function () {
  return axios.get("https://lipak.com/api/v1/contents/productGroups/slug");
};

export const fetchProductsSlugsAxiosRequest = function () {
  return axios.get("https://lipak.com/api/v1/products/slug");
};

export const getProductsGroupSlugsService = async function () {
  try {
    const { data } = await fetchProductsGroupsSlugsAxiosRequest();
    return data;
  } catch (e) {
    await Promise.reject(e);
  }
};

export const getProductsSlugsService = async function () {
  try {
    const { data } = await fetchProductsSlugsAxiosRequest();
    return data;
  } catch (e) {
    await Promise.reject(e);
  }
};
