import { fetchProductByTitleAxiosRequest } from "@/packages/product/repository/api/product.api";
// initial state
const state = () => ({
  product: null,
  selectedColor: "",
  selectedGuarantee: "",
  currentLpv: null,
  firstComment: null,
  accessories: [],
  comments: [],
  relations: [],
});

// getters
export const getters = {
  product(state) {
    return state.product;
  },
  lpId(state) {
    return state.product ? state.product.lpId : null;
  },
  isAvailable(state) {
    return !!state.product.lpv.filter((lpv) => lpv.available).length;
  },
  availableLpvs(state) {
    let available = state.product.lpv.filter((lpv) => lpv.available);
    return available.sort((a, b) => a.salePrice - b.salePrice);
  },
  selectedColor(state) {
    return state.selectedColor;
  },
  selectedGuarantee(state) {
    return state.selectedGuarantee;
  },
  currentLpv(state) {
    return state.currentLpv;
  },
  lpvColors(state, getter) {
    return [...new Set(getter.availableLpvs.map((lpv) => lpv.colorHex))];
  },
  lpvGuaranteesByColor(state, getter) {
    return getter.availableLpvs
      .filter((lpv) => lpv.colorHex === state.selectedColor)
      .map((lpv) => lpv.guarantee);
  },
  satisfaction(state) {
    return state.product.satisfaction;
  },
  specialFeatures(state) {
    return state.product.specialFeatures;
  },
  firstComment(state) {
    return state.firstComment;
  },
  accessories(state) {
    return state.product.accessories.map((item) => {
      return {
        ...item,
        isLoading: false,
      };
    });
  },
  comments: (state) => state.comments,
  relations: (state) => state.relations,
};

// mutations
export const mutations = {
  mutateProduct(state, value) {
    state.product = value;
  },
  mutateSelectedColor(state, value) {
    state.selectedColor = value;
  },
  mutateSelectedGuarantee(state, value) {
    state.selectedGuarantee = value;
  },
  mutateCurrentLpv(state, value) {
    state.currentLpv = value;
  },
  mutateFirstComment(state, value) {
    state.firstComment = value;
  },
  mutateComments(state, value) {
    state.comments = value;
  },
  mutateRelations(state, value) {
    state.relations = value;
  },
};

// actions
export const actions = {
  async setSelectedColor({ dispatch, commit, getters }, value) {
    await commit("mutateSelectedColor", value);
    await dispatch("setSelectedGuarantee", getters.lpvGuaranteesByColor[0]);
  },
  async setSelectedGuarantee({ commit, dispatch }, value) {
    await commit("mutateSelectedGuarantee", value);
    await dispatch("setCurrentLpv");
  },
  async setCurrentLpv({ commit, getters, dispatch }) {
    let lpvs = getters.product.lpv.filter(
      (lpv) =>
        lpv.colorHex === getters.selectedColor &&
        lpv.guarantee === getters.selectedGuarantee
    );

    let currentLpv = lpvs.find((lpv) => lpv.available);
    if (currentLpv) {
      await commit("mutateCurrentLpv", currentLpv);
      await dispatch(
        "data_layer/setViewItem",
        {
          currentLpv: currentLpv,
          product: getters.product,
        },
        { root: true }
      );
    } else {
      await commit("mutateCurrentLpv", lpvs[0]);
    }
  },
  resetProduct({ commit }) {
    commit("mutateProduct", null);
  },
  async setProduct({ commit, getters, dispatch }, slug) {
    const { data: product } = await fetchProductByTitleAxiosRequest(slug);
    await commit("mutateProduct", product);
    await dispatch("setSelectedColor", getters.lpvColors[0]);
  },
  setFirstComment({ commit }, value) {
    commit("mutateFirstComment", value);
  },
  setComments({ commit }, value) {
    commit("mutateComments", value);
  },

  setRelations({ commit }, value) {
    commit("mutateRelations", value);
  },
};

export default {
  namespaced: true,
  name: "product",
  state,
  getters,
  actions,
  mutations,
};
