module.exports = {
  key: "product",
  mega_menu: {
    components: {},
    views: {},
    route_titles: {},
  },
  attribute: {
    q: "نام کالا",
  },
  placeholder: { q: "نام کالا را وارد کنید" },
};
