import store from "@/app/store";
import { MessageService } from "@/services/MessageService.service";
import { getUserCartInitAction } from "@/packages/order/actions/order-cart/cart.actions";

export const handleErrors = async (e) => {
  if (e.error.status === 413 || e.error.status === "413") {
    await store.dispatch("cart/setHasPaymentWaitingLimit", true);
  } else if (e.error.status === 412 || e.error.status === "412") {
    await store.dispatch("cart/setHasDailyLimitCount", {
      state: true,
      message: e.error.message,
    });
  } else {
    await getUserCartInitAction();
    await store.dispatch("cart/setIsUserCheckedChanges", false);
    if (
      e.error.message.toLocaleLowerCase().includes("card have some empty item")
    )
      MessageService.error("DELETE_ALL_UNAVAILABLE_ITEMS");
  }
};
