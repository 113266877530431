module.exports = {
  key: "profile",
  profile: {
    components: {},
    views: {},
    route_titles: {
      index: "profile",
    },
  },
  attribute: {},
  placeholder: {},
};
