var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"d-flex flex-row-reverse py-2 align-center ss02",class:[_vm.isMobile ? 'white--text' : 'primary--text  '],style:({
    borderColor: `${
      _vm.isMobile ? 'var(--v-white-base)' : 'var(--v-primary-base)'
    }!important`,
  }),attrs:{"outlined":"","elevation":"0","color":_vm.isMobile ? 'primary' : 'white'}},[_c('div',{staticClass:"time-holder font-weight-bold"},[_vm._v(_vm._s(_vm.getHMS.hours))]),_c('v-divider',{style:({
      borderColor: `${
        _vm.isMobile ? 'var(--v-white-base)' : 'var(--v-primary-base)'
      }`,
    }),attrs:{"vertical":""}}),_c('div',{staticClass:"time-holder font-weight-bold"},[_vm._v(_vm._s(_vm.getHMS.minutes))]),_c('v-divider',{style:({
      borderColor: `${
        _vm.isMobile ? 'var(--v-white-base)' : 'var(--v-primary-base)'
      }`,
    }),attrs:{"vertical":""}}),_c('div',{staticClass:"time-holder font-weight-bold"},[_vm._v(_vm._s(_vm.getHMS.seconds))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }