<template>
  <v-container class="px-4 px-lg-16 white py-10" fluid>
    <v-card
      :elevation="isMobile ? 0 : 2"
      :color="isMobile ? 'white' : 'background'"
      class="d-flex flex-wrap justify-center justify-md-space-around font-weight-medium"
      :class="[!isMobile && 'pa-6']"
    >
      <v-card
        :outlined="isMobile"
        elevation="0"
        v-for="item in items"
        :key="item.title"
        class="px-3 py-2"
        :class="[isMobile ? 'service-container' : 'transparent']"
      >
        <div class="text-center">
          <v-icon color="primary">{{ item.icon }}</v-icon>
        </div>
        <div class="mt-2">
          {{ item.title }}
        </div>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "ServiceList",
  data() {
    return {
      items: [
        { title: "تحویل در کوتاه‌ترین زمان", icon: "mdi-truck-outline" },
        { title: "تامین‌کننده کالا", icon: "mdi-home-city-outline" },
        { title: "اصالت کالا", icon: "mdi-shield-check-outline" },
        { title: "قیمت مناسب کالا", icon: "mdi-ticket-percent-outline" },
        { title: "خدمات پس از فروش", icon: "mdi-hours-24" },
        { title: "ضمانت ۷ روز بازگشت", icon: "mdi-backup-restore" },
      ],
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>

<style scoped>
.service-container {
  width: 150px;
  margin: 5px;
  text-align: center;
}
</style>
