<template>
  <!--  <InitLoading v-if="isLoading" :value="isLoading" />-->
  <!--  <ServerUnderConstruction v-else-if="hasError" />-->
  <ServerError v-if="hasError" />
  <div v-else>
    <component :is="layout" v-if="layout" />
    <LipakSnackbar />
    <LipakAppUpdater />
    <!--    <UnderConstruction-->
    <!--      @close-alert="isUnderConstruction = false"-->
    <!--      :overlay="isUnderConstruction"-->
    <!--    />-->
  </div>
</template>

<script>
import app from "@/config/app.config";
import LipakAppUpdater from "@/app/components/LipakAppUpdater";
import { InitializerService } from "@/services/Initializer.service";
import mixinImage_url_builder from "@/app/mixins/mixin.image_url_builder";
import logoSrc from "@/app/assets/images/logo/img.png";
import { mapGetters } from "vuex";
// import ServerUnderConstruction from "@/app/components/ServerUnderConstruction";
// import UnderConstruction from "@/app/UnderConstruction";
// Load layout components dynamically.
const requireContext = require.context("@/app/layouts", false, /.*\.vue$/);

const layouts = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.vue$)/g, ""), requireContext(file)])
  .reduce((components, [name, component]) => {
    components[name] = component.default || component;
    return components;
  }, {});

const initializerServiceKeys = [
  "megaMenu",
  "authentication",
  "cartInit",
  "labels",
];
const initializerBanners = ["slider"];
const initializerSpecial = ["special"];
// import lipakSnackbar from "./app/components/LipakSnackbar.vue";
export default {
  name: "App",
  mixins: [mixinImage_url_builder],
  components: {
    // ServerUnderConstruction,
    // UnderConstruction,
    // InitLoading: () => import("@/app/components/InitLoading"),
    ServerError: () => import("@/app/components/ServerError"),
    LipakAppUpdater,
    // lipakSnackbar,
    LipakSnackbar: () => import("@/app/components/LipakSnackbar"),
  },
  data() {
    return {
      layout: null,
      title: this.$_t("route_titles.index"),
      isLoading: false,
      hasError: false,
      // isUnderConstruction: false,
    };
  },
  computed: {
    ...mapGetters({
      sliderImages: "home/sliderImages",
      systemBar: "systemBar/data",
      specials: "home/specials",
    }),
    isDesktop() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
  },
  watch: {
    $route: {
      handler(newValue) {
        if (newValue.name === "home" && this.sliderImages.length) {
          let image = this.isDesktop
            ? this.sliderImages[0].desktopImage
            : this.sliderImages[0].mobileImage;
          this.setPreloadIImages(image, "image-slider-preload");
        }

        if (
          !(
            this.$route.name === "home" ||
            this.$route.name === "products.category" ||
            this.$route.name === "products.label-brand"
          ) &&
          this.systemBar.isActive
        ) {
          this.removeSystemBarPreLoadIImages("image-system-bar-preload");
        }
        if (this.$route.name !== "home") {
          this.removeSystemBarPreLoadIImages("image-special-preload");
        }
      },
      deep: true,
    },
    sliderImages: {
      handler(newValue) {
        if (this.$route.name === "home" && newValue.length) {
          let image = this.isDesktop
            ? this.sliderImages[0].desktopImage
            : this.sliderImages[0].mobileImage;
          this.setPreloadIImages(image, "image-slider-preload");
        }
      },
      deep: true,
      immediate: true,
    },
    specials: {
      handler(newValue) {
        if (this.$route.name === "home" && newValue.length) {
          this.setPreloadIImages(
            this.specials[0].image.url,
            "image-special-preload"
          );
        }
      },
      deep: true,
      immediate: true,
    },
    systemBar: {
      handler(newValue) {
        if (
          (this.$route.name === "home" ||
            this.$route.name === "products.category" ||
            this.$route.name === "products.label-brand") &&
          newValue.isActive
        ) {
          let image = this.isDesktop
            ? this.systemBar.desktopImageUrl
            : this.systemBar.mobileImageUrl;
          this.setPreloadIImages(image, "image-system-bar-preload");
        }
      },
      deep: true,
      // immediate: true,
    },
  },
  async created() {
    // this.isLoading = true;
    InitializerService(initializerBanners);
    if (!this.isDesktop && this.$route.name === "home") {
      InitializerService(initializerSpecial);
    }
    const isSuccessful = await InitializerService(initializerServiceKeys);
    if (!isSuccessful) {
      this.hasError = true;
    }
    // this.isLoading = false;
  },
  methods: {
    setLayout(layout) {
      this.layout = layouts[layout];
    },
    setPreloadIImages(image, id) {
      const link = document.createElement("link");
      link.href = this.imageUrlBuilder(image);
      link.rel = "preload";
      link.as = "image";
      link.id = id;
      document.getElementsByTagName("head")[0].appendChild(link);
    },

    removeSystemBarPreLoadIImages(id) {
      let latestImg = document.getElementById(id);
      if (latestImg) {
        document.getElementsByTagName("head")[0].removeChild(latestImg);
      }
    },
  },
  mounted() {
    // setTimeout(() => {
    //   this.isUnderConstruction = true;
    // }, 550);
  },
  metaInfo() {
    const title = this.title;
    const defaultDescription =
      "فروشگاه لیپک سایت آنلاین در عرضه بهترین محصولات دنیای فناوری با معتبرترین گارانتی و ارائه دهنده مشاوره حرفه‌ای به کاربران گرامی";
    const imageSrc = location.origin + logoSrc;
    const currentRoute = location.origin + this.$route.fullPath;
    return {
      meta: [
        {
          name: "og:title",
          vmid: "og:title",
          content: title,
        },
        {
          name: "description",
          vmid: "description",
          content: defaultDescription,
        },
        {
          name: "og:description",
          vmid: "og:description",
          content: defaultDescription,
        },
        {
          name: "og:image",
          vmid: "og:image",
          content: imageSrc,
        },
        {
          name: "og:url",
          vmid: "og:url",
          content: currentRoute,
        },
        {
          name: "og:type",
          vmid: "og:type",
          content: "website",
        },
        {
          name: "og:site_name",
          vmid: "og:site_name",
          content: "فروشگاه اینترنتی لیپک",
        },
        {
          name: "og:locale",
          vmid: "og:locale",
          content: "fa-IR",
        },
        {
          name: "twitter:card",
          vmid: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:title",
          vmid: "twitter:title",
          content: title,
        },
        {
          name: "twitter:description",
          vmid: "twitter:description",
          content: defaultDescription,
        },
        {
          name: "twitter:image",
          vmid: "twitter:image",
          content: imageSrc,
        },
        {
          name: "twitter:site",
          vmid: "twitter:site",
          content: currentRoute,
        },
        {
          name: "twitter:creator",
          vmid: "twitter:creator",
          content: "@lipakcom",
        },
      ],
      link: [
        {
          rel: "canonical",
          vmid: "canonical",
          href: currentRoute,
        },
      ],
      // if no subcomponents specify a metaInfo.title, this title will be used
      title,
      // all titles will be injected into this template
      titleTemplate: "%s | " + app.app_name,
    };
  },
};
</script>
<style></style>
