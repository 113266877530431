<template>
  <v-footer color="#E5E5E5" class="pa-0">
    <v-container
      fluid
      class="px-4 px-lg-16 py-3"
      :class="{ 'new_blue py-8': !isMobile, white: isMobile }"
    >
      <v-row class="align-center" :class="[isMobile && 'ma-n4']">
        <v-col cols="12" md="4">
          <GoToTop
            :color="isMobile ? 'primary' : 'white'"
            :class="[
              !isMobile && 'white--text',
              isMobile && 'primary--text w-100 text-center d-inline-block',
            ]"
          />
        </v-col>
        <v-col v-if="!isMobile" cols="4" class="text-center">
          <template v-for="(item, index) in topLinks">
            <router-link
              v-if="item.type === 'internalLink'"
              :key="item.title + 'index-lg'"
              :to="item.to"
              class="text-decoration-none white--text font-weight-medium"
              :class="[index && 'mr-5']"
            >
              {{ item.title }}
            </router-link>
            <a
              v-else
              :key="item.title + 'index-md'"
              :href="item.to"
              :class="[index && 'mr-5']"
              class="text-decoration-none white--text font-weight-medium"
            >
              {{ item.title }}
            </a>
          </template>
        </v-col>

        <v-col
          cols="12"
          md="4"
          class="text-center text-md-center text-lg-end new_blue"
        >
          <v-btn
            v-for="(item, index) in topSocial"
            :class="[index && 'mr-2']"
            :key="item.icon"
            :href="item.href"
            color="white"
            small
            fab
            :aria-label="item.name"
          >
            <v-icon color="primary">{{ item.icon }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="px-4 px-lg-16 py-3">
      <v-row class="align-center">
        <v-col cols="12" md="3"><Communication /></v-col>
        <v-col cols="12" md="9">
          <v-row>
            <v-col v-if="isMobile" cols="12">
              <div class="font-weight-bold mb-3">لینک‌های مهم</div>
              <div v-for="item in topLinks" :key="item.title" class="mt-2">
                <router-link
                  v-if="item.type === 'internalLink'"
                  :to="item.to"
                  class="text-decoration-none more--text"
                >
                  {{ item.title }}
                </router-link>
                <a
                  v-else
                  :href="item.to"
                  class="text-decoration-none more--text"
                >
                  {{ item.title }}
                </a>
              </div>
            </v-col>
            <v-col cols="12" md="4"><BuyInfo /></v-col>
            <v-col cols="12" md="4"><Rules /></v-col>
            <v-col cols="12" md="4"><About /></v-col>
          </v-row>
        </v-col>
      </v-row>
      <Symbols class="mt-8" />
      <div class="text-center mt-6 font-weight-medium">
        <v-icon color="secondary" class="ml-1" small>mdi-copyright</v-icon>
        <span>کلیه حقوق متعلق به </span>
        <router-link
          class="text-decoration-none primary--text"
          :to="{ name: 'home' }"
        >
          فروشگاه اینترنتی لیپک
        </router-link>
        <span> می‌باشد.</span>
      </div>
    </v-container>
  </v-footer>
</template>

<script>
import GoToTop from "@/app/components/new-footer/GoToTop";
import Communication from "@/app/components/new-footer/Communication";
import BuyInfo from "@/app/components/new-footer/BuyInfo";
import Rules from "@/app/components/new-footer/Rules";
import About from "@/app/components/new-footer/About";
import Symbols from "@/app/components/new-footer/Symbols";
export default {
  name: "Footer",
  components: { Symbols, About, Rules, BuyInfo, Communication, GoToTop },
  data() {
    return {
      topLinks: [
        { title: "صفحه اصلی", to: { name: "home" }, type: "internalLink" },
        {
          title: "مجله خبری لیپک",
          to: "https://lipak.com/mag/",
          type: "externalLink",
        },
        {
          title: "تماس با ما",
          to: { name: "contact-us" },
          type: "internalLink",
        },
        { title: "درباره ما", to: { name: "about-us" }, type: "internalLink" },
      ],
      topSocial: [
        {
          icon: "mdi-instagram",
          href: "https://www.instagram.com/lipakcom",
          name: "instagram",
        },
        {
          icon: "mdi-twitter",
          href: "https://twitter.com/lipakcom",
          name: "twitter",
        },
        {
          icon: "mdi-linkedin",
          href: "https://www.linkedin.com/company/lipak",
          name: "linkedin",
        },
      ],
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>

<style scoped></style>
