<template>
  <div>
    <div class="font-weight-bold mb-3">قوانین و مقررات</div>
    <div v-for="link in links" :key="link.title" class="mt-2">
      <router-link :to="link.to" class="text-decoration-none more--text">
        {{ link.title }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Rules",
  data() {
    return {
      links: [
        { to: { name: "terms" }, title: "قوانین و مقررات خرید از لیپک" },
        { to: "#", title: "حریم خصوصی" },
        { to: { name: "guarantee-guide" }, title: "گارانتی لیپک" },
      ],
    };
  },
};
</script>

<style scoped></style>
