const addresses = [
  {
    path: "addresses",
    name: "dashboard.addresses",
    component: () =>
      import(
        /* webpackChunkName: "profile" */ "@/packages/profile/presentation/components/dashboard-content/addresses/Index"
      ),
  },
];

export default addresses;
