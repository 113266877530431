<template>
  <div class="introduction">
    <v-card
      class="elevation-0 pb-8"
      :class="seeMore ? 'limit-of-description' : 'card-transition'"
      tag="article"
    >
      <v-scroll-y-transition
        class="w-100 d-flex flex-wrap w-100 d-flex flex-wrap justify-space-between"
      >
        <div key="introduction">
          <v-row class="px-4 px-lg-16 py-3">
            <v-col
              cols="12"
              :style="{
                height: seeMore ? '445px' : '100%',
                overflow: 'hidden',
              }"
            >
              <article
                dir="rtl"
                class="ck__description ss02 elevation-3 pa-4 pa-md-6 pa-lg-10 rounded-lg"
                :class="{ 'description-mobile': !isDesktop }"
              >
                <h1>فروشگاه اینترنتی لیپک</h1>

                <p>
                  فروشگاه اینترنتی لیپک، طیف وسیعی از کالاهای دیجیتال روز دنیا
                  را از برند&zwnj;های معتبر عرضه می&zwnj;کند. خرید محصول از
                  فروشگاه&zwnj;های اینترنتی لوازم دیجیتال چالش&zwnj;هایی به
                  همراه دارد از جمله:
                </p>

                <ul>
                  <li>صحت اصالت کالا</li>

                  <li>تضمین خدمات پشتیبانی</li>

                  <li>تفاوت قیمتی نسبت به بازار سنتی</li>
                </ul>

                <p>
                  برای رفع این چالش&zwnj;ها، فروشگاه لیپک از طریق واردات و سایر
                  تامین&zwnj;کنندگان اصلی کالا، محصولات خود را تامین می&zwnj;کند
                  تا بتواند کالاها را به قیمت مناسبی عرضه کند. لیپک تمامی
                  محصولات خود را با ضمانت اصالت کالا در اختیار شما قرار
                  می&zwnj;دهد. فروشگاه اینترنتی لیپک همواره سعی کرده است تا
                  تجربه خریدی رضایت&zwnj;بخش و &nbsp;مطمئن را به شما ارائه دهد.
                  به همین منظور تیم پشتیبانی لیپک در تمامی مراحل خرید و پس از
                  آن، همراه شما خواهد بود.
                </p>

                <h2>خرید کالای دیجیتال</h2>

                <ul>
                  <li>
                    <strong>موبایل</strong>: مجموعه&zwnj;ای از جدیدترین
                    مدل&zwnj;های موبایل اپل، شیائومی، سامسونگ، موتورولا و هوآوی
                    همراه با اطلاعات تخصصی در سایت لیپک قابل مشاهده و بررسی است.
                    ویژگی&zwnj;های بسیاری مانند ظرفیت حافظه، دوربین و باتری در
                    <a
                      href="https://lipak.com/product-category/%D9%85%D9%88%D8%A8%D8%A7%DB%8C%D9%84/"
                      >قیمت موبایل</a
                    >
                    تاثیرگذارند. شما می&zwnj;توانید برای خرید گوشی مورد نظر خود،
                    مشخصات و قیمت&zwnj; آن را در فروشگاه اینترنتی لیپک مشاهده
                    کنید.
                  </li>

                  <li>
                    <strong>لپ&zwnj;تاپ</strong>: فروشگاه اینترنتی لیپک
                    مجموعه&zwnj;ی کاملی از لپ&zwnj;تاپ&zwnj;&zwnj;های گیمینگ،
                    برنامه&zwnj;نویسی، گرافیکی، اداری، اقتصادی و دانشجویی را در
                    مدل&zwnj;های متنوع عرضه می&zwnj;کند. برای
                    <a
                      href="https://lipak.com/product-category/%D9%84%D9%BE-%D8%AA%D8%A7%D9%BE/"
                      >خرید لپ&zwnj;&zwnj;تاپ</a
                    >
                    از فروشگاه لیپک، می&zwnj;توانید پس از بررسی مشخصات و کسب
                    اطلاعات تخصصی از لپ&zwnj;تاپ&zwnj;های اپل، ایسوس، اچ پی،
                    لنوو و مایکروسافت، سفارش خود را ثبت کنید.
                  </li>

                  <li>
                    <strong>تبلت</strong>: با توجه به صفحه نمایش بزرگ و راحتی در
                    جابه&zwnj;جایی، تبلت&zwnj;ها از نظر کاربری بسیار کارآمد
                    هستند. اگر نیاز به
                    <a
                      href="https://lipak.com/product-category/%D8%AA%D8%A8%D9%84%D8%AA/"
                      >خرید تبلت</a
                    >
                    ویندوزی یا اندرویدی دارید، می&zwnj;توانید مجموعه&zwnj;ی
                    کاملی از تبلت&zwnj;ها را در برند&zwnj;های مختلف مانند
                    مایکروسافت، سامسونگ و اپل (آیپد)، همراه با ویژگی&zwnj;های
                    دقیق آن&zwnj;ها در سایت لیپک مشاهده کنید.
                  </li>

                  <li>
                    <strong>هندزفری</strong>: برای
                    <a href="https://lipak.com/product-category/hands-free/"
                      >خرید هندزفری</a
                    >
                    ویژگی&zwnj;هایی مانند حجم و کیفیت صدا، قابلیت کنترل لمسی و
                    عمر باتری اهمیت بالایی دارند. تنوع گسترده&zwnj;ای از
                    هدفون&zwnj;های مطرح مانند هدفون انکر، سونی، جی بی ال،
                    شیائومی، کیو سی وای، سامسونگ و اپل را در فروشگاه لیپک مشاهده
                    کنید.
                  </li>

                  <li>
                    <strong>ساعت هوشمند</strong>: ساعت&zwnj;های هوشمند امروزه
                    وظایف مهم&zwnj;تری از نمایش زمان بر عهده دارند. امکاناتی
                    مانند قابلیت مکالمه، ویژگی ضدآب و نمایش اعلان و
                    پیام&zwnj;ها، بخشی از ویژگی&zwnj;های ساعت&zwnj; هوشمند است.
                    شما می&zwnj;توانید برند&zwnj;های معروف
                    <a href="https://lipak.com/product-category/smart-watch/"
                      >ساعت هوشمند</a
                    >
                    مانند اپل، سامسونگ، امیزفیت، شیائومی و کیسلکت را همراه با
                    مشخصات و بررسی تخصصی آن در سایت لیپک مشاهده کنید.
                  </li>

                  <li>
                    <strong>کنسول بازی: </strong>بخش مهمی از کاربران لیپک را
                    افراد علاقه&zwnj;مند به بازی تشکیل می&zwnj;دهند. لیپک مجموعه
                    بزرگی از محصولات گیمینگ مانند
                    <a href="https://lipak.com/product-category/console/"
                      >کنسول بازی</a
                    >
                    Xbox، پلی استیشن و لوازم جانبی آن&zwnj;ها را در سبد محصولات
                    خود دارد.
                  </li>
                </ul>

                <h3>لوازم جانبی</h3>

                <p>
                  &nbsp;<strong>لوازم جانبی موبایل و کامپیوتر</strong> به دلیل
                  کاربرد فراوان آن&zwnj;ها در زندگی روزمره ما، اهمیت
                  ویژه&zwnj;ای پیدا کرده&zwnj;اند. فروشگاه لیپک مجموعه&zwnj;ی
                  کاملی از لوازم جانبی دیجیتال را در برند&zwnj;های مختلف فراهم
                  کرده است تا شما را در انتخابی بهتر یاری کند.
                </p>

                <ul>
                  <li>
                    <strong>موس</strong>: اگر بازی کامپیوتری انجام می&zwnj;دهید
                    و یا یک گیمر حرفه&zwnj;ای هستید، موس گیمینگ انتخاب بهتری
                    برای شماست. موس گیمینگ ویژگی&zwnj;های مهمی مانند سرعت IPS،
                    قابلیت شخصی&zwnj;سازی دکمه&zwnj;ها و نورپردازیRGB &nbsp;را
                    دارد. شما می&zwnj;توانید برای
                    <a href="https://lipak.com/product-category/mouse/"
                      >خرید موس</a
                    >
                    گیمینگ از برندهای مطرح بازار مانند لاجیتک، ریزر، میشن،
                    ردراگون و ای&zwnj;دیتا در سایت لیپک دیدن کنید.
                  </li>

                  <li>
                    <strong>هارد اکسترنال</strong>: انواع متفاوتی از
                    هارد&zwnj;های اکسترنال با ظرفیت&zwnj;های مختلف و
                    قابلیت&zwnj;هایی مانند ضدآب، ضدضربه و ضد شوک وجود دارند.
                    هاردها در ابعاد متفاوت و برای کاربری&zwnj;های گوناگون طراحی
                    شده&lrm;اند. برای
                    <a href="https://lipak.com/product-category/external-hdd/"
                      >خرید هارد اکسترنال</a
                    >
                    می&zwnj;توانید از میان برند&zwnj;های موجود در سایت لیپک
                    مانند هارد ای&zwnj;دیتا، سیگیت و وسترن دیجیتال انتخاب کنید.
                  </li>

                  <li>
                    <strong>شارژر</strong>:
                    <a href="https://lipak.com/product-category/phone-charger/"
                      >شارژر گوشی</a
                    >
                    از اصلی&zwnj;ترین لوازم جانبی همراه است. شما می&zwnj;توانید
                    مجموعه&zwnj;ای از شارژرهای مختلف از جمله شارژر دیواری و
                    شارژر&zwnj; فندکی اورجینال &nbsp;را از برندهای معتبر، در
                    سایت لیپک مشاهده کنید.
                  </li>

                  <li>
                    <strong>پاوربانک</strong>: نیاز به شارژ دوباره باتری گوشی کم
                    و بیش برای همه&zwnj;ی ما وجود دارد. زمان زیادی است که
                    پاوربانک یا
                    <a href="https://lipak.com/product-category/power-bank/"
                      >شارژر همراه</a
                    >
                    به همین منظور مورد استفاده قرار می&zwnj;گیرد.
                    پاوربانک&zwnj;ها در ظرفیت&zwnj; و برندهای متنوعی عرضه
                    می&zwnj;شوند. شما می&zwnj;توانید مدل&zwnj;های&zwnj; جدید
                    پاوربانک فست شارژ، سامسونگ، شیائومی، باسئوس، ای&zwnj;دیتا و
                    انکر را در سایت لیپک مشاهده کنید.
                  </li>

                  <li>
                    <strong>فلش مموری و کارت حافظه</strong>: کارت حافظه و فلش
                    مموری برای ذخیره و انتقال اطلاعات بسیار مهم و کاربردی است.
                    برای
                    <a href="https://lipak.com/product-category/flash-memory/"
                      >خرید فلش مموری</a
                    >
                    و
                    <a href="https://lipak.com/product-category/memory-card/"
                      >کارت حافظه</a
                    >
                    ویژگی&zwnj;های&zwnj; مهمی مانند سرعت انتقال اطلاعات و ظرفیت
                    آنان در نظر گرفته می&zwnj;شود. شما می&zwnj;توانید طیف وسیعی
                    از برند&zwnj;های فلش مموری و کارت حافظه را در فروشگاه لیپک
                    مشاهده و خریداری کنید.
                  </li>

                  <li>
                    <strong>پایه نگهدارنده</strong>: کار با گوشی موبایل در حین
                    رانندگی، مشکلات خاص خود را دارد. استفاده از پایه&zwnj;های
                    نگهدارنده موبایل در زمان مسیریابی یا نقشه&zwnj;خوانی بسیار
                    متداول شده است. پایه&zwnj; نگهدارنده موبایل قابلیت نصب بر
                    روی شبکه کولر و داشبورد خودرو را دارد. طرح&zwnj;های متنوعی
                    از پایه نگهدارنده باسئوس و گرین در فروشگاه لیپک موجود است.
                  </li>
                </ul>

                <h3>لوازم صوتی و تصویری</h3>

                <p>
                  <strong>لوازم صوتی و تصویری&zwnj;</strong> یکی از ملزومات مهم
                  در هر خانه&zwnj; است. شما می&zwnj;توانید در وبسایت لیپک،
                  برندهای معروف اسپیکر مانند جی بی ال، انکر، سونی، هارمن کاردن،
                  اپل و بسیاری از مدل&zwnj;های تی وی باکس را مشاهده کنید.
                </p>

                <ul>
                  <li>
                    <strong>اسپیکر</strong>: طیف وسیعی از اسپیکرها در ابعاد و
                    کاربری&zwnj;های مختلف، مانند اسپیکر قابل حمل، پارتی باکس،
                    ساندبار و … در سایت لیپک قابل مشاهده است. شما می&zwnj;توانید
                    با کسب اطلاعات تخصصی درباره
                    <a href="https://lipak.com/product-category/speaker/"
                      >قیمت اسپیکر</a
                    >&zwnj; و ویژگی&zwnj;های آن مانند میزان توان خروجی، قابلیت
                    ضدآب و نوع باتری، محصول مورد نظر خود را انتخاب کنید.
                  </li>

                  <li>
                    <strong>تی&zwnj;وی باکس</strong>: اجرای برنامه&zwnj; و
                    بازی&zwnj;های اندروید بر روی تلویزیون، کار سختی نیست.
                    می&zwnj;توانید انواع تی&zwnj;وی باکس و اندروید باکس را در
                    سایت لیپک مشاهده کنید.
                  </li>
                </ul>

                <h2>راهنمای خرید از فروشگاه لیپک</h2>

                <ul>
                  <li>
                    <strong>روش</strong><strong> خرید</strong>: پس از ثبت سفارش
                    در سایت، خرید شما در سریع&zwnj;ترین زمان پردازش می&zwnj;شود.
                    لازم به ذکر است که مراحل پردازش و ارسال کالا، از طریق پیامک
                    به شما اطلاع داده می&zwnj;شود.
                  </li>

                  <li>
                    <strong>شیوه ارسال</strong>: سفارش&zwnj;های شهر تهران به
                    صورت حضوری و یا از طریق پیک لیپک در همان روز قابل دریافت
                    است. سفارش&zwnj;های سایر نقاط کشور در سریع&zwnj;ترین زمان از
                    طریق پست پیشتاز و سایر شرکت&zwnj;های پستی ارسال می&zwnj;شود.
                  </li>

                  <li>
                    <strong>پشتیبانی</strong>: با خرید از فروشگاه اینترنتی لیپک،
                    تمامی محصولات را همراه با پشتیبانی ۲۴ ساعته و با
                    گارانتی&zwnj; معتبر دریافت خواهید کرد. شما می توانید از طریق
                    شماره تلفن ۵۸۲۵۶۰۰۰ با بخش پشتیبانی در تماس باشید.
                  </li>
                </ul>

                <p>
                  برای کسب اطلاعات بیشتر در مورد نحوه ارسال و پرداخت مبلغ سبد
                  خرید خود به صفحه&zwnj;ی “<a
                    href="https://lipak.com/payment-methods-guide"
                    >راهنمای پرداخت و ارسال سفارش</a
                  >” مراجعه کنید.
                </p>

                <h2>مزایای خرید از لیپک</h2>

                <ul>
                  <li>
                    <strong>تحویل در کوتاه&zwnj;ترین زمان</strong>: شرکت لیپک
                    جهت رفاه حال کاربران، امکان ارسال سفارش با پیک را تا حداکثر
                    ۳ ساعت پس از ثبت سفارش در تهران، فراهم کرده است. سفارش
                    شهرهای دیگر، در کمتر از ۳۰ دقیقه پردازش و در همان روز به
                    شرکت&zwnj;های پستی تحویل داده می&zwnj;شود.
                  </li>

                  <li>
                    <strong>واردات مستقیم کالا</strong>: شرکت راه آگاه آیندگان
                    کنگان بیشتر کالاهای خود را از طریق&nbsp; واردات مستقیم از
                    کشورهای مختلف تهیه می&zwnj;کند.
                  </li>

                  <li>
                    <strong>قیمت مناسب کالا</strong>: فروشگاه لیپک همیشه در تلاش
                    است که محصولات خود را با بهترین قیمت بازار از طریق واردات
                    مستقیم عرضه &zwnj;کند.
                  </li>

                  <li>
                    <strong>تضمین اصالت کالا</strong>: شرکت لیپک&nbsp; به عنوان
                    تامین کننده، این تضمین را به کاربران خود می&zwnj;دهد که
                    تمامی محصولات از جمله لوازم جانبی، دارای ضمانت اصالت کالا و
                    اورجینال هستند.
                  </li>

                  <li>
                    <strong>ضمانت بازگشت ۷ روزه</strong>: لیپک به خریداران این
                    امکان را می&zwnj;دهد تا ۷ روز پس از دریافت سفارش، کالا را
                    مرجوع کنند. برای مشاهده قوانین و شرایط بازگشت کالا به صفحه
                    «<a href="https://lipak.com/order-canceling-guide"
                      >راهنمای لغو و مرجوعی کالا</a
                    >» یا صفحه «<a href="https://lipak.com/terms"
                      >قوانین خرید از فروشگاه لیپک</a
                    >» مراجعه کنید.
                  </li>
                </ul>
              </article>
            </v-col>
          </v-row>
        </div>
      </v-scroll-y-transition>

      <v-col cols="12" v-if="seeLess" class="d-flex justify-center">
        <span
          @click="showContent"
          class="blue--text font-weight-bold text-body-1 cursor-pointer"
        >
          مشاهده کمتر <v-icon color="blue" dense>mdi-chevron-up</v-icon>
        </span>
      </v-col>
      <v-col cols="12" v-else class="d-flex justify-center">
        <span
          @click="showContent"
          class="blue--text font-weight-bold text-body-1 cursor-pointer"
        >
          مشاهده بیشتر
          <v-icon color="blue" dense>mdi-chevron-down</v-icon>
        </span>
      </v-col>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Introduction",

  data() {
    return {
      seeMore: true,
      seeLess: false,
    };
  },
  computed: {
    isDesktop() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
  },
  methods: {
    showContent() {
      this.seeMore = !this.seeMore;
      this.seeLess = !this.seeLess;
    },
  },
};
</script>

<style scoped></style>
<style>
.introduction > .limit-of-description:before {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0), white);
  content: "";
  height: 460px;
  position: absolute;
  width: 100%;
}
.introduction > .card-transition {
  transition: all 2s ease;
}
</style>
