module.exports = {
  key: "products",
  products: {
    components: {
      top_filters: {
        filters: {
          popular: "محبوب‌ترین",
          newest: "جدید‌ترین",
          lowest: "ارزان‌ترین",
          viewed: "پربازدید‌ترین",
          highest: "گران‌ترین",
          sales: "پرفروش‌ترین",
        },
      },
    },
    views: {},
    route_titles: {
      products: "محصولات",
    },
  },
  attribute: {},
  placeholder: {},
};
