import { getMegaMenu } from "@/app/config/actions/megaMenu.action";
import { setAuthDataToStore } from "@/app/config/actions/authentication.action";
import { getUserCartInitAction } from "@/packages/order/actions/order-cart/cart.actions";
import { getLabelsSlugsService } from "@/services/Labels-slugs.service";
import {
  getSliderItemsAxiosRequest,
  getSpecialProductsAxiosRequest,
} from "@/packages/home/api/main.api";
import store from "../app/store";

const configs = {
  megaMenu: async () => {
    await getMegaMenu();
  },
  authentication: async () => {
    await setAuthDataToStore();
  },
  cartInit: async () => {
    await getUserCartInitAction();
  },
  labels: async () => {
    await getLabelsSlugsService();
  },
  slider: async () => {
    let { data } = await getSliderItemsAxiosRequest();
    await store.dispatch("home/setSliderImage", data);
  },

  special: async () => {
    let { data } = await getSpecialProductsAxiosRequest({
      pageNumber: 0,
      pageSize: 12,
    });
    await store.dispatch("home/setSpecials", data.content);
  },
};

export const InitializerService = async (keys) => {
  try {
    const promises = [];
    keys.forEach((key) => {
      // https://stackoverflow.com/a/39283005
      if (Object.prototype.hasOwnProperty.call(configs, key)) {
        promises.push(configs[key]());
      }
    });
    await Promise.all(promises);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};
