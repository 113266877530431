"use strict";

export default [
  {
    path: "/auth",
    name: "authentication",
    redirect: { name: "check-phone" },
    component: () =>
      import(
        /* webpackChunkName: "auth-pkg" */ "@/packages/authentication/presentation/views/Index"
      ),
    children: [
      {
        path: "auth/check-phone",
        name: "check-phone",
        component: () =>
          import(
            /* webpackChunkName: "auth-pkg" */ "@/packages/authentication/presentation/components/auth/Main"
          ),
        meta: {
          layout: "auth",
        },
      },
      {
        path: "auth/register",
        name: "register",
        redirect: { name: "otp.validate" },
        component: () =>
          import(
            /* webpackChunkName: "auth-pkg" */ "@/packages/authentication/presentation/views/Register"
          ),
        children: [
          {
            path: "auth/register/otp/validate",
            name: "otp.validate",
            component: () =>
              import(
                /* webpackChunkName: "auth-pkg" */ "@/packages/authentication/presentation/components/otp/OtpValidator"
              ),
            meta: {
              layout: "auth",
            },
          },
          {
            path: "auth/register/password",
            name: "create.password",
            component: () =>
              import(
                /* webpackChunkName: "auth-pkg" */ "@/packages/authentication/presentation/components/password/CreatePassword"
              ),
            meta: {
              layout: "auth",
            },
          },
        ],
      },
      {
        name: "login",
        path: "auth/login",
        redirect: { name: "login.password" },
        component: () =>
          import(
            /* webpackChunkName: "auth-pkg" */ "@/packages/authentication/presentation/views/Login"
          ),
        children: [
          {
            path: "/auth/login/password",
            name: "login.password",

            component: () =>
              import(
                /* webpackChunkName: "auth-pkg" */ "@/packages/authentication/presentation/components/login/LoginWithPassword"
              ),
            meta: {
              layout: "auth",
            },
          },
          {
            path: "/auth/login/otp",
            name: "login.otp",

            component: () =>
              import(
                /* webpackChunkName: "auth-pkg" */ "@/packages/authentication/presentation/components/login/LoginWithOTP"
              ),
            meta: {
              layout: "auth",
            },
          },
          {
            path: "/auth/login/forget-password",
            name: "login.forget-password",

            component: () =>
              import(
                /* webpackChunkName: "auth-pkg" */ "@/packages/authentication/presentation/components/forget-password/ForgetPassword"
              ),
            meta: {
              layout: "auth",
            },
          },
          {
            path: "/auth/login/reset-password/:input_password",
            name: "login.reset-password",

            component: () =>
              import(
                /* webpackChunkName: "auth-pkg" */ "@/packages/authentication/presentation/components/forget-password/ResetPassword"
              ),
            meta: {
              layout: "auth",
            },
          },
        ],
      },
    ],
  },
];
