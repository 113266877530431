module.exports = {
  key: "product",
  mega_menu: {
    components: {},
    views: {},
    route_titles: {},
  },
  attribute: {},
  placeholder: {},
};
