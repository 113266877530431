import util from "util";

// Urls Class
// this class handle all urls and api address

/*
 * import all api urls in packages directory /src/packages
 * ------------------------------------
 * @return array of urls
 * ------------------------------------
 * Description:
 * ------------------------------------
 * It allows you to pass in a directory to search,
 * a flag indicating whether subdirectories should be searched too,
 * and a regular expression to match files against.
 * load all file with urls.js extension type in packages directory
 * then return an array of default module in read files
 *  */
const loadUrls = function () {
  const context = require.context("@/packages", true, /.*\.urls.js$/i);
  return context
    .keys()
    .map(context) // import module
    .map((m) => m.default); // get `default` export from each resolved module
};

const list = {
  base_url: process.env.VUE_APP_BASE_URL || "https://cui.zoft.ir/api/",
  /*
  Public routes
  */
  mega_menu: "users/megaMenu",
  mega_menu_search: "shop/search",
  systemBar: "marketing/systemBar",

  slugs_products_group: "contents/productGroups/slug",
  slugs_products: "products/slug",

  fetch_province: "contents/provinces",
  fetch_cities_by_province_id: "contents/cities",
  fetch_distinct_by_city_id: "contents/quarter/Get-All/%s",
  slugs_labels: "contents/contentLabels",
  /*
  Test routes
  */
  test_url: "test",
  test_url_with_path_variable: "test/%s",
};

//Add urls to list
loadUrls().forEach((url) => {
  Object.assign(list, list, url);
});

const urls = {
  apiParamsSupperKey: "@api_params:",

  // Get api
  api(
    key,
    parameters = null,
    version = process.env.VUE_APP_BASE_API_VERSION || "v1/"
  ) {
    if (!Array.isArray(parameters)) parameters = [];
    let url = list[key];
    if (parameters.length !== 0) url = joinParametersToString(url, parameters);
    return list["base_url"] + version + url;
  },

  // Add server base url to a url
  server(url) {
    return list["base_url"] + url;
  },

  // Get web with current host name
  local(key) {
    return this.current() + this.web(key);
  },

  // Get current web url with protocol
  current() {
    return window.location.protocol + "//" + window.location.hostname;
  },

  // Get url with key
  url(key = "base_url") {
    return list[key];
  },
};

// Join parameters to string
function joinParametersToString(string, p) {
  const params = p;
  if (params === undefined || params === []) return string;
  if (!string.includes("%s")) return string;
  params.unshift(string);
  return util.format.apply(util, params);
}

export default urls;
