var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"pa-3 ss02 cursor-pointer fill-height d-flex flex-column justify-space-between",style:([
      _vm.campaignDetail
        ? {
            border:
              '1px solid ' + `${_vm.campaignDetail.colorHex}` + '!important',
          }
        : '',
    ]),attrs:{"outlined":"","link":"","to":{
      name: 'product.detail',
      params: { slug: _vm.item.slug },
    }}},[_c('div',{staticClass:"d-flex justify-space-between pb-2"},[(_vm.campaignDetail)?_c('div',[_c('div',{staticClass:"font-weight-bold text-body-1",style:({
            color: `${_vm.campaignDetail.colorHex} `,
          })},[_vm._v(" "+_vm._s(_vm.campaignDetail.title)+" ")]),(_vm.campaignDetail.discount)?_c('div',{staticClass:"font-weight-bold text-body-2",style:({
            color: `${_vm.campaignDetail.colorHex} `,
          })},[_vm._v(" + تخفیف "+_vm._s(_vm.$_currency(_vm.campaignDetail.discount / 10))+" تومانی ")]):_vm._e()]):_vm._e(),(_vm.isProductHasDiscount || _vm.campaignDetail)?_c('v-btn',{staticClass:"rounded-sm text-body-2 mr-auto white--text primary",class:[
          !+_vm.percent
            ? _vm.$vuetify.breakpoint.xs
              ? 'd-none'
              : 'visibility-hidden'
            : '',
        ],style:([
          _vm.campaignDetail
            ? {
                backgroundColor: _vm.campaignDetail.colorHex + '!important',
              }
            : '',
        ]),attrs:{"small":""}},[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('span',{key:_vm.percent},[_vm._v(_vm._s(_vm.percent)+"%")])])],1):_vm._e()],1),_c('figure',{staticClass:"d-flex justify-space-between align-center"},[(!_vm.image.url)?_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"width":"100px","height":"100px"},attrs:{"mode":"in-out"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","aria-label":"progress"}})],1):_c('img',{staticClass:"mt-1 product-image-size",attrs:{"src":_vm.image.url,"alt":_vm.image.alt,"mode":"in-out"},on:{"error":_vm.catchImageLoadingError}}),_c('figcaption',{staticClass:"w-100 pr-1"},[_c('div',{staticClass:"mt-2",staticStyle:{"position":"relative"}},[_c('p',{staticClass:"text-body-1 font-weight-bold more--text mb-0 line-clamp text-right",class:[_vm.isMobile && 'text-body-2']},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]),_c('v-expand-transition',[(hover)?_c('div',{staticClass:"text-body-1 white font-weight-bold secondary--text text-wrap text-right card-product__price-wrapper-title-hovered",class:[_vm.isMobile && 'text-body-2']},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]):_vm._e()])],1),_c('div',{staticClass:"d-flex justify-space-between align-center mt-6"},[_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.colors),function(color,index){return _c('div',{key:`${color}-${index}`,staticClass:"rounded-circle mt-1 ml-1",staticStyle:{"border":"1px solid var(--v-secondary-lighten5)"},style:({
                backgroundColor: color,
                width: _vm.isMobile ? '8px' : '10px',
                height: _vm.isMobile ? '8px' : '10px',
              })})}),0),(_vm.item.satisfactionRate.count)?_c('div',{staticClass:"d-flex"},[_c('v-rating',{staticClass:"amazing-rating",attrs:{"dir":"ltr","color":"warning","length":"5","dense":"","readonly":"","half-increments":"","value":_vm.item.satisfactionRate.score,"size":_vm.isMobile ? 16 : 18}})],1):_vm._e()]),(_vm.item.saleType === 'STOP')?_c('div',{staticClass:"d-flex justify-space-between mt-3 primary--text font-weight-bold"},[_c('div',{staticClass:"mr-auto"},[_vm._v("ناموجود")])]):_c('div',{staticClass:"d-flex justify-space-between mt-3"},[(+_vm.percent)?_c('div',{staticClass:"info--text font-weight-medium text-small text-decoration-line-through"},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.item.basePrice / 10))+" ")]):_vm._e(),_c('div',{staticClass:"primary--text font-weight-bold text-left mr-auto"},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.item.salePrice / 10))+" تومان ")])])])])])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }