"use strict";

import { getProductsGroupSlugsService } from "@/services/Slugs.service";

export default [
  {
    path: "/product-category/",
    name: "products",
    component: () =>
      import(
        /* webpackChunkName: "products-pkg" */ "@/packages/products/presentation/views/index"
      ),
    redirect: { name: "products.search", query: { q: "" } },
    children: [
      {
        path: "search",
        name: "products.search",
        component: () =>
          import(
            /* webpackChunkName: "products-pkg" */ "@/packages/products/presentation/components/Search"
          ),
      },
      // {
      //   path: "specials",
      //   name: "products.specials",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "products-pkg" */ "@/packages/products/presentation/components/Specials"
      //     ),
      // },
      {
        path: "specials",
        name: "products.specials",
        component: () =>
          import(
            /* webpackChunkName: "products-pkg" */ "@/packages/products/presentation/components/SpecialsAndAmazing.vue"
          ),
      },
      {
        path: ":cat_title//",
        name: "products.category",
        sensitive: true,
        strict: true,
        props: true,
        meta: {
          slugs: async () => await getProductsGroupSlugsService(),
        },
        component: () =>
          import(
            /* webpackChunkName: "products-pkg" */ "@/packages/products/presentation/components/Category"
          ),
      },
      {
        path: ":cat_title/:title//",
        name: "products.label-brand",
        component: () =>
          import(
            /* webpackChunkName: "products-pkg" */ "@/packages/products/presentation/components/BrandsAndLabels"
          ),
      },
      {
        path: "specials",
        name: "products.specials",
        component: () =>
          import(
            /* webpackChunkName: "products-pkg" */ "@/packages/products/presentation/components/Specials"
          ),
      },
    ],
  },
];
