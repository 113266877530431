module.exports = {
  translated: "Hi Buddy",
  components: {
    header: {
      slogan: "LIPAK slogan",
      searchPlaceholder: "search in LIPAK ...",
      magazine: "LIPAK news magazine",
      contactUs: "contact us",
      aboutUs: "about us",
      profile: "profile",
      register_login: "register / login",
      cart: "cart",
      mega_menu: {
        home: "home page",
        label_title: "{title} based on usage",
        brand_title: "{title} brand",
      },
    },
  },
  schema: {
    mobile_bottom_nav: {
      home: "Home",
      grouping: "search & categories",
      cart: "Cart",
      profile: "Profile",
      register_login: "register / login",
    },
  },
  router: {},
  route_titles: {
    index: "Lipak",
  },
  views: {
    not_found: {
      home: "return to main page",
      text1: "error 404 page not found!",
      text2: "your page not found!",
      text3: "probably this page moved to another address or has been removed.",
    },
    server_error: {
      home: "try again",
      text1: "error 500 something went wrong",
      text2: "internal server error",
      text3: "we are fixing the problem. try again later.",
    },
  },
  layouts: {},
  attribute: {
    phone: "phone",
    password: "password",
    confirm: "confirm",
  },
  placeholder: {
    phone: "phone",
    password: "password",
    confirm: "confirm",
  },
  validations: {
    required: "{attribute} can not be empty.",
    alpha: "{attribute} Can only contain letters A-Z.",
    alpha_num: "{attribute} Can only contain letters A-Z and numbers 9-0",
    alpha_dash: "{attribute} Can contain of letter, numbers, - and _. ",
    alpha_spaces: "{attribute} Can only contain letter A-Z as well as space.",
    between:
      "{attribute} Can contain minimum {min} characters and maximum {max} characters.",
    confirmed: "{attribute} Password dose not match.",
    digits: "{attribute} Must contain numbers and exactly {length} characters.",
    length:
      "{attribute} Must contain characters and exactly {length} characters.",
    dimensions:
      "{attribute} Dimensions need to be {width} pixels height and {height} pixels width.",
    email: "{attribute} Must be a valid email address.",
    excluded: "{attribute} Must be a valid input.",
    ext: "{attribute} Must be a valid file format.",
    image: "{attribute} Must be an image.",
    max_value: "{attribute} Must be {max} or less than {max}",
    max: "{attribute} Cannot be longer than {length}",
    mimes: "{attribute} Must be a valid format.",
    minLength: "{attribute} Must be {min} or more than {min}",
    min: "{attribute} Must be at least {length} characters.",
    numeric: "{attribute} Can only contain numbers.",
    oneOf: "{attribute} Must be a valid input.",
    regex:
      "{attribute} must contain at least an Upper case letter,lower case letter,special character(# $ @ %) and a number.",
    required_if: "{attribute} Mandatory.",
    size: "{attribute} File size can be {size}KB",
    double: "{attribute} Must be a valid decimal number.",
    sameAsPassword: "{attribute} Must be same as password.",
    mixCharacterPersianAndEnglishValidation: "{attribute} is not valid",
  },

  FAILED: "",
  OTP_RESEND_FAILED: "",
  OTP_RESEND_SUCCESS: "",
  INVALID_GRANT: "Username / password or access token is wrong",
};
