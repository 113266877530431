import api from "@/services/ApiService.service";
import urls from "@/helpers/api_route_manager.helper";

/**
 *  change cart item count by id
 *  ------------------------
 *  Api address| /order/card/change-item//:id
 *  ------------------------
 *  Method| Put
 *  ------------------------
 *  Description| change cart item count by id
 *  ------------------------
 *  @param cart_id {String}
 *  @param payload {Object}
 *  @param query_params {Object}
 *  ------------------------
 *  @return  type {Promise}
 *  ------------------------
 **/
export const changeCartItemCountAxiosRequest = function (
  cart_id,
  payload,
  query_params = null
) {
  return api.put(urls.api("change_cart_item_count", [`${cart_id}`]), payload, {
    params: {
      ...query_params,
    },
  });
};
