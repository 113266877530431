<template>
  <div
    v-if="!hasError"
    class="py-4 py-lg-6 white"
    :class="[!isMobile && 'clipped-container']"
  >
    <v-container fluid class="px-4 px-lg-16 pt-lg-5">
      <v-carousel
        cycle
        :interval="3500"
        class="rounded"
        delimiter-icon="mdi-minus"
        hide-delimiter-background
        :height="isMobile ? '66vw' : '26.5vw'"
        :show-arrows="!isMobile"
        show-arrows-on-hover
      >
        <!--Start slider items     ---------------------------------------------------------------------------------------->
        <v-carousel-item v-if="isLoading">
          <v-sheet class="fill-height">
            <v-skeleton-loader type="image@3" class="mx-auto" loading />
          </v-sheet>
        </v-carousel-item>
        <template v-else>
          <v-carousel-item
            v-for="(item, index) in slider"
            :key="'slider-image-' + item.index"
            :href="item.cta ? item.cta : '#'"
            target="_blank"
            :style="{
              minHeight: isDesktop ? '26.5vw' : '66vw',
              width: '100% !important',
            }"
            tag="div"
            role="link"
          >
            <img
              v-if="imageUrlBuilder(item.desktopImage) && item.desktopImage"
              :key="isDesktop"
              :src="
                isDesktop
                  ? imageUrlBuilder(item.desktopImage)
                  : imageUrlBuilder(item.mobileImage)
              "
              :loading="Number(index) === 0 ? 'eager' : 'lazy'"
              class="rounded banner-image-size w-100 h-100"
              style="
                width: 100% !important;
                height: 100% !important;
                min-width: 100%;
              "
              :alt="isDesktop ? item.desktopAlt : item.mobileAlt"
            />
            <v-sheet v-else class="banner-image-size" color="white">
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  aria-label="progress"
                />
              </v-row>
            </v-sheet>
          </v-carousel-item>
          <!--          <SliderItem-->
          <!--            v-for="(item, index) in slider"-->
          <!--            :item="item"-->
          <!--            :key="'slider-item' + index"-->
          <!--            aria-label="more detail about this product"-->
          <!--            :image-eager="Number(index) === 0 ? 'eager' : 'lazy'"-->
          <!--          />-->
        </template>
        <!--End   slider items     ---------------------------------------------------------------------------------------->
      </v-carousel>
    </v-container>
  </div>
</template>

<script>
import imageUrlBuilder from "@/app/mixins/mixin.image_url_builder";
import { getSliderItemsAxiosRequest } from "@/packages/home/api/main.api";
import { mapGetters } from "vuex";
// import SliderItem from "./SliderItem.vue";
export default {
  name: "TopSlider",
  mixins: [imageUrlBuilder],

  components: {
    // SliderItem,
  },
  data() {
    return {
      /* Slider options */
      continuous: false,
      currentSlide: 0,
      hasError: false,
      isLoading: true,
      // slider: [],
    };
  },
  computed: {
    ...mapGetters({
      slider: "home/sliderImages",
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    isDesktop() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
  },
  watch: {
    slider: {
      handler(newValue) {
        if (newValue.length) {
          this.isLoading = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.$gtm.trackView("TopSlider", "currentPath");
  },
  // created() {
  //   if (this.sliderImages.length > 0) {
  //     this.slider = this.sliderImages;
  //   } else {
  //     this.fetchSliderItems();
  //   }
  // },
  methods: {
    async fetchSliderItems() {
      this.isLoading = true;
      this.hasError = false;
      try {
        const { data } = await getSliderItemsAxiosRequest();
        this.slider = data;
      } catch (err) {
        console.log(err);
        this.hasError = true;
      }
      this.isLoading = false;
    },
    onClick() {
      this.$gtm.trackEvent({
        event: null, // Event type [default = 'interaction'] (Optional)
        category: "Calculator",
        action: "click",
        label: "Home page SIP calculator",
        value: 5000,
        noninteraction: false, // Optional
      });
    },

    removeSliderPreloadImages() {
      let latestImg = document.getElementById("image-slider-preload");
      if (latestImg) {
        document.getElementsByTagName("head")[0].removeChild(latestImg);
      }
    },
  },
  beforeDestroy() {
    this.removeSliderPreloadImages();
  },
};
</script>

<style scoped></style>
