// initial state

const state = () => ({
  addresses: [],
  inputEnabledToQuarter: null,
  mutateCities: [],
});

// getters
export const getters = {
  addresses: (state) => state.addresses,
  inputEnabledToQuarter: (state) => state.inputEnabledToQuarter,
  mutateCities: (state) => state.mutateCities,
};

// mutations
export const mutations = {
  mutateAddress(state, payload) {
    state.addresses = payload;
  },
  mutateInputEnabledToQuarter(state, value) {
    state.inputEnabledToQuarter = value;
  },
  mutateCities(state, value) {
    state.mutateCities = value;
  },
};

// actions
export const actions = {
  setAddress({ commit }, payload) {
    commit("mutateAddress", payload);
  },
  setInputEnabledToQuarter({ commit }, data) {
    commit("mutateInputEnabledToQuarter", data);
  },
  setCities({ commit }, data) {
    commit("mutateCities", data);
  },
};

export default {
  namespaced: true,
  name: "addresses",
  state,
  getters,
  actions,
  mutations,
};
