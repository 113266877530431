<template>
  <div class="mt-0">
    <v-app-bar
      app
      elevation="0"
      :height="gradientHeight"
      :max-height="gradientHeight"
      class="header-gradient mt-0"
      tag="div"
    />
    <v-app-bar
      app
      :hide-on-scroll="isMobile"
      :scroll-off-screen="isMobile"
      elevation="0"
      :height="height"
      :max-height="height"
      color="#dff3fe"
      class="header-app-bar mt-0"
    >
      <SystemBar v-if="isActiveSystemBar" />

      <HeaderMobile
        v-model="drawer"
        v-if="isMobile"
        :class="{ 'mt-header': isActiveSystemBar }"
      />
      <HeaderDesktop v-else :class="{ 'mt-header': isActiveSystemBar }" />
    </v-app-bar>
    <Navigation v-model="drawer" />
  </div>
</template>

<script>
import HeaderDesktop from "@/app/components/new-header/desktop/Index";
import HeaderMobile from "@/app/components/new-header/mobile/Index";
import HEADER_HEIGHTS from "@/app/schema/header/HEADER_HEIGHTS";
import Navigation from "@/app/components/new-header/mobile/Navigation";
import { mapGetters } from "vuex";
export default {
  name: "Header",
  components: {
    SystemBar: () => import("@/app/components/SystemBar.vue"),
    Navigation,
    HeaderDesktop,
    HeaderMobile,
  },
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    ...mapGetters({
      isActiveSystemBar: "systemBar/isActive",
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    height() {
      let headerHeight = this.isMobile
        ? HEADER_HEIGHTS.mobile
        : HEADER_HEIGHTS.desktop;
      return this.isActiveSystemBar ? headerHeight + 35 : headerHeight;
    },
    gradientHeight() {
      return this.isMobile
        ? HEADER_HEIGHTS.mobileGradient - 35
        : HEADER_HEIGHTS.desktopGradient;
    },
  },
};
</script>

<style scoped>
.header-gradient {
  background: linear-gradient(
    180deg,
    #dff3fe 0%,
    #dff3fe 35%,
    rgba(223, 243, 254, 0) 100%
  ) !important;
  z-index: 0 !important;
}
</style>
<style>
.header-app-bar > .v-toolbar__content {
  padding: 0 !important;
}
.mt-header {
  margin-top: 35px;
}
</style>
