// initial state
import storageService from "@/services/LocalStorageService.service";

const state = () => ({
  items: storageService.get("label-slugs") || [],
  expireTime: storageService.get("labels-expire-time"),
});

// getters
export const getters = {
  items: (state) => state.items,
  expireTime: (state) => new Date(state.expireTime).getTime(),
  isExpire: (state) =>
    !state.expireTime ||
    new Date(state.expireTime).getTime() < new Date().getTime(),
  isSlugExists: (state) => (title) => state.items.includes(title),
};

// mutations
export const mutations = {
  setItems(state, value) {
    let items = value.map((item) => item.slug);
    state.items = items;
    storageService.set("label-slugs", items);
  },
  setExpireTime(state) {
    let expireTime = new Date(
      new Date().getTime() + 7 * 24 * 60 * 60 * 1000
    ).getTime();
    state.expireTime = expireTime;
    storageService.set("labels-expire-time", expireTime);
  },
};

// actions
export const actions = {
  saveItems({ commit }, data) {
    commit("setItems", data);
  },
  saveExpireTime({ commit }) {
    commit("setExpireTime");
  },
};

export default {
  namespaced: true,
  name: "labelSlugs",
  state,
  getters,
  actions,
  mutations,
};
