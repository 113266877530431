<template>
  <div>
    <div class="font-weight-bold mb-3">راهنمای خرید</div>
    <div v-for="link in links" :key="link.title" class="mt-2">
      <router-link :to="link.to" class="text-decoration-none more--text">
        {{ link.title }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "BuyInfo",
  data() {
    return {
      links: [
        { to: { name: "faq" }, title: "سوالات متداول" },
        {
          to: { name: "payment-methods-guide" },
          title: "راهنمای پرداخت و ارسال سفارش",
        },
        {
          to: { name: "order-canceling-guide" },
          title: "راهنمای لغو و مرجوعی سفارش",
        },
        {
          to: { name: "installment-purchase-guide" },
          title: "راهنمای خرید اقساطی",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
