import api from "@/services/ApiService.service";
import urls from "@/helpers/api_route_manager.helper";

/**
 *  get product
 *  ------------------------
 *  Api address| shop/product-detail/:productTitle
 *  ------------------------
 *  Method| GET
 *  ------------------------
 *  Description| get product by title request
 *  ------------------------
 *  @param productTitle {String}
 *  ------------------------
 *  @return  type {Promise}
 *  ------------------------
 **/
export const fetchProductByTitleAxiosRequest = function (productTitle) {
  return api.get(urls.api("product_title", [productTitle]));
};

export const fetchProductByLpCodeAxiosRequest = function (productTitle) {
  return api.get(urls.api("product_detail_by_lp_code", [productTitle]));
};

export const postAddToCartAxiosRequest = function (cartId, saleId) {
  return api.post(urls.api("product_add_to_cart", [cartId]), null, {
    params: { saleId },
  });
};

export const fetchRelationsByProductAxiosRequest = function (productTitle) {
  return api.get(urls.api("product_relations", [productTitle]));
};
