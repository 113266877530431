// initial state

const state = () => ({});

// getters
export const getters = {};

// mutations
export const mutations = {};

// actions
export const actions = {};

export default {
  namespaced: true,
  name: "order",
  state,
  getters,
  actions,
  mutations,
};
