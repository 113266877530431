module.exports = {
  key: "authentication",
  authentication: {
    components: {
      main: {
        container: {
          title: "Lipak Shop",
        },
        auth_alert_model: {
          status: {
            success: "Success",
            error: "Failed",
          },
          form: {
            buttons: {
              success_btn: "go to login",
              error_btn: "close",
            },
          },
        },
      },
      wide_screen_header_title: {
        title: "Lipak Shop",
      },
      auth: {
        auth: {
          title: "Lipak Shop",
          form: {
            buttons: {
              submit: "Login",
            },
          },
        },
        privacy_rules: {
          text1: "text1",
          link_rules: "link rules",
          link_privacy: "link privacy",
          text2: "text2",
          text3: "text3",
        },
      },
      otp: {
        otp_validator: {
          title: "",
          subtitle: "",
          form: {
            buttons: {
              submit: "",
            },
          },
        },
        otp_resend_link: {
          resend_link: "",
        },
      },
    },
    views: {},
    route_titles: {
      index: "login",
      description: "lipak login page",
    },
  },
  attribute: {},
  placeholder: {},
};
