export default {
  /*Address Routes********************/
  addresses: "user/address",
  address: "user/address/%s",
  province: "contents/provinces",
  province_cities: "contents/provinces/%s",

  /*Legal Routes********************/
  legal: "user/legal/%s",
  legals: "user/legal",

  /*Cart Routes********************/
  init_cart: "order/card/card-init",
  get_cart: "order/card/card-init/%s",
  validate_cart: "order/card/card-validate/%s",

  change_cart_item_count: "order/card/change-item/%s",
  delete_cart_item_count: "order/card/delete-item/%s",

  /*Shipping Routes********************/

  get_shipping_methods: "order/card/second-state/all-shipping/%s",
  get_shipping_methods_couriers:
    "order/card/second-state/all-shipping/shift/%s",
  update_order_shipping: "order/card/second-state/address/%s",
  register_order: "order/card/second-state/register",
  shipping_init: "order/card/delete-address/%s",

  /*Discount Routes********************/
  order_discount: "order/card/applyDiscount/%s",

  /*Payment Routes********************/
  payment_verify: "payment/verify",
  payment_init: "payment/initiate",
  payment_in_place: "payment/inplace/%s",
  get_payment_token: "payment/token",
  payment_purchase: "payment/redirectToPurchase/%s",
  payment_online_request: "payment/requestOnlinePayment",
  get_payment_method_shipments:
    "orderSetting/getPaymentMethodShipments?paymentMethod=INPLACE",
  get_payment_gateway_status:
    "orderSetting/getPaymentGatewayStatus?paymentMethod=ONLINE",
  get_payment_config_setting: "orderSetting/getCustomerPaymentDetail",

  //
  validate_otp_installments_payment: "payment/verify",
  request_to_send_otp_for_order_installments_payment:
    "payment/installment/requestCartBaloanOtp/%s",
  // request_to_resend_otp_for_order_installments_payment:
  //   "payment/installment/requestCartBaloanOtp/%s",
  post_invoice_registration: "payment/installment/storeInvoice/%s",

  cart_check_for_use_baloan: "payment/installment/checkBaloanCredit/%s",
};
