import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import fa from "vuetify/lib/locale/fa";
import en from "vuetify/lib/locale/en";
import theme from "@/app/plugins/colors/theme";
import "@/styles/main.scss";

/* for production mode only */
/* This option suppresses all Vuetify logs and warnings. */
//Vuetify.config.silent = true

Vue.use(Vuetify);
import "@mdi/font/css/materialdesignicons.css";
export default new Vuetify({
  /* theme color config */
  theme: theme,

  /* direction config */
  rtl: true,

  /* locale config */
  lang: {
    locales: { fa, en },
    current: "fa",
  },
  icons: {
    iconfont: "mdi",
  },
});
