<template>
  <span
    class="font-weight-bold cursor-pointer user-select-none"
    @click="$vuetify.goTo(0)"
  >
    <v-icon :color="color">mdi-arrow-up-circle-outline</v-icon>
    بازگشت به بالا
  </span>
</template>

<script>
export default {
  name: "GoToTop",
  props: {
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
