// initial state

const state = () => ({
  viewCart: {},
  beginCheckout: localStorage.getItem("begin_checkout") || [],
});

// getters
export const getters = {};

// mutations
export const mutations = {
  mutateViewCart(state, payload) {
    if (!window.dataLayer) return;
    state.viewCart = payload;
    window.dataLayer.push(payload);
  },

  mutateDataToCart(state, payload) {
    if (!window.dataLayer) return;
    window.dataLayer.push(payload);
  },

  mutateBeginCheckout(state, payload) {
    if (!window.dataLayer) return;

    //save in local for use in purchase action
    localStorage.setItem("begin_checkout", JSON.stringify(payload));
    window.dataLayer.push(payload);
  },

  mutateDeleteAction(state, payload) {
    if (!window.dataLayer) return;
    window.dataLayer.push(payload);
  },

  mutatePurchase(state) {
    if (!window.dataLayer) return;
    let data = JSON.parse(state.beginCheckout, "beginCheckout");
    data.event = "purchase";
    delete data.ecommerce.coupon;
    window.dataLayer.push(data);
  },

  mutateViewItem(state, payload) {
    if (!window.dataLayer) return;
    let data = {
      event: "view_item",
      ecommerce: {
        value: Number(
          (payload.currentLpv.salePrice / 10).toString().replace(".1", "")
        ),
        currency: "TRY",
        items: [
          {
            item_id: payload.product.lpCode,
            item_name: payload.product.title,
            item_brand: payload.product.brand.slug,
            discount: Number(
              (
                (payload.currentLpv.basePrice - payload.currentLpv.salePrice) /
                10
              )
                .toString()
                .replace(".1", "")
            ),
            item_category: payload.product.productGroup.slug,
            item_variant: payload.currentLpv.colorName,

            price: Number(
              (payload.currentLpv.basePrice / 10).toString().replace(".1", "")
            ),
            quantity: 1,
            index: 0,
          },
        ],
      },
    };
    window.dataLayer.push(data);
  },

  mutateClearDataLayer() {
    localStorage.removeItem("begin_checkout");
  },

  mutateUpdateQuantityInViewCart(state, payload) {
    if (!window.dataLayer) return;

    ///update view-cart
    let viewCart = state.viewCart;
    let itemIndexFoundInViewCart = viewCart.ecommerce.items.findIndex(
      (item) => item.item_id === payload.lpCode
    );
    if (itemIndexFoundInViewCart >= 0) {
      viewCart.ecommerce.items[itemIndexFoundInViewCart].quantity =
        payload.newCount;
      window.dataLayer.push(viewCart);
    }
  },

  mutateDeleteItemFromViewCart(state, payload) {
    if (!window.dataLayer) return;

    let viewCart = { ...state.viewCart };

    let itemIndexFoundInViewCart = viewCart.ecommerce.items.findIndex(
      (item) => item.item_id === payload.ecommerce.items[0].item_id
    );
    if (itemIndexFoundInViewCart >= 0) {
      viewCart.ecommerce.items.splice(itemIndexFoundInViewCart, 1);
      window.dataLayer.push(viewCart);
    }
  },
};

// actions
export const actions = {
  setViewCart({ commit }, payload) {
    commit("mutateViewCart", payload);
  },

  setDataToCart({ commit }, payload) {
    commit("mutateDataToCart", payload);
  },
  setBeginCheckout({ commit }, payload) {
    commit("mutateBeginCheckout", payload);
  },
  setDeleteAction({ commit }, payload) {
    commit("mutateDeleteAction", payload);
    commit("mutateDeleteItemFromViewCart", payload);
  },
  setPurchaseAction({ commit }) {
    commit("mutatePurchase");
  },
  setViewItem({ commit }, payload) {
    commit("mutateViewItem", payload);
  },
  clearDataLayer({ commit }) {
    commit("mutateClearDataLayer");
  },
  updateQuantityInViewCart({ commit }, payload) {
    commit("mutateUpdateQuantityInViewCart", payload);
  },
};

export default {
  namespaced: true,
  name: "data_layer",
  state,
  getters,
  actions,
  mutations,
};
