const state = () => ({
  surveyList: [],
  orderProducts: [],
  productFeedbackItems: [],
});
export const getters = {
  surveyList: (state) => state.surveyList,
  orderProducts: (state) => state.orderProducts,
  productFeedbackItems: (state) => state.productFeedbackItems,
};
export const mutations = {
  mutateSurveyList(state, payload) {
    state.surveyList = payload;
  },
  mutateOrderProducts(state, payload) {
    state.orderProducts = payload;
  },
  mutateProductFeedbackItems(state, payload) {
    state.productFeedbackItems = payload;
  },
};
export const actions = {
  setSurveyList({ commit }, payload) {
    commit("mutateSurveyList", payload);
  },
  setOrderProducts({ commit }, payload) {
    commit("mutateOrderProducts", payload);
  },
  setProductFeedbackItems({ commit }, payload) {
    commit("mutateProductFeedbackItems", payload);
  },
};
export default {
  name: "user_comments",
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
