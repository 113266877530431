import axios from "axios";
import handler from "@/helpers/handler.helper";
import auth from "@/services/AuthService.service";
import lang from "@/helpers/lang.helper";
import store from "@/app/store/index";

const api = axios.create({});
// api.defaults.withCredentials = false;

// add token to header
api.interceptors.request.use(
  async (request) => {
    if (!request.options || request.options.loading)
      await store.dispatch("loading/start");
    if (lang.locale())
      request.headers.common["Accept-Language"] = lang.locale();

    //Auth token if token exist,set it on header request
    let token = auth.getToken();
    if (token) request.headers.common["Authentication"] = `${token}`;
    return request;
  },
  async (error) => {
    await store.dispatch("loading/finish");
    return Promise.reject(error);
  }
);

// response and error handler
api.interceptors.response.use(
  async (response) => {
    await store.dispatch("loading/finish");
    return handler.response(response);
  },
  async (error) => {
    await store.dispatch("loading/finish");
    return handler.error(error);
  }
);

export default api;
