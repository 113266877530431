// Handler
// handle server or connection errors

import urls from "@/helpers/api_route_manager.helper";
import store from "@/app/store";
import router from "@/app/router";
// import { MessageService } from "@/services/MessageService.service";

const handler = {
  // handle errors
  error(result) {
    try {
      let error = new ServerError(
        result.response.status,
        result.response.data.message,
        result.response.data.error
      );
      if (error.status === 401) {
        store.dispatch("authentication/logout").then(() => {
          let query = {};
          if (router.currentRoute.name === "dashboard.account-information")
            query = { redirect: "profile" };
          else if (router.currentRoute.name === "order.cart")
            query = { redirect: "order" };
          // MessageService.error("UNAUTHORIZED");
          if (query.redirect) {
            router
              .push({ name: "authentication", query: { ...query } })
              .then(() => {});
          }
        });
      }
      return Promise.reject({
        error: error,
        header: result.response.headers,
      });
    } catch (e) {
      return Promise.reject({
        error: e,
        header: result.response.headers,
      });
    }
  },

  // handle response
  response(result) {
    try {
      let request_url = result.config.url;
      if (request_url.includes(urls.url("base_url")))
        return {
          headers: result.headers,
          data: result.data,
        };
      return Promise.reject(result);
    } catch (e) {
      return Promise.reject(result);
    }
  },
};

class ServerError {
  status;
  message;
  errors;
  validationErrors;
  constructor(status, message, errors, validationErrors = []) {
    try {
      this.status = status;
      this.message = message;
      this.errors = errors;
      this.validationErrors = validationErrors;
    } catch (e) {
      this.status = null;
      this.message = null;
      this.errors = null;
      this.validationErrors = [];
    }
  }
}
export default handler;
