export default {
  /*OTP Routes********************/
  auth_init: "auth/init",
  validate_otp: "auth/otp/validate",
  user_resend_otp: "auth/otp/resend",
  user_send_otp: "auth/otp/send",
  user_forget_password: "auth/forget-password",
  user_reset_password: "auth/reset-password",

  /*Auth Routes********************/
  user_refresh_token: "user/refreshToken",
  user_register: "user/register",
  user_login: "auth/login",
  user_change_password: "user/changePassword",
  user_info: "user/fetchUser",
  log_out_user: "auth/logout",
};
