<template>
  <v-app class="ss02">
    <Header />
    <v-main
      :style="{
        paddingTop: `${paddingTop}px`,
      }"
    >
      <router-view style="min-height: 80vh" />
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import Footer from "@/app/components/new-footer/Index";
import Header from "@/app/components/new-header/Index";
import HEADER_HEIGHTS from "@/app/schema/header/HEADER_HEIGHTS";
import { mapGetters } from "vuex";
import { getSystemBar } from "@/app/config/actions/systemBar.action";

export default {
  components: {
    Header,
    Footer,
  },
  computed: {
    ...mapGetters({
      isActiveSystemBar: "systemBar/isActive",
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    paddingTop() {
      let paddingTop = this.isMobile
        ? HEADER_HEIGHTS.mobile
        : HEADER_HEIGHTS.desktop;
      return this.isActiveSystemBar ? paddingTop + 35 : paddingTop;
    },
  },
  async created() {
    await getSystemBar();
  },
};
</script>
