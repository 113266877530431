<template>
  <v-app>
    <v-main style="min-height: 100vh" class="white">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  async created() {},
};
</script>
