import store from "@/app/store/index";
import auth from "@/services/AuthService.service";
import { snakeToPascal } from "@/helpers/utilities.helper";

export const setAuthDataToStore = async function () {
  const authData = {
    init_token: auth ? auth.getInitToken() : null,
    otp_token: auth ? auth.getOtpToken() : null,
    token: auth ? auth.getToken() : null,
    phone: auth ? auth.getPhone() : null,
    is_registered: auth ? auth.getIsRegistered() : 0, //0 -> registered, 1 -> not registered
    refresh_token: auth ? auth.getRefreshToken() : null,
    expiration: auth ? auth.getExpiration() : null,
  };
  for (const [key, value] of Object.entries(authData)) {
    await store.dispatch(`authentication/save${snakeToPascal(key)}`, value);
  }
  return Promise.resolve();
};
