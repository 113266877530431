"use strict";

export default [
  {
    path: "/user-comments/:id",
    name: "user-comments",
    component: () =>
      import(
        /* webpackChunkName: "user-comments-pkg" */ "@/packages/user-comments/presentation/views/Index"
      ),
    meta: {
      title: "تظرات کاربر",
    },
  },
];
