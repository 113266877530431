module.exports = {
  key: "authentication",
  authentication: {
    components: {
      main: {
        container: {
          title: "فروشگاه لیپک",
        },
        auth_alert_model: {
          status: {
            success: "موفق",
            error: "ناموفق",
          },
          form: {
            buttons: {
              success_btn: "ورود به حساب کاربری",
              error_btn: "متوجه شدم",
            },
          },
        },
      },
      wide_screen_header_title: {
        title: "فروشگاه لیپک",
      },
      auth: {
        privacy_rules: {
          text1: " با ثبت نام و ورود به لیپک شما",
          link_rules: "قوانین و مقررات",
          link_privacy: "قوانین حریم خصوصی",
          text2: " استفاده از لیپک و",
          text3: " آن را می پذیرید.",
        },
        auth: {
          title: "ورود یا ثبت نام",
          form: {
            buttons: {
              submit: "ورود به لیپک",
            },
          },
        },
      },
      otp: {
        otp_validator: {
          title: " کد تایید را وارد کنید.",
          subtitle:
            "   حساب کاربری با شماره {phone} وجود ندارد. برای ثبت نام کد تایید ارسالی را وارد کنید.",
          form: {
            buttons: {
              submit: "ثبت نام",
            },
          },
        },
        otp_resend_link: {
          resend_link: "ارسال مجدد کد",
        },
      },
      login: {
        login_with_otp: {
          title: "برای ورود کد تایید را وارد نمایید.",
          form: {
            buttons: {
              submit: "ورود",
              otp_btn: "ورود با رمز عبور",
            },
          },
        },
        login_with_password: {
          title: "رمز عبور خود را وارد کنید.",
          form: {
            buttons: {
              submit: "ادامه",
              otp_btn: "ورود با رمز یکبار مصرف",
            },
          },
        },
      },
      forget_password: {
        forget_password: {
          title: "بازیابی رمز عبور",
          subtitle:
            "کد تایید برای شماره {phone} ارسال شده است. کد را وارد کنید.",
          form: {
            buttons: {
              submit: "ارسال کد تایید",
            },
          },
        },
        reset_password: {
          title: "بازیابی رمز عبور",
          form: {
            buttons: {
              submit: "تغییر رمز عبور",
            },
          },
        },
      },
    },
    views: {},
    route_titles: {
      index: "ورود",
      description: "صفحه ورود لیپک",
      login_with_password: {
        title: "ورود با رمز عبور",
        description: "ورود با رمز عبور",
      },
      login_with_otp: {
        title: "ورود با کد یکبار مصرف",
        description: "ورود با کد یکبار مصرف",
      },
      otp_validator: {
        title: "احراز هویت",
        description: "تایید کد یک بار مصرف",
      },
      create_password: {
        title: "تعین رمز عبور",
        description: "تعیین رمز عبور",
      },
    },
  },
  attribute: {},
  placeholder: {},
};
