<template>
  <v-container class="px-4 px-lg-16 pt-0" fluid>
    <v-row class="align-center">
      <v-col cols="4">
        <v-app-bar-nav-icon
          class="secondary--text"
          @click.stop="$emit('input', true)"
        />
      </v-col>

      <v-col cols="4">
        <router-link :to="{ name: 'home' }" class="mx-auto">
          <v-img width="180px" :src="logoSrc" contain alt="lipak" />
        </router-link>
      </v-col>

      <v-col cols="4" class="text-left">
        <router-link
          class="text-decoration-none ml-3"
          :to="{ name: 'order.cart' }"
        >
          <span v-if="cartItemsCount" class="font-weight-bold ss02">
            {{ cartItemsCount }}
          </span>
          <v-icon color="secondary">mdi-cart-outline</v-icon>
        </router-link>
        <span
          class="text-decoration-none"
          @click="
            is_authenticated ? toggleProfileDrawer() : goToAuthentication()
          "
        >
          <v-icon color="secondary">mdi-account-outline</v-icon>
        </span>
      </v-col>
    </v-row>
    <SearchBox />
  </v-container>
</template>

<script>
import logoSrc from "@/app/assets/images/logo/logo.webp";
import { mapActions, mapGetters } from "vuex";
import SearchBox from "@/app/components/SearchBox";
export default {
  name: "HeaderMobile",
  components: { SearchBox },
  data() {
    return {
      logoSrc,
    };
  },
  computed: {
    ...mapGetters({
      is_authenticated: "authentication/check",
      cartItemsCount: "cart/count",
      drawerStatus: "profile/drawer",
    }),
  },
  methods: {
    ...mapActions({
      toggleDrawerStatus: "profile/setDrawer",
    }),
    async toggleProfileDrawer() {
      // TODO: after changing routes' name, fullPath should change to name
      if (!this.$route.fullPath.startsWith("/profile")) {
        await this.$router.push({
          name: "profile",
        });
        await this.toggleDrawerStatus(true);
      } else {
        window.scrollTo(0, 0);
        this.toggleDrawerStatus(!this.drawerStatus);
      }
    },
    goToAuthentication() {
      this.$router.push({ name: "authentication" }).catch(() => {});
    },
  },
};
</script>

<style scoped></style>
