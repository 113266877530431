import { getMegaMenuAxiosRequest } from "@/app/config/repository/api/megaMenu.api";
import store from "@/app/store/index";

/**
 * get mega menu and save it in store
 * -----------------------
 * @param
 * -----------------------
 * Description
 * -----------------------
 * @return type {Promise}
 * */
export const getMegaMenu = async function () {
  try {
    const { data: items } = await getMegaMenuAxiosRequest();
    await store.dispatch("megaMenu/saveItems", items);
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
};
