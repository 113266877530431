module.exports = {
  key: "order",
  order: {
    components: {},
    views: {},
    route_titles: {
      index: "order",
      description: "shop order",
    },
  },
  attribute: {},
  placeholder: {},
};
