var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{attrs:{"fixed":"","temporary":"","width":"100%","tag":"nav"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-container',{staticStyle:{"padding-top":"56px"}},[_c('div',{staticClass:"d-flex align-center justify-space-between lipak-navigation-fixed-top elevation-2 px-2"},[_c('v-btn',{attrs:{"fab":"","color":"transparent","elevation":"0"},on:{"click":function($event){return _vm.$emit('input', false)}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('router-link',{attrs:{"to":{ name: 'home' }}},[_c('v-img',{attrs:{"height":"26px","width":"100%","src":_vm.logoSrc,"contain":""}})],1)],1),_c('v-expansion-panels',{staticClass:"mt-3"},_vm._l((_vm.items),function(item){return _c('v-expansion-panel',{key:item.productGroupId,staticClass:"mt-2 rounded elevation-0 user-select-none",staticStyle:{"background":"transparent"}},[_c('v-expansion-panel-header',{staticClass:"rounded py-5",staticStyle:{"min-height":"auto"},attrs:{"color":"white","hide-actions":""},scopedSlots:_vm._u([{key:"default",fn:function({ open }){return [_c('v-row',{staticClass:"justify-space-between align-center"},[_c('div',{staticClass:"font-weight-bold text-body-1"},[_vm._v(_vm._s(item.title))]),(item.mobileImage)?_c('v-img',{staticClass:"mega-menu-header-image",attrs:{"contain":"","max-width":open ? 43 : 83,"src":_vm.imageUrlBuilder(item.mobileImage),"alt":item.mobileImageAlt},on:{"click":function($event){return _vm.goToCta(item.cta)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-sheet',{attrs:{"height":"100%","color":"white"}},[_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","aria-label":"progress"}})],1)],1)]},proxy:true}],null,true)}):_vm._e()],1)]}}],null,true)}),_c('v-expansion-panel-content',{class:{
            'rounded mt-2': !_vm.hasExists(item.children),
            'mega-menu-expandable-child-px-0': _vm.hasExists(item.children),
          },attrs:{"color":_vm.hasExists(item.children) ? 'transparent' : 'white'},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                name: 'products.category',
                params: { cat_title: item.slug },
                // query: { catId: item.productGroupId },
              }}},[_vm._v(" مشاهده همه محصولات ")]),_c('div',[_c('div',{staticClass:"mt-4"},[(_vm.hasExists(item.brands))?_c('div',[_c('div',{staticClass:"mega-menu-item-option-title ps-3 my-5 font-weight-medium text-body-1"},[_vm._v(" "+_vm._s(_vm.$_t("components.header.mega_menu.brand_title", { title: item.title, }))+" ")]),_c('v-chip-group',{attrs:{"column":""}},_vm._l((item.brands),function(brand){return _c('v-chip',{key:brand.id,attrs:{"to":{
                        name: 'products.label-brand',
                        params: {
                          cat_title: item.slug,
                          title: brand.slug,
                        },
                        // query: {
                        //   catId: item.productGroupId,
                        //   brandId: brand.id,
                        // },
                      }}},[_vm._v(" "+_vm._s(item.title)+" "+_vm._s(brand.title)+" ")])}),1)],1):_vm._e(),(_vm.hasExists(item.labels))?_c('div',{class:{ 'mt-4': _vm.hasExists(item.brands) }},[_c('div',{staticClass:"mega-menu-item-option-title ps-3 mb-5 font-weight-medium text-body-1"},[_vm._v(" "+_vm._s(_vm.$_t("components.header.mega_menu.label_title", { title: item.title, }))+" ")]),_c('v-chip-group',{attrs:{"column":""}},_vm._l((item.labels),function(label){return _c('v-chip',{key:label.id,attrs:{"to":{
                        name: 'products.label-brand',
                        params: { cat_title: item.slug, title: label.slug },
                        // query: {
                        //   catId: item.productGroupId,
                        //   labelId: label.id,
                        // },
                      }}},[_vm._v(" "+_vm._s(label.title)+" ")])}),1)],1):_vm._e()]),_c('v-expansion-panels',_vm._l((item.children),function(child){return _c('v-expansion-panel',{key:child.productGroupId,staticClass:"mt-2 rounded elevation-0 user-select-none",staticStyle:{"background":"transparent"}},[_c('v-expansion-panel-header',{staticClass:"rounded py-5",staticStyle:{"min-height":"auto"},attrs:{"color":"white","hide-actions":""},scopedSlots:_vm._u([{key:"default",fn:function({ open: childOpen }){return [_c('v-row',{staticClass:"justify-space-between align-center"},[_c('div',{staticClass:"font-weight-bold text-body-1"},[_vm._v(" "+_vm._s(child.title)+" ")]),_c('v-fab-transition',[_c('v-icon',{key:`${child.productGroupId}-${
                              childOpen ? 'minus' : 'plus'
                            }`,attrs:{"color":"secondary"}},[_vm._v("mdi-"+_vm._s(childOpen ? "minus" : "plus"))])],1)],1)]}}],null,true)}),_c('v-expansion-panel-content',{staticClass:"rounded mt-2",attrs:{"color":"white"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"mt-4"},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                            name: 'products.category',
                            params: { cat_title: child.slug },
                            // query: { catId: child.productGroupId },
                          }}},[_vm._v(" مشاهده همه محصولات ")]),(_vm.hasExists(child.brands))?_c('div',[_c('div',{staticClass:"mega-menu-item-option-title ps-3 mb-5 font-weight-medium text-body-1 mt-3"},[_vm._v(" "+_vm._s(_vm.$_t("components.header.mega_menu.brand_title", { title: child.title, }))+" ")]),_c('v-chip-group',{attrs:{"column":""}},_vm._l((child.brands),function(brand){return _c('v-chip',{key:brand.id,attrs:{"to":{
                                name: 'products.label-brand',
                                params: {
                                  cat_title: child.slug,
                                  title: brand.slug,
                                },
                                // query: {
                                //   catId: child.productGroupId,
                                //   brandId: brand.id,
                                // },
                              }}},[_vm._v(" "+_vm._s(child.title)+" "+_vm._s(brand.title)+" ")])}),1)],1):_vm._e(),(_vm.hasExists(child.labels))?_c('div',{class:{ 'mt-4': _vm.hasExists(child.brands) }},[_c('div',{staticClass:"mega-menu-item-option-title ps-3 mb-5 font-weight-medium text-body-1"},[_vm._v(" "+_vm._s(_vm.$_t("components.header.mega_menu.label_title", { title: child.title, }))+" ")]),_c('v-chip-group',{attrs:{"column":""}},_vm._l((child.labels),function(label){return _c('v-chip',{key:label.id,attrs:{"to":{
                                name: 'products.label-brand',
                                params: {
                                  cat_title: item.slug,
                                  title: label.slug,
                                },
                                // query: {
                                //   catId: child.productGroupId,
                                //   labelId: label.id,
                                // },
                              }}},[_vm._v(" "+_vm._s(label.title)+" ")])}),1)],1):_vm._e()],1)]},proxy:true}],null,true)})],1)}),1)],1)]},proxy:true}],null,true)})],1)}),1),_c('v-list-item',[_c('a',{staticClass:"py-2 px-2 ms-n2 secondary--text font-weight-bold text-decoration-none",attrs:{"href":"https://mag.lipak.com/","target":"_blank"}},[_vm._v(" مجله لیپک ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }