<template>
  <v-expand-transition>
    <div v-if="showSpecials">
      <v-fade-transition>
        <v-container
          v-if="isLoading"
          key="loader"
          fluid
          class="px-4 px-lg-16 pt-5"
        >
          <v-skeleton-loader type="image@2" />
        </v-container>
        <div v-else key="special">
          <SpecialMobile v-if="isMobile" :data="specials" />
          <SpecialDesktop v-else :data="specials" />
        </div>
      </v-fade-transition>
    </div>
  </v-expand-transition>
</template>

<script>
import SpecialMobile from "@/packages/home/components/special/mobile/Index";
import SpecialDesktop from "@/packages/home/components/special/desktop/Index";
import { getSpecialProductsAxiosRequest } from "@/packages/home/api/main.api";
import { mapGetters } from "vuex";
import store from "../../../../app/store";
export default {
  name: "Special",
  components: { SpecialDesktop, SpecialMobile },
  data() {
    return {
      isLoading: false,
      hasError: false,
      // specialData: null,
    };
  },
  computed: {
    ...mapGetters({
      specials: "home/specials",
    }),
    showSpecials() {
      return !(this.hasError || (this.specials && !this.specials.length));
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  created() {
    if (this.specials.length) return;
    this.getSpecials();
  },
  methods: {
    async getSpecials() {
      this.isLoading = true;
      this.hasError = false;
      try {
        // const {
        //   data: {
        //     productPage: { content },
        //   },
        // } = await getSpecialProductsAxiosRequest({
        //   pageNumber: 0,
        //   pageSize: 12,
        //   specials: ["special", "available"],
        // });
        // this.specialData = content;
        let { data } = await getSpecialProductsAxiosRequest({
          pageNumber: 0,
          pageSize: 12,
        });
        await store.dispatch("home/setSpecials", data.content);
        // this.specialData = data.content;
      } catch (err) {
        console.log(err);
        this.hasError = true;
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped></style>
