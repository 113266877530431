export default {
  install(Vue) {
    // an instance method
    Vue.prototype.$_currency = function (
      number,
      local = "ir-IR",
      currency = "IRR"
    ) {
      let formatted = new Intl.NumberFormat(`${local}`, {
        style: "currency",
        currency: `${currency}`,
      })
        .format(number)
        .replace("IRR", "");
      if (currency === "IRR") {
        formatted = formatted.replace(".00", "");
        formatted = formatted.replace("ریال", "");
      }
      return formatted;
    };
  },
};
