var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showCategories)?_c('v-container',{staticClass:"px-4 px-lg-16 white",attrs:{"fluid":""}},[(_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"image@2"}}):_c('v-card',{staticClass:"pa-3",attrs:{"outlined":""}},[_c('CategorySelection',{attrs:{"next":_vm.nextCat,"prev":_vm.prevCat,"categories":_vm.categories},on:{"select-cat":_vm.selectCat}}),_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('v-row',{directives:[{name:"touch",rawName:"v-touch",value:({
          left: () => _vm.swipe('left'),
          right: () => _vm.swipe('right'),
        }),expression:"{\n          left: () => swipe('left'),\n          right: () => swipe('right'),\n        }"}],key:_vm.selectedCatId,staticClass:"mt-3"},_vm._l((_vm.productsToShow),function(product){return _c('v-col',{key:product.id,attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('CategoryProduct',{attrs:{"item":product}})],1)}),1)],1),_c('div',{staticClass:"text-left"},[_c('v-btn',{staticClass:"error lighten-5 mt-5",staticStyle:{"border-color":"var(--v-primary-base) !important"},attrs:{"outlined":"","block":_vm.isMobile,"color":"primary","to":{
          name: 'products.category',
          params: {
            cat_title: _vm.selectedCatId,
          },
          query: { specials: ['available'] },
        }}},[_vm._v(" مشاهده همه "),_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-arrow-left")])],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }