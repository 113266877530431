export default {
  // /*Slider Routes********************/
  sliders: "marketing/banners",
  //
  // /*top banner Routes********************/
  // top_banner: "marketing/banners",
  //
  /*Category Banner Routes********************/
  main_page_category: "users/category",

  /*day offer Routes********************/
  day_offer: "main-page/day-offer",

  // /*suggest banner Routes********************/
  // suggest_banners: "marketing/banners",

  /*product in special sale Routes********************/
  main_page_products: "products-page",
  main_page_products_v2: "product",
  main_page_special: "main-page/special-offer",
  // /*mobile product Routes********************/
  // mobile_products: "main-page/product-group/%s",
  //
  /*products by product group Routes********************/
  products_by_product_group: "main-page/product-group/%s",
  //
  // /*special banners Routes********************/
  // special_banners: "marketing/banners",
  //
  // /*laptop product Routes********************/
  // laptop_products: "shop/main-page/laptops",
  //
  // /*bottom banner Routes********************/
  // bottom_banner: "marketing/banners",
};
