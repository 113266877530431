const track_orders = [
  {
    path: "track-orders",
    name: "dashboard.track-orders",
    component: () =>
      import(
        /* webpackChunkName: "profile"  */ "@/packages/profile/presentation/components/dashboard-content/track-orders/Index"
      ),
    redirect: { name: "track-orders.orders" },

    children: [
      {
        path: "orders",
        name: "track-orders.orders",
        component: () =>
          import(
            /* webpackChunkName: "profile"  */ "@/packages/profile/presentation/components/dashboard-content/track-orders/orders/Orders"
          ),
        redirect: { name: "orders.INPAYMENT" },
        children: [
          {
            path: "INPAYMENT",
            name: "orders.INPAYMENT",
            component: () =>
              import(
                /* webpackChunkName: "profile" */ "@/packages/profile/presentation/components/dashboard-content/track-orders/orders/awaiting-payment/Index"
              ),
            meta: {
              order_type: "INPAYMENT",
            },
          },
          {
            path: "INPROCESS",
            name: "orders.INPROCESS",
            component: () =>
              import(
                /* webpackChunkName: "profile" */ "@/packages/profile/presentation/components/dashboard-content/track-orders/orders/processing/Index"
              ),
            meta: {
              order_type: "INPROCESS",
            },
          },
          {
            path: "DELIVERED",
            name: "orders.DELIVERED",
            component: () =>
              import(
                /* webpackChunkName: "profile" */ "@/packages/profile/presentation/components/dashboard-content/track-orders/orders/successful-order/Index"
              ),
            meta: {
              order_type: "DELIVERED",
            },
          },
          {
            path: "RETURNED",
            name: "orders.RETURNED",
            component: () =>
              import(
                /* webpackChunkName: "profile" */ "@/packages/profile/presentation/components/dashboard-content/track-orders/orders/order-returned/Index"
              ),
            meta: {
              order_type: "RETURNED",
            },
          },
          {
            path: "CANCELED",
            name: "orders.CANCELED",
            component: () =>
              import(
                /* webpackChunkName: "profile" */ "@/packages/profile/presentation/components/dashboard-content/track-orders/orders/order-canceled/Index"
              ),
            meta: {
              order_type: "CANCELED",
            },
          },
        ],
      },
      {
        path: "order-detail",
        name: "track-orders.order-detail",
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "@/packages/profile/presentation/components/dashboard-content/track-orders/order-detail/Index"
          ),
        children: [
          {
            path: "INPAYMENT/:id",
            name: "order-detail.INPAYMENT/:id",
            component: () =>
              import(
                /* webpackChunkName: "profile" */ "@/packages/profile/presentation/components/dashboard-content/track-orders/order-detail/ContainerDetail"
              ),
            meta: {
              dir: "inpayment",
            },
          },
          {
            path: "INPROCESS/:id",
            name: "order-detail.INPROCESS/:id",
            component: () =>
              import(
                /* webpackChunkName: "profile" */ "@/packages/profile/presentation/components/dashboard-content/track-orders/order-detail/ContainerDetail"
              ),
            meta: {
              dir: "inprocess",
            },
          },
          {
            path: "DELIVERED/:id",
            name: "order-detail.DELIVERED/:id",
            component: () =>
              import(
                /* webpackChunkName: "profile" */ "@/packages/profile/presentation/components/dashboard-content/track-orders/order-detail/ContainerDetail"
              ),
            meta: {
              dir: "delivered",
            },
          },
          {
            path: "RETURNED/:id",
            name: "order-detail.RETURNED/:id",
            component: () =>
              import(
                /* webpackChunkName: "profile" */ "@/packages/profile/presentation/components/dashboard-content/track-orders/order-detail/ContainerDetailForReturned"
              ),
            meta: {
              dir: "returned",
            },
          },
          {
            path: "CANCELED/:id",
            name: "order-detail.CANCELED/:id",
            component: () =>
              import(
                /* webpackChunkName: "profile" */ "@/packages/profile/presentation/components/dashboard-content/track-orders/order-detail/ContainerDetail"
              ),
            meta: {
              dir: "canceled",
            },
          },
        ],
      },
    ],
  },
];

export default track_orders;
