// initial state
import storageService from "@/services/LocalStorageService.service";
const key = "pagination-";
const state = () => ({
  is_reset_called: false,
  page: storageService.get(key + "page") ? storageService.get(key + "page") : 1,
  total_items: storageService.get(key + "total")
    ? storageService.get(key + "total")
    : 0,
  total_pages: storageService.get(key + "total_page")
    ? storageService.get(key + "total_page")
    : 0,
  size: process.env.VUE_APP_TOTAL_ITEMS_PER_PAGE || 12,
});

// getters
const getters = {
  current_page: (state) => state.page,
  size: (state) => state.size,
  total_items: (state) => state.total_items,
  total_pages: (state) => state.total_pages,
  is_reset_called: (state) => state.is_reset_called,
};

// mutations
const mutations = {
  setIsResetCalled(state, value) {
    state.is_reset_called = value;
  },
  setPage(state, value) {
    state.page = value;
    storageService.set(key + "page", value);
  },
  setTotal(state, value) {
    state.total_items = value;
    storageService.set(key + "total", value);
  },
  setTotalPage(state, value) {
    state.total_pages = value;
    storageService.set(key + "total_page", value);
  },
  setPageSize(state, value) {
    state.size = value;
  },
};

// actions
const actions = {
  setCurrentPage({ commit }, data) {
    commit("setIsResetCalled", false);
    commit("setPage", data);
  },
  nextPage({ commit, state }) {
    commit("setIsResetCalled", false);
    if (state.total > state.page) commit("setPage", ++state.page);
  },
  previousPage({ commit, state }) {
    commit("setIsResetCalled", false);
    if (state.page > 0) commit("setPage", --state.page);
  },
  setTotalItems({ commit }, data) {
    commit("setTotal", data);
  },
  setTotalIPages({ commit }, data) {
    commit("setTotalPage", data);
  },
  setSize({ commit }, data) {
    commit("setPageSize", data);
  },
  reset({ commit }) {
    commit("setIsResetCalled", true);
    commit("setPage", 1);
    commit("setTotal", 0);
    commit("setTotalPage", 0);
    commit("setPageSize", process.env.VUE_APP_TOTAL_ITEMS_PER_PAGE || 4);
  },
};

export default {
  namespaced: true,
  name: "pagination",
  state,
  getters,
  actions,
  mutations,
};
