import api from "@/services/ApiService.service";
import urls from "@/helpers/api_route_manager.helper";

/**
 *  verify cart for next order step
 *  ------------------------
 *  Api address| /order/card-validate/:id
 *  ------------------------
 *  Method| Get
 *  ------------------------
 *  Description| verify cart for next order step
 *  ------------------------
 *  @param cart_id {String}
 *  @param query_params {Object}
 *  ------------------------
 *  @return  type {Promise}
 *  ------------------------
 **/
export const verifyCartAxiosRequest = function (cart_id, query_params = null) {
  return api.get(urls.api("validate_cart", [`${cart_id}`]), {
    params: {
      ...query_params,
    },
  });
};
