"use strict";

export default [
  {
    path: "/campaign/",
    name: "campaign",
    component: () =>
      import(
        /* webpackChunkName: "campaign-pkg" */ "@/packages/campaign/presentation/views/Index"
      ),
    redirect: { name: "campaign.search", query: { q: "" } },

    children: [
      {
        path: ":slug/",
        name: "campaign.slug",
        component: () =>
          import(
            /* webpackChunkName: "campaign-pkg" */ "@/packages/campaign/presentation/views/CampaignWrapper.vue"
          ),
        meta: {
          title: "کمپین",
        },
      },
    ],
  },
];
