// initial state

const state = () => ({
  specialFilters: [],
  brandFilters: [],
  colorFilters: [],
  priceFilters: [],
  guaranteeFilters: [],
  price: null,
  otherFilters: [],
  selectedFilters: {},
  allFilters: {},
  specials: {
    amazing: "فقط شگفت انگیز",
    special: "فقط تخفیف دار",
    available: "فقظ کالاهای موجود",
  },
  campaign: {},
});

// getters
export const getters = {
  specials: (state) => state.specialFilters,
  brands: (state) => state.brandFilters,
  colors: (state) => state.colorFilters,
  colorsCount: (state) => state.colorFilters.length,
  prices: (state) => state.priceFilters,
  guarantees: (state) => state.guaranteeFilters,
  otherFilters: (state) => state.otherFilters,
  allFilters: (state) => state.allFilters,
  filters: (state) => {
    return {
      specials: state.specialFilters,
      brandId: state.brandFilters,
      colorId: state.colorFilters,
      priceStart: state.priceFilters[0],
      priceEnd: state.priceFilters[1],
      guarantee: state.guaranteeFilters,
    };
  },
  price: (state) => {
    state.price;
  },
  campaign: (state) => state.campaign,
};

// mutations
export const mutations = {
  mutateSpecialFilters(state, payload) {
    state.specialFilters = payload;
  },
  mutateBrandFilters(state, payload) {
    state.brandFilters = payload;
  },
  mutateColorFilters(state, payload) {
    state.colorFilters = payload;
  },
  mutatePriceFilters(state, payload) {
    state.priceFilters = payload;
  },
  mutateGuaranteeFilters(state, payload) {
    state.guaranteeFilters = payload;
  },
  mutatePriceFilter(state, payload) {
    state.price = payload;
  },
  mutateOtherFilters(state, payload) {
    state.otherFilters = payload;
  },
  mutateSelectedFilters(state, payload) {
    state.selectedFilters = payload;
  },
  mutateAllFilters(state, payload) {
    state.allFilters = payload.filters;
  },
  removeSpecialFiltersElement(state, payload) {
    let filters = [...state.specialFilters];
    let index = filters.indexOf(payload);
    if (index > -1) {
      filters.splice(index, 1);
      state.specialFilters = [...filters];
    }
  },
  removeBrandFiltersElement(state, payload) {
    let filters = [...state.brandFilters];
    let index = filters.indexOf(payload);
    if (index > -1) {
      filters.splice(index, 1);
      state.brandFilters = [...filters];
    }
  },
  removeGuaranteeFiltersElement(state, payload) {
    let filters = [...state.guaranteeFilters];
    let index = filters.indexOf(payload);
    if (index > -1) {
      filters.splice(index, 1);
      state.guaranteeFilters = [...filters];
    }
  },
  removeColorFiltersElement(state, payload) {
    let filters = [...state.colorFilters];
    let index = filters.indexOf(payload);
    if (index > -1) {
      filters.splice(index, 1);
      state.colorFilters = [...filters];
    }
  },
  removeOthersFiltersElement(state, payload) {
    let filters = [...state.otherFilters];
    let index = filters.indexOf(payload);
    if (index > -1) {
      filters.splice(index, 1);
      state.otherFilters = [...filters];
    }
  },
  resetSpecialFilters(state) {
    state.specialFilters = [];
  },
  resetBrandFilters(state) {
    state.brandFilters = [];
  },
  resetGuaranteeFilters(state) {
    state.guaranteeFilters = [];
  },
  resetColorFilters(state) {
    state.colorFilters = [];
  },
  resetOthersFilters(state) {
    state.otherFilters = [];
  },
  resetPriceFilters(state) {
    state.priceFilters = [];
  },
  mutateCampaign(state, payload) {
    state.campaign = payload;
  },
};

// actions
export const actions = {
  setPriceAce({ commit }) {
    commit("mutatePriceFilter", "price,asc");
  },
  setPriceDesc({ commit }) {
    commit("mutatePriceFilter", "price,desc");
  },
  setAllFilters({ commit }, data) {
    commit("mutateAllFilters", data);
  },
  resetFilters({ commit }) {
    commit("resetSpecialFilters");
    commit("resetBrandFilters");
    commit("resetGuaranteeFilters");
    commit("resetColorFilters");
    commit("resetPriceFilters");
    commit("resetOthersFilters");
  },
  setCampaign({ commit }, payload) {
    commit("mutateCampaign", payload);
  },
};

export default {
  namespaced: true,
  name: "campaign",
  state,
  getters,
  actions,
  mutations,
};
