import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./app/router";
import store from "./app/store";
import vuetify from "./app/plugins/vuetify";
import i18n from "./app/plugins/i18n";
import translator from "./app/plugins/translator";
import date_formatter from "./app/plugins/date-formatter";
import faToEn from "./app/plugins/convertFaNumberToEnNumber";
import currency_formatter from "./app/plugins/currency-formatter";
import Vuelidate from "vuelidate";
import vuelidateErrorExtractor, { templates } from "vuelidate-error-extractor";
Vue.use(Vuelidate);
import VueGtm from "vue-gtm";
Vue.use(vuelidateErrorExtractor, {
  /**
   * Optionally provide the template in the configuration.
   * or register like so Vue.component("FormField", templates.singleErrorExtractor.foundation6)
   */
  template: templates.singleErrorExtractor.foundation6,
  i18n: "validations", // Path to validation messages. Can be deeply.nested.property.
});
import VueHtmlToPaper from "@/app/plugins/html-to-paper";
Vue.use(VueHtmlToPaper);

import VueMask from "v-mask";
Vue.use(VueMask);

Vue.config.productionTip = false;

Vue.use(translator);
Vue.use(date_formatter);
Vue.use(faToEn);
Vue.use(currency_formatter);
Vue.use(VueGtm, {
  id: process.env.VUE_APP_BASE_URL.includes("lipak")
    ? process.env.VUE_APP_GTM_ID || "GTM-M8KFQKL"
    : process.env.VUE_APP_GTM_ID_FOR_DEVELOP || "GTM-KT22ZPK7",
  defer: true,
  enabled:
    process.env.VUE_APP_BASE_URL.includes("lipak") ||
    process.env.VUE_APP_BASE_URL.includes("zoft"),
  debug: false,
  loadScript: false,
  vueRouter: router,
  trackOnNextTick: false,
});
// import { siteMap } from "@/services/SiteMapGenerator.service";
// siteMap().then((r) => console.log("finish", r));
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
