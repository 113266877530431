const account_information = [
  {
    path: "account-information",
    name: "dashboard.account-information",
    component: () =>
      import(
        /* webpackChunkName: "profile" */ "@/packages/profile/presentation/components/dashboard-content/account-information/Index"
      ),
  },
];
export default account_information;
