export default {
  data() {
    return {
      base_url: process.env.VUE_APP_IMAGE_BASE_URL || "https://zoft.ir/",
    };
  },

  created() {},

  methods: {
    imageUrlBuilder(image_url) {
      return `${this.base_url}/${image_url}`;
    },
  },
};
