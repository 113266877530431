<template>
  <v-snackbar
    v-model="updateExists"
    color="#64B5F6"
    right
    dark
    class="text-right"
    content-class="text-right mx-2"
    multi-line
  >
    <div class="px-3 text-right">
      {{ $_t("UPDATE_AVAILABLE") }}
    </div>
    <div class="text-left" style="margin-top: 5px">
      <!--      <v-btn-->
      <!--        v-show="false"-->
      <!--        ref="update-btn"-->
      <!--        color="#1976D2"-->
      <!--        small-->
      <!--        class="mx-1"-->
      <!--        @click="refreshApp"-->
      <!--      >-->
      <!--        {{ $_t("UPDATE_BTN") }}-->
      <!--      </v-btn>-->
      <!--            <v-btn>-->
      <!--        outlined-->
      <!--        small-->
      <!--        class="mt-3 mx-2"-->
      <!--        @click="updateExists = false"-->
      <!--        style="margin-left: 5px !important"-->
      <!--      >-->
      <!--        {{ $_t("UPDATE_CLOSE") }}-->
      <!--      </v-btn>-->
    </div>
  </v-snackbar>
</template>

<script>
import update from "@/app/mixins/mixin.update";
export default {
  name: "LipakAppUpdater",
  mixins: [update],
  mounted() {
    this.refreshApp();
  },
};
</script>

<style scoped></style>
