module.exports = {
  key: "profile",
  profile: {
    components: {
      dashboard_sidebar: {
        user_info: {
          wallet_credit: "اعتبار کیف پول",
        },
        sidebar_tabs: {
          account_info: "اطلاعات حساب کاربری",
          track_orders: "پیگیری سفارش‌ها",
          comments: "نظرات",
          discount: "تخفیف‌ها",
          addresses: "لیست آدرس‌ها",
          wallet: "کیف پول",
          returned_request: "درخواست مرجوعی",
          popular_product: "کالاهای محبوب",
          change_password: "تغییر رمز عبور",
          exit: "خروج",
        },
      },
      dashboard: {
        dashboard_content: {
          account_information: {
            user_information: {
              personal_info: {
                user_info_item: { title: " مشخصات کاربر", edit: "ویرایش" },
              },
              bank_account_detail: {
                title: "مشخصات حساب بانکی",
                edit: "ویرایش",
              },
              add_legal_information: {
                legal_info: {
                  message_top:
                    "با تکمیل اطلاعات حقوقی , میتوانید اقدام به خرید سازمانی با دریافت فاکتور رسمی و گواهی ارزش افزوده کنید. ",
                },
              },
            },
          },
          track_orders: {
            awaiting_payment: {
              awaiting_payment_table: {
                row: "ردیف",
                order_code: "کد سفارش",
                order_date: "تاریخ سفارش",
                price: "مبلغ سفارش (تومان)",
                status: "وضعیت سفارش",
                statPayment: "وضعیت پرداخت",
                factor: "فاکتور",
                detail: "جز‌ئيات",
              },
            },
            processing: {
              processing_table: {
                row: "ردیف",
                order_code: "کد سفارش",
                returned_date: "تاریخ مرجوعی",
                returned_code: "کد مرجوعی",
                price: "مبلغ مرجوعی (تومان)",
                status: "وضعیت مرجوعی",
                detail: "جز‌ئيات",
              },
            },
            order_detail: {
              row: "ردیف",
              productImage: "تصویر کالا",
              product_name: "نام کالا",
              count: "تعداد",
              color: "رنگ",
              warrenty: "گارانتی",
              price: "جمع مبلغ(تومان)",
            },
          },
        },
        registration_returned: {
          tabel_header: {
            product_image: "تصویر کالا",
            specifications: "مشخصات کالا",
            returned_count: "تعداد مرجوعی",
            returned_reason: "دلیل مرجوعی",
            upload_image: "بارگذاری تصویر",
          },
        },
      },
    },
    views: {},
    route_titles: {
      index: "پروفایل",
    },
  },
  attribute: {
    Name: "نام",
    lastName: "نام خانوادگی",
    nationalCode: "کد ملی",
    birthday: "تاریخ تولد",
    email: "ایمیل",
    ownerAccount: "نام صاحب حساب",
    accountOwner: "نام صاحب حساب",
    cartNumber: "شماره کارت",
    shebaNumber: "شماره شبا",
    companyName: "نام شرکت",
    registrationNumber: " شماره ثبت",
    nationalID: " شناسه ملی",
    receiverName: "نام تحویل گیرنده",
    receiverLastName: "نام  خانوادگی تحویل گیرنده",
    receiverPhone: "شماره موبایل تحویل گیرنده",
    province: "استان",
    city: "شهر",
    distinct: "محله",
    addressDetails: "آدرس",
    postalCode: "کد پستی",
    phoneNumber: "شماره تلفن همراه",
    returnReason: "دلیل مرجوعی",
    description: "توضیحات",
    comment: "یادداشت",
    newPassword: "رمز عبور",
    confrimNewPassword: "تکرار رمز عبور",
    fullName: "نام و نام خانوادگی",
  },
  placeholder: {
    Name: "نام را وارد کنید",
    lastName: "نام خانوادگی را وارد کنید",
    nationalCode: "کد ملی را وارد کنید",
    birthday: "تاریخ تولد را وارد کنید",
    email: "ایمیل را وارد کنید",
    ownerAccount: "نام صاحب حساب را وارد کنید",
    accountOwner: "نام صاحب حساب را وارد کنید",
    cartNumber: "شماره کارت را وارد کنید",
    shebaNumber: "شماره شبا را وارد کنید",
    companyName: "نام شرکت را وارد کنید",
    registrationNumber: " شماره ثبت را وارد کنید",
    nationalID: " شناسه ملی را وارد کنید",
    receiverName: "نام تحویل گیرنده را وارد کنید",
    receiverLastName: "نام  خانوادگی تحویل گیرنده را وارد کنید",
    receiverPhone: "شماره موبایل تحویل گیرنده را وارد کنید",
    province: "انتخاب استان",
    city: "انتخاب شهر",
    distinct: "انتخاب محله",
    addressDetails: "آدرس را وارد کنید",
    postalCode: "کد پستی را وارد کنید",
    phoneNumber: "مثل 09121234567",
    returnReason: "دلیل مرجوعی را انتخاب کنید",
    description: "با نوشتن علت مرجوعی کالا،فرآیند مرجوعی سریعتر انجام می شود",
    comment: "لطفا یادداشت را وارد کنید",
    newPassword: "رمز عبور جدید را وارد کنید",
    confrimNewPassword: "رمز عبور جدید را تأیید کنید",
    fullName: "نام و نام خانوادگی را وارد کنید",
    descriptionContactUs: "",
  },
};
