<template>
  <v-card class="d-flex px-5 user-select-none w-100" elevation="0" tag="nav">
    <!-- home link    -------------------------------------------------------------->
    <router-link
      :to="{ name: 'home' }"
      class="py-2 px-2 ms-n2 secondary--text font-weight-medium text-decoration-none mega-menu-link"
    >
      {{ $_t("components.header.mega_menu.home") }}
    </router-link>
    <!--End home link -------------------------------------------------------------->

    <!-- menu links   -------------------------------------------------------------->
    <v-menu
      offset-y
      allow-overflow
      bottom
      open-on-hover
      content-class="w-min-100 rounded-0 pt-3 pb-6 elevation-0 left-0"
      v-for="item in items"
      :key="item.productGroupId"
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-on="on" v-bind="attrs" aria-label="nav-item" role="link">
          <router-link
            :to="{
              name: 'products.category',
              params: { cat_title: item.slug },
              // query: { catId: item.productGroupId },
            }"
            class="text-decoration-none secondary--text font-weight-medium py-2 px-2 d-block mega-menu-link"
          >
            {{ item.title }}
          </router-link>
        </div>
      </template>
      <v-card
        tile
        class="w-100 user-select-none py-5 rounded-br rounded-bl"
        elevation="6"
      >
        <v-container fluid>
          <v-row class="px-16">
            <!--            <v-col cols="2"></v-col>-->
            <v-col cols="12" class="pt-0">
              <MegaMenuDesktopCard :item="item" />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-menu>
    <!--End menu links-------------------------------------------------------------->
    <v-spacer />
    <!-- mag.lipak    -------------------------------------------------------------->
    <a
      href="https://lipak.com/mag/"
      target="_blank"
      class="py-2 px-2 ms-n2 secondary--text font-weight-medium text-decoration-none mega-menu-link"
    >
      مجله لیپک
    </a>
    <!--End mag.lipak -------------------------------------------------------------->
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import MegaMenuDesktopCard from "@/app/components/new-header/desktop/MegaMenuCard";
import query_builder from "@/app/mixins/mixin.filter-sort";

export default {
  mixins: [query_builder],
  name: "MegaMenuDesktop",
  components: { MegaMenuDesktopCard },
  computed: {
    ...mapGetters({
      items: "megaMenu/items",
    }),
    catId() {
      return this.$route.params.cat_title;
    },
    isDesktop() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
  },
};
</script>

<style lang="scss" scoped>
.mega-menu-link {
  position: relative;
  &:hover,
  &.router-link-exact-active {
    color: var(--v-primary-base) !important;
  }
}
.left-0 {
  left: 0 !important;
}
</style>
