"use strict";
import account_information from "@/packages/profile/routes/account-information";
import track_orders from "@/packages/profile/routes/track-orders";
import addresses from "@/packages/profile/routes/addresses";
export default [
  {
    path: "/profile",
    name: "profile",
    redirect: { name: "profile.dashboard" },
    component: () =>
      import(
        /* webpackChunkName: "profile" */ "@/packages/profile/presentation/views/Index"
      ),
    meta: {
      title: "پروفایل",
    },
    children: [
      {
        path: "order/return/:id",
        redirect: { name: "order-detail.RETURNED/:id" },
      },
      {
        path: "order/INPROCESS/:id",
        redirect: { name: "order-detail.INPROCESS/:id" },
      },
      {
        path: "dashboard",
        name: "profile.dashboard",
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "@/packages/profile/presentation/components/Dashboard"
          ),
        meta: {
          // layout: "auth",
        },
        redirect: { name: "dashboard.account-information" },
        children: [
          ...account_information,
          ...track_orders,
          {
            path: "comments",
            name: "dashboard.comments",
            component: () =>
              import(
                /* webpackChunkName: "profile" */ "@/packages/profile/presentation/components/dashboard-content/comments/Comments"
              ),
          },
          {
            path: "discount",
            name: "dashboard.discount",
            component: () =>
              import(
                /* webpackChunkName: "profile" */ "@/packages/profile/presentation/components/dashboard-content/discount/Index"
              ),
          },
          ...addresses,
          {
            path: "wallet",
            name: "dashboard.wallet",
            component: () =>
              import(
                /* webpackChunkName: "profile" */ "@/packages/profile/presentation/components/dashboard-content/wallet/Index"
              ),
          },
          {
            path: "returned-request",
            name: "dashboard.returned-request",
            component: () =>
              import(
                /* webpackChunkName: "profile" */ "@/packages/profile/presentation/components/dashboard-content/returned-request/Index"
              ),
          },
          {
            path: "popular-product",
            name: "dashboard.popular-product",
            component: () =>
              import(
                /* webpackChunkName: "profile" */ "@/packages/profile/presentation/components/dashboard-content/popular-product/Index"
              ),
          },
          {
            path: "change-password",
            name: "dashboard.change-password",
            component: () =>
              import(
                /* webpackChunkName: "profile" */ "@/packages/profile/presentation/components/dashboard-content/change-password/Index"
              ),
          },
        ],
      },
      {
        path: "registration-returned/:id",
        name: "profile.registration-returned/:id",
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "@/packages/profile/presentation/components/registration-returned/Index"
          ),
        meta: {
          // layout: "auth",
        },
      },
    ],
  },
];
