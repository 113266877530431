import store from "@/app/store";
import { _t } from "@/helpers/utilities.helper";

// Message
// display a quick message to user

export const MessageService = {
  error(text) {
    store.commit("messages/set", { text: _t(text), color: "#FB4E4E" });
  },

  success(text) {
    store.commit("messages/set", { text: _t(text), color: "#2AC769" });
  },

  show(text) {
    store.commit("messages/set", { text: _t(text), color: "default" });
  },
};
