<template>
  <div class="ss02 user-select-none text-center card-product__image-container">
    <div class="d-flex justify-space-between align-center">
      <div
        v-if="!isDesktop && item.satisfactionRate.count"
        class="d-flex text-caption"
      >
        <v-rating
          dir="ltr"
          class="text-left mb-1"
          empty-icon="mdi-star-outline"
          full-icon="mdi-star"
          half-icon="mdi-star-half-full"
          hover
          length="1"
          size="14"
          :value="item.satisfactionRate.score"
          color="warning"
          readonly
          dense
        >
        </v-rating>
        {{ item.satisfactionRate.score }}
      </div>
      <v-btn small color="primary" class="rounded-sm text-body-2 mr-auto">
        {{ percent }}%
      </v-btn>
    </div>
    <div v-if="!isDesktop" class="d-block product-color-viewer-container">
      <div
        v-for="(color, index) in colors"
        :key="`${color}-${index}`"
        class="rounded-circle mt-1 ml-1"
        style="border: 1px solid var(--v-secondary-lighten5)"
        :style="{
          backgroundColor: color,
          width: isMobile ? '8px' : '10px',
          height: isMobile ? '8px' : '10px',
        }"
      />
    </div>
    <figure>
      <img
        style="flex: unset; aspect-ratio: 1/1 !important"
        class="mt-1 w-100"
        :src="image.url"
        @error="catchImageLoadingError"
        :alt="image.alt"
      />
      <figcaption>
        <!--    <v-img-->
        <!--      class="mt-1"-->
        <!--      :src="image.url"-->
        <!--      contain-->
        <!--      style="flex: unset"-->
        <!--      @error="catchImageLoadingError"-->
        <!--      mode="in-out"-->
        <!--    >-->
        <!--      <template v-slot:placeholder>-->
        <!--        <v-sheet height="100%" color="white">-->
        <!--          <v-row class="fill-height ma-0" align="center" justify="center">-->
        <!--            <v-progress-circular-->
        <!--              indeterminate-->
        <!--              color="primary"-->
        <!--              aria-label="progress"-->
        <!--            />-->
        <!--          </v-row>-->
        <!--        </v-sheet>-->
        <!--      </template>-->
        <!--    </v-img>-->
        <div
          class="d-flex justify-space-between align-end mt-3 flex-column flex-lg-row"
        >
          <div
            class="info--text font-weight-medium text-body-2 text-decoration-line-through"
          >
            {{ formatPrice(item.basePrice / 10) }}
          </div>
          <div class="primary--text font-weight-bold text-large">
            {{ formatPrice(item.salePrice / 10) }} تومان
          </div>
        </div>
        <div style="position: relative" class="mt-2">
          <p
            class="text-body-1 font-weight-bold more--text mb-0 line-clamp text-right"
            :class="[isMobile && 'text-body-2']"
          >
            {{ item.title }}
          </p>
          <v-expand-transition>
            <div
              v-if="hover"
              class="text-body-1 white font-weight-bold secondary--text text-wrap text-right card-product__price-wrapper-title-hovered"
              :class="[isMobile && 'text-body-2']"
            >
              {{ item.title }}
            </div>
          </v-expand-transition>
        </div>
        <div
          v-if="isDesktop"
          class="d-flex flex-wrap justify-space-between align-center mt-3"
        >
          <div class="d-flex justify-start">
            <div
              v-for="(color, index) in colors"
              :key="`${color}-${index}`"
              class="rounded-circle mt-1 ml-1"
              style="border: 1px solid var(--v-secondary-lighten5)"
              :style="{
                backgroundColor: color,
                width: isMobile ? '8px' : '10px',
                height: isMobile ? '8px' : '10px',
              }"
            />
          </div>

          <div class="d-flex" v-if="item.satisfactionRate.count">
            <v-rating
              dir="ltr"
              class="amazing-rating"
              color="warning"
              length="5"
              dense
              readonly
              half-increments
              :value="item.satisfactionRate.score"
              :size="isMobile ? 16 : 18"
            />
          </div>
        </div>
      </figcaption>
    </figure>
  </div>
</template>

<script>
import imageUrlBuilder from "@/app/mixins/mixin.image_url_builder";

export default {
  name: "SpecialProduct",
  mixins: [imageUrlBuilder],
  props: {
    item: {
      type: Object,
      required: true,
    },
    hover: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      imageHasError: false,
    };
  },
  computed: {
    isDesktop() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    colors() {
      return [
        ...new Set(
          this.item.colors
            ? this.item.colors.map((v) => {
                let color = v;
                if (v.length === 3) {
                  color = `${v[0]}${v[0]}${v[1]}${v[1]}${v[2]}${v[2]}`;
                }
                return color.toLowerCase();
              })
            : []
        ),
      ];
    },
    image() {
      const { image } = this.item;
      return {
        url:
          image && !this.imageHasError
            ? this.imageUrlBuilder(image.url)
            : require("@/app/assets/images/product-default-image.png"),
        alt: image && image.alt ? image.alt : this.item.title,
      };
    },
    percent() {
      return Math.ceil(
        ((this.item.basePrice - this.item.salePrice) / this.item.basePrice) *
          100
      ).toFixed(0);
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  methods: {
    catchImageLoadingError() {
      this.imageHasError = true;
    },
    formatPrice(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<style lang="scss" scoped>
.card-product__price-wrapper-title-hovered {
  position: absolute !important;
  top: 0;
  width: 100%;
  z-index: 12;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.card-product__image-container {
  position: relative;
}
.product-color-viewer-container {
  position: absolute;
  z-index: 2;
  top: 35%;
  transform: translate(0, -50%);
}
</style>
