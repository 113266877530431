export default {
  install(Vue) {
    Vue.prototype.$_faToEn = function (input) {
      const persianDigits = /[\u06F0-\u06F9]/g; // Updated regular expression
      return input.replace(persianDigits, function (c) {
        return String.fromCharCode(c.charCodeAt(0) - 1728); // Adjusting the conversion
      });
    };
  },
};
