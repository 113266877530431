<template>
  <Index />
</template>

<script>
import Index from "@/packages/home/components/Index";
import mixinMeta from "@/app/mixins/mixin.meta";

export default {
  name: "Home",
  mixins: [mixinMeta],
  components: {
    Index,
  },
  metaInfo() {
    return this.createMeta({
      title: "فروشگاه اینترنتی لیپک | فروشگاه لپ‌تاپ، موبایل و لوازم دیجیتال",
      description:
        "لیپک یک قدم جلوتر | فروشگاه اینترنتی لیپک ارائه دهنده لپ‌تاپ، گوشی موبایل، تبلت و لوازم دیجیتال با قیمت مناسب، پشتیبانی ۲۴ ساعته و ارسال سریع",
    });
  },
};
</script>
