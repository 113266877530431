<template>
  <div class="ss02" v-if="!$vuetify.breakpoint.mobile">
    <div class="font-weight-bold mb-3">{{ title }}</div>
    <div class="text-justify">{{ description }}</div>
  </div>
  <v-expansion-panels v-else>
    <v-expansion-panel>
      <v-expansion-panel-header> {{ title }} </v-expansion-panel-header>
      <v-expansion-panel-content class="text-justify">
        {{ description }}
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      title: "درباره لیپک",
      description:
        "لیپک به معنای قطب‌نماست و احتمالا شما این کلمه را جایی شنیده‌اید. در یک\n" +
        "موسیقی شاد جنوبی (شاید بهتر باشد در گوگل لیپک را جست و جو کنید). شرکت\n" +
        "اندیشه راه آگاه آیندگان کنگان با نام تجاری لیپک فعالیت خود را از تابستان\n" +
        "۹۹ شروع کرد. ماموریت اصلی لیپک تامین و توزیع محصولات الکترونیک مانند\n" +
        "لپتاپ، تبلت و گوشی‌های تلفن همراه است.",
    };
  },
};
</script>

<style scoped></style>
