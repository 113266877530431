// initial state

const state = () => ({
  drawer: false,
  hasError: false,
});

// getters
export const getters = {
  drawer: (state) => state.drawer,
  hasError: (state) => state.hasError,
};

// mutations
export const mutations = {
  mutationsDrawer(state, payload) {
    state.drawer = payload;
  },
  mutationsHasError(state, payload) {
    state.hasError = payload;
  },
};

// actions
export const actions = {
  setDrawer({ commit }, payload) {
    commit("mutationsDrawer", payload);
  },
  setHasError({ commit }, payload) {
    commit("mutationsHasError", payload);
  },
};

export default {
  namespaced: true,
  name: "profile",
  state,
  getters,
  actions,
  mutations,
};
