<template>
  <v-card
    outlined
    elevation="0"
    :color="isMobile ? 'primary' : 'white'"
    class="d-flex flex-row-reverse py-2 align-center ss02"
    :class="[isMobile ? 'white--text' : 'primary--text  ']"
    :style="{
      borderColor: `${
        isMobile ? 'var(--v-white-base)' : 'var(--v-primary-base)'
      }!important`,
    }"
  >
    <div class="time-holder font-weight-bold">{{ getHMS.hours }}</div>
    <v-divider
      vertical
      :style="{
        borderColor: `${
          isMobile ? 'var(--v-white-base)' : 'var(--v-primary-base)'
        }`,
      }"
    />
    <div class="time-holder font-weight-bold">{{ getHMS.minutes }}</div>
    <v-divider
      vertical
      :style="{
        borderColor: `${
          isMobile ? 'var(--v-white-base)' : 'var(--v-primary-base)'
        }`,
      }"
    />
    <div class="time-holder font-weight-bold">{{ getHMS.seconds }}</div>
  </v-card>
</template>

<script>
export default {
  name: "AmazingTimer",
  props: {
    time: {
      type: Number, // milliseconds
      required: true,
    },
  },
  data() {
    return {
      timerInterval: null,
      timer: Math.abs(this.time),
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    getHMS() {
      return this.convertMsToHMS(this.timer);
    },
  },
  created() {
    this.startTimer();
  },
  methods: {
    startTimer() {
      this.timerInterval = setInterval(() => {
        this.timer -= 1000;
        if (this.timer <= 0) {
          this.timer = 0;
          clearInterval(this.timerInterval);
          this.$emit("finish-timer");
        }
      }, 1000);
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    },
    convertMsToHMS(milliseconds) {
      let seconds = Math.floor((milliseconds / 1000) % 60);
      let minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
      let hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);

      return {
        hours: this.padTo2Digits(hours),
        minutes: this.padTo2Digits(minutes),
        seconds: this.padTo2Digits(seconds),
      };
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
.time-holder {
  width: 40px;
  text-align: center;
}
</style>
