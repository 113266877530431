"use strict";

import { getProductsSlugsService } from "@/services/Slugs.service";

export default [
  {
    path: "/product/",
    name: "product",
    redirect: { name: "home" },
    component: () =>
      import(
        /* webpackChunkName: "product" */ "@/packages/product/presentation/views/Index"
      ),
    children: [
      {
        path: ":slug//",
        name: "product.detail",
        meta: {
          slugs: async () => await getProductsSlugsService(),
        },
        component: () =>
          import(
            /* webpackChunkName: "product" */ "@/packages/product/presentation/views/ProductDetail"
          ),
      },
    ],
  },
  {
    path: "/compare",
    name: "compare",
    component: () =>
      import(
        /* webpackChunkName: "product" */ "@/packages/product/presentation/views/ProductCompare"
      ),
  },
];
