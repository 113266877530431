module.exports = {
  translated: "سلام رفیق",
  components: {
    header: {
      slogan: "شعار لیپک",
      searchPlaceholder: "جستجو در لیپک ...",
      magazine: "مجله خبری لیپک",
      contactUs: "تماس با ما",
      aboutUs: "درباره ما",
      profile: "پروفایل",
      register_login: "ثبت‌نام / ورود",
      cart: "سبد خرید",
      mega_menu: {
        home: "صفحه اصلی",
        label_title: "{title} بر اساس کاربری",
        brand_title: "برند {title}",
      },
    },
    footer: {
      footer_link: {
        purchase_guide: "راهنمای خرید",
        payment_methods: "روش‌های پرداخت",
        frequentl_asked_questions: "سوالات متداول",
        Order_submission_guide: "راهنمای ارسال سفارش",
        terms_and_conditions: "قوانین و مقررات خرید از لیپک",
        reference_rules: "راهنمای لغو و مرجوعی سفارش",
        purchase_rules: "قوانین خرید",
        privacy: "حریم خصوصی",
        guarantee: "گارانتی لیپک",
        lipak_is_electronic_store:
          "لیپک یک فروشگاه اینترنتی محصولات الکترونیک است",
        buyـwithـconfidence: "با اطمینان از لیپک خرید کنید",
      },
      footer_address: {
        address:
          "تهران - خیابان ولی‌عصر - بالاتر از میدان ولی‌عصر - کوچه شهید شهامتی -  پلاک 21",
        email: "info@lipak.com",
        working_hours:
          "شنبه تا چهارشنبه از ساعت 9 صبح الی 17:30،پنجشنبه ها 9 صبح الی 13",
      },
      about_lipak_info: {
        about_lipak: "درباره لیپک",
        description_about_lipak:
          "لیپک به معنی قطب نماست و احتمالا شما این کلمه را جایی شنیده اید؛ در یک موسیقی شاد جنوبی. (شاید بهتر باشد در گوگل جستجو کنید)شرکت اندیشه راه آگاه آیندگان کنگان با نام تجاری لیپک فعالیت خود را از تابستان99شروع کرد. ماموریت اصلی لیپک تامین و توزیع محصولات الکترونیک مانند لپتاپ، تبلت و گوشی های تلفن همراه است.",

        callـforـquestions: "  سوالی دارید تماس بگیرید",
        follow_us_in_instagram: "مارا در اینستاگرام دنبال کنید",
      },
      all_right: {
        message: "© کلیه حقوق متعلق به",
        lipak_store: "فروشگاه اینترنتی لیپک ",
        is: "است.",
      },
    },
    share_modal: {
      header: "اشتراک گذاری",
      title: "این کالا را با دوستان خود به اشتراک بگذارید!",
      copy_link: "کپی کردن لینک",
      whatsapp: "واتس‌اپ",
      twitter: "توییتر",
      facebook: "فیسبوک",
      cancel: "انصراف!",
    },
  },
  schema: {
    mobile_bottom_nav: {
      home: "خانه",
      grouping: "جستجو و دسته‌بندی",
      cart: "سبد خرید",
      profile: "پروفایل",
      register_login: "ثبت‌نام / ورود",
    },
  },
  router: {},
  route_titles: {
    index: "لیپک",
  },
  views: {
    not_found: {
      home: "بازگشت به صفحه اصلی",
      text1: "خطای 404 صفحه‌ای پیدا نشد!",
      text2: "صفحه مورد نظر شما یافت نشد!",
      text3: "احتمالا این صفحه به آدرس دیگری تغییر کرده یا حذف شده است.",
    },
    server_error: {
      home: "تلاش مجدد",
      text1: "خطای 500 مشکلی پیش آمده",
      text2: "خطای داخلی سرور رخ داده",
      text3: "ما درصدد رفع مشکل هستیم. پس از مدتی دوباره تلاش کنید.",
    },
  },
  attribute: {
    name: "نام",
    phone: "شماره تلفن همراه",
    password: "رمز عبور",
    confirm: "تکرار رمز عبور",
  },
  placeholder: {
    name: "نام را وارد کنید.",
    phone: "مثال:09331234567",
    password: "رمز عبور شما باید حداقل 6 کارکتر باشد.",
    confirm: "تکرار رمز عبور را وارد کنید.",
  },
  validations: {
    alpha: "{attribute} فقط می تواند از حروف تشکیل شود",
    alpha_num: "{attribute} فقط میتواند از حروف و اعداد تشکیل شود",
    alpha_dash:
      "{attribute} فقط می تواند از حروف، اعداد، خط فاصله و زیرخط تشکیل شود",
    alpha_spaces: "{attribute} فقط می تواند از حروف و فاصله تشکیل شود",
    between: "{attribute} باید بین {min} و {max} کارکتر باشد",
    confirmed: "{attribute} با رمز عبور مطابقت ندارد",
    digits: "{attribute} باید یک مقدار عددی و دقیقاً {length} رقم باشد",
    dimensions:
      "{attribute} باید در اندازه {width} پیکسل عرض و {height} پیکسل ارتفاع باشد",
    email: "{attribute} باید یک ایمیل(رایانامه) معتبر باشد",
    excluded: "{attribute}باید یک مقدار معتبر باشد",
    ext: "{attribute} باید یک فایل معتبر باشد",
    image: "{attribute} باید یک تصویر باشد",
    max_value: "مقدار {attribute} باید {max} یا کمتر باشد",
    max: "{attribute} نباید بیشتر از {length} کارکتر باشد",
    mimes: "{attribute} باید از نوع معتبر باشد",
    min_value: "مقدار {attribute} باید {min} یا بیشتر باشد",
    minLength: "مقدار {attribute} باید {min} کارکتر باشد",
    maxLength: "مقدار {attribute} باید {max} کارکتر باشد",
    // TODO
    // validation minLength and maxLength must change
    min: "{attribute} باید حداقل {length} کارکتر باشد",
    numeric: "{attribute} فقط می تواند عددی باشد",
    persianEnglishArabicNumber: "{attribute} فقط می تواند عددی باشد",
    oneOf: "{attribute} باید یک مقدار معتبر باشد",
    regex: "قالب {attribute} قابل قبول نیست",
    required_if: "{attribute} الزامی است",
    required: "{attribute} الزامی است",
    size: "حجم {attribute} کمتر از {size}KB باشد",
    double: "قسمت {attribute} باید یک اعشار معتبر باشد",
    strongPassword:
      "پسورد باید شامل حروف و اعداد و کارکترهای ویژه $#%^&*@ باشد.",
    sameAsPassword: "مقدار {attribute} با رمز عبور برابر نیست.",
    nationalId: "شناسه ملی وارد شده صحیح نمی باشد.",
    nationalCode: "کد ملی وارد شده صحیح نمی باشد.",
    mobileNumber: "{attribute} باید با ۰۹ شروع شود و ۱۱ رقم باشد.",
    mixCharacterPersianAndEnglishValidation: "{attribute} معتبر نمی‌اشد",
    isNotPersian: "لطفا در {attribute} از حروف فارسی استفاده نکنید.",
  },

  FAILED: "سیستم با مشکل مواجه شده است. لطفا مجددا تلاش کنید.",
  OTP_RESEND_FAILED: "متاسفانه کد تایید ارسال نشد.",
  OTP_RESEND_SUCCESS: "کد تایید با موفقیت ارسال شد.",
  INVALID_GRANT: "رمز عبور اشتباه است.",
  INVALID_OTP: "کد تایید اشتباه است. لطفا مجددا تلاش کنید.",
  ACCESS_DENIED:
    "بعد از 10 دقیقه مجددا تلاش کنید.تعداد در خواست های شما بیش از حد مجاز است.",
  REGISTER_SUCCESS: "شما با موفقیت در لیپک ثبت نام کردید.",
  CREATE_SUCCESS: "افزودن با موفقیت انجام شد.",
  UPDATE_SUCCESS: "به روز رسانی با موفقیت انجام شد.",
  DELETE_SUCCESS: "حذف با موفقیت انجام شد.",
  CREATE_FAILED: "افزودن انجام نشد.",
  UPDATE_FAILED: "به روز رسانی انجام نشد.",
  DELETE_FAILED: "حذف انجام نشد.",
  SERVER_ERROR: "خطای داخلی سرور",
  500: "خطای داخلی سرور",
  RESET_PASSWORD_SUCCESS: "رمز عبور با موفقیت تغییر یافت",
  UPDATE_AVAILABLE: "به زودی سایت به روز رسانی می شود.",
  UPDATE_BTN: "به روز رسانی",
  UPDATE_CLOSE: "بستن",
  UNAUTHORIZED: "دسترسی غیر مجاز",
  "no address found": "لطفا آدرس یا زمان ارسال مورد نظر خود را انتخاب کنید.",
  ADDRESS_IS_NOT_DEFINED: "آدرس یا شیوه ارسال را انتخاب نکرده اید.",
  PAYMENT_METHOD_IS_NOT_DEFINED: "لطفا روش پرداخت خود را انتخاب کنید.",
  BALON_OTP_IS_NOT_DEFINED: "لطفا کد ارسالی بالون را وارد کنید",
  PLEASE_SELECT_ANOTHER_PAYMENT_WAY:
    "مجاز به استفاده از بالون نمی‌باشید، لطفا روش پرداخت دیگری را انتخاب کنید",
  "no item in card": "سبد خرید شما خالی می باشد.",
  "card item change count ":
    "قیمت یا موجودی کالا بعضی از اقلام سبد خری تغییر کرده است. لطفا سبد خرید خود را به روز رسانی کنید.",
  DELETE_ALL_UNAVAILABLE_ITEMS:
    "لطفا تمام آیتم های ناموجود سبد خرید خود را حذف کنید.",
  "cart item price is change ": "قیمت یکی از آیتم های سبد کالا تغییر کرده است.",
  "already have enough waiting for payment":
    "ابتدا سفارش در انتظار پرداخت خود را تکمیل نمایید",
  REQ_SUCCESS: "درخواست شما با موفقیت ثبت شد.",
  REQ_FAILED: "درخواست شما ثبت نشد.",
  NOTIFY_ME_DUPLICATE: "درخواست شما قبلا ثبت شده است.",
  DELETE_SUCCESS_ORDER: "حذف کالا از سبد با موفقیت انجام شد",
};
