// initial state

import storageService from "@/services/LocalStorageService.service";

const state = () => ({
  cart: storageService.get("cart"),
  isUserCheckedChanges: true,
  hasPaymentWaitingLimit: false,
  hasDailyLimitCount: {
    state: false,
    message: "",
  },
  // purchaseData: {},
});

// getters
export const getters = {
  cart_id: (state) => state.cart.cardId,
  cart: (state) => state.cart,
  items: (state) => state.cart.cartItems,
  isEmptyCart: (state) => state.cart.cartItems.length === 0,
  count: (state) =>
    state.cart && state.cart.cartItems ? state.cart.cartItems.length : 0,
  total_items: (state) =>
    state.cart.cartItems.reduce((acc, item) => acc + item.count, 0),
  price_summary: (state, getters, rootState, rootGetters) => {
    let isBalonSelected = rootGetters["order.payment_method/balonStatus"];
    let isCreditSelected = rootGetters["order.payment_method/creditStatus"];
    let installmentsHasPriceDifferent =
      rootGetters["order.payment_method/installmentsHasPriceDifferent"];
    let orderStep = rootGetters["order.wizard/step"];
    let installmentPrice =
      rootGetters["order.payment_method/installmentsPrice"];

    let price = {
      total_price: state.cart.price.totalPrice,
    };

    let shippingCost = Number(state.cart.price.shippingCost);

    if (
      state.cart.price.discount &&
      Number(state.cart.price.discount) !== 0 &&
      orderStep === 3
    ) {
      price.discount = state.cart.price.discount;
    }
    if (Number(state.cart.price.shippingCost)) {
      price.shipping_price = state.cart.price.shippingCost;
    }

    if (
      !isBalonSelected &&
      !isCreditSelected &&
      (Number(state.cart.price.shippingCost) ||
        (state.cart.price.discount &&
          Number(state.cart.price.discount) !== 0)) &&
      orderStep !== 1
    ) {
      price.final_price = state.cart.price.finalPrice;
    }

    if (
      (isBalonSelected || isCreditSelected) &&
      installmentsHasPriceDifferent
    ) {
      let discount = state.cart.price.discount || 0;
      price.installmentCredit = installmentPrice;
      price.amountPayable =
        price.total_price - installmentPrice + shippingCost - discount;
    }

    if (
      (isBalonSelected || isCreditSelected) &&
      !installmentsHasPriceDifferent
    ) {
      let discount = state.cart.price.discount || 0;
      price.amountPayable = Number(price.total_price) + shippingCost - discount;
    }
    return price;
  },
  isChangedItems: (state) => {
    return state.cart.cartItems.some(
      (item) => item.countChange || item.priceChange || !item.isAvailable
    );
  },
  isUserCheckedChanges: (state) => state.isUserCheckedChanges,
  hasPaymentWaitingLimit: (state) => state.hasPaymentWaitingLimit,
  hasDailyLimitCount: (state) => state.hasDailyLimitCount,
  // purchaseData: (state) => state.purchaseData,
};

// mutations
export const mutations = {
  mutateCart(state, value) {
    state.cart = value;
    storageService.set("isUserCheckedCartChanges", value);
  },
  mutateCartOnStepChange(state, value) {
    state.cart = value;
  },
  mutateIsUserCheckedChanges(state, value) {
    state.isUserCheckedChanges = value;
  },

  mutateHasPaymentWaitingLimit(state, value) {
    state.hasPaymentWaitingLimit = value;
  },

  mutateHasDailyLimitCount(state, value) {
    state.hasDailyLimitCount = value;
  },
  mutateCartItem(state, { item_id, cart }) {
    let newCart = {};
    let items = [...state.cart.cartItems];
    let old_item = items.find((item) => item.id === item_id);
    let new_item = cart.cartItems.find((item) => item.id === item_id);
    if (old_item) {
      let index = state.cart.cartItems.indexOf(old_item);
      items[index] = new_item;
      newCart.cartItems = items;
      newCart.price = cart.price;
      newCart.cardId = cart.cardId;
      newCart.courierShipping = cart.courierShipping;

      state.cart = { ...newCart };

      storageService.set("cart", state.cart);
    }
  },

  mutateCartItemDeleted(state, { item_id, cart }) {
    // let newCart = {};
    // let items = [...state.cart.cartItems];
    // let old_item = items.find((item) => item.id === item_id);
    //
    // if (old_item) {
    //   let index = state.cart.cartItems.indexOf(old_item);
    //   items.splice(index, 1);
    //   newCart.cartItems = items;
    //   newCart.price = cart.price;
    //   newCart.cardId = cart.cardId;
    //   newCart.courierShipping = cart.courierShipping;
    //
    //   state.cart = { ...newCart };
    //
    //   storageService.set("cart", state.cart);
    // }
    console.log(item_id);
    state.cart = cart;
    storageService.set("cart", cart);
  },

  resetCart(state) {
    let cartId = state.cart.cardId;
    state.cart = {
      cardId: cartId,
      price: {
        totalPrice: 0,
        finalPrice: 0,
        shippingCost: 0,
      },
      cartItems: [],
      courierShipping: false,
    };
    // storageService.set("cart", state.cart);
  },
  // mutateCartDataInPurchaseData(state) {
  //   state.purchaseData = state.cart;
  // },
};

// actions
export const actions = {
  setCart({ commit }, data) {
    storageService.set("cart", data);
    commit("mutateCart", data);
  },
  setIsUserCheckedChanges({ commit }, data) {
    commit("mutateIsUserCheckedChanges", data);
  },

  setHasPaymentWaitingLimit({ commit }, data) {
    commit("mutateHasPaymentWaitingLimit", data);
  },
  setHasDailyLimitCount({ commit }, data) {
    commit("mutateHasDailyLimitCount", data);
  },
  setCartItem({ commit }, data) {
    commit("mutateCartItem", data);
  },
  deleteCartItem({ commit }, data) {
    commit("mutateCartItemDeleted", data);
  },

  clearCart({ commit }) {
    commit("resetCart");
    storageService.remove("cart");
  },

  resetCartShippingAndDiscountCost({ commit, state }) {
    let cart = {
      cardId: state.cart.cardId,
      price: {
        totalPrice: state.cart.price.totalPrice,
        finalPrice: state.cart.price.totalPrice - state.cart.price.discount,
        discount: state.cart.price.discount,
        shippingCost: 0,
      },
      cartItems: state.cart.cartItems,
      courierShipping: false,
    };
    commit("mutateCartOnStepChange", cart);
  },

  // setCartDataInPurchaseData({ commit }) {
  //   commit("mutateCartDataInPurchaseData");
  // },
};

export default {
  namespaced: true,
  name: "cart",
  state,
  getters,
  actions,
  mutations,
};
