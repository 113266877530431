module.exports = {
  key: "order",
  order: {
    components: {
      order_price_summary: {
        invoice_summary: {
          buttons: {
            submit_step_1: "ادامه خرید",
            submit_step_2: "ادامه خرید",
            submit_step_3: "پرداخت",
            discount: "ثبت کد تخفیف",
          },
        },
        invoice_summary_items: {
          total_price: "جمع سبد خرید",
          discount: "تخفیف کلی شما",
          shipping_price: "هزینه ارسال و بسته‌بندی",
          final_price: "قیمت نهایی سفارش",
          installmentCredit: "اعتبار بالون",
          amountPayable: "مبلغ قابل پرداخت",
          balloonWallet: "کیف پول بالون",
        },
      },
    },
    views: {},
    route_titles: {
      index: "صفحه سفارش",
      description: "صفحه سفارش کالا از فروشگاه لیپک",
    },
  },
  attribute: {
    firstName: "نام",
    lastName: "نام خانوادگی",
    nationalNumber: "کدملی",
    receiverName: "نام تحویل گیرنده",
    receiverLastName: "نام خانوادگی تحویل گیرنده",
    receiverPhone: "شماره موبایل تحویل گیرنده",
    province: "استان",
    city: "شهر",
    quarter: "محله",
    addressDetails: "آدرس",
    postalCode: "کد پستی",
    companyName: "نام شرکت",
    nationalId: "شناسه ملی",
    registerNumber: "شماره ثبت",
    discountCode: "کد تخفیف",
  },
  placeholder: {
    firstName: "لطفا نام را وارد کنید.",
    lastName: "لطفا نام خانوادگی را وارد کنید.",
    nationalNumber: "لطفا کدملی را وارد کنید.",
    receiverName: "نام تحویل گیرنده را وارد کنید.",
    receiverLastName: "نام خانوادگی تحویل گیرنده را وارد کنید.",
    receiverPhone: "مثال 09121234567",
    province: "انتخاب استان",
    city: "انتخاب شهر",
    quarter: "انتخاب محله",
    addressDetails: "آدرس را وارد کنید.",
    postalCode: "کد پستی باید 10 رقم و بدون خط باشد.",
    companyName: "نام شرکت را وارد کنید.",
    nationalId: "شناسه ملی را وارد کنید.",
    registerNumber: "شماره ثبت را وارد کنید.",
    discountCode: "کد تخفیف را وارد کنید.",
  },
};
