// initial state

const state = () => ({
  sliderImages: {},

  specials: [],
});

// getters
export const getters = {
  sliderImages: (state) => state.sliderImages,

  specials: (state) => state.specials,
};

// mutations
export const mutations = {
  mutateSliderImage(state, value) {
    state.sliderImages = value;
  },
  mutateSpecials(state, payload) {
    state.specials = payload;
  },
};

// actions
export const actions = {
  setSliderImage({ commit }, data) {
    commit("mutateSliderImage", data);
  },
  setSpecials({ commit }, data) {
    commit("mutateSpecials", data);
  },
};

export default {
  namespaced: true,
  name: "home",
  state,
  getters,
  actions,
  mutations,
};
