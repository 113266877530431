"use strict";
export default [
  {
    path: "/lucky-spinner",
    name: "lucky-spinner",
    component: () =>
      import(
        /* webpackChunkName: "wheel-of-luck-pkg" */ "@/packages/wheel-of-luck/presentation/views/Index.vue"
      ),
    meta: {
      layout: "noneLayout",
    },
  },
];
