import api from "@/services/ApiService.service";
import urls from "@/helpers/api_route_manager.helper";

/**
 *  get user cart
 *  ------------------------
 *  Api address| /order/card/card-init/:id
 *  ------------------------
 *  Method| Get
 *  ------------------------
 *  Description| get user cart
 *  ------------------------
 *  @param query_params {Object}
 *  ------------------------
 *  @return  type {Promise}
 *  ------------------------
 **/
export const getUserCartInitAxiosRequest = function (query_params = null) {
  return api.get(urls.api("init_cart"), {
    params: {
      ...query_params,
    },
  });
};
